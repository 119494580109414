.welcome-msg {
    margin-top: 20px;
    margin-bottom: 50px;
    width: calc(100% - 40px);
    position: relative;

    p {
        font-size: 18px !important;
        width: 50% !important;
    }

    h2 {
        font-size: 42px;
        line-height: 42px;
        font-family: $SohoGothicPro-Bold;
        font-weight: bold;
        margin-bottom: 20px;
    }

    a {
        position: absolute;
        bottom: -40px;
        cursor: pointer;
        text-decoration: underline;
    }
}

.col2-left-layout {
    .sidebar {
        width: 30%;
        float: left;
        margin-right: 1%;
        margin-bottom: 3%;
        padding-right: 30px;
    }

    .col-main {
        width: 100%;
        float: left;
        margin-bottom: 59px;

        @media screen and (min-width: $breakpoint-medium) {
            width: 69%;
        }
    }
}

.newsletter-manage-index {
    .account-wr {
        .button {
            width: 300px;
        }
    }
}

.address-book-table {
    table {
        width: 100%;
        margin-bottom: 30px;
        
        th, td {
            border: 1px solid $darkgrey;
            padding: 10px;
            text-align: center;
        }
    }
}

.pager {
    $pager-margin: 20px;

    .amount {
        margin-top: $pager-margin;
    }

    .limiter {
        float: right;
        margin-bottom: $pager-margin;
        select {
            width: 150px;
        }
    }
}

.my-order-block {
    &__table {
        strong {
            font-weight: bold;
        }

        @media (max-width: 767px) {
            tbody {
                tr {
                    &:not(:last-of-type) {
                        margin-bottom: 10px;
                    }

                    .product-name {
                        font-size: px-to-rem(14);
                    }

                    td {
                        padding: 5px;
                    }
                }
            }
        }

        tbody {
            tr.border {
                td {
                    padding-bottom: 15px;
                }
            }
        }

        tfoot {
            text-align: right;

            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 10px;
                padding-top: 10px;
                border-top: 1px solid #e5e5e5;

                tr {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }

        &.no-more-table {
            thead,
            tbody {
                th,
                td {
                    text-align: left;
                }
            }
            
            thead {
                tr.first {
                    th {
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}

.header .links>li {
    display: inline-block;
    position: relative;
    padding-top: 0;
}

.br-small .icon-my-account {
    display: inline-block;
    width: 12px;
    height: 10px;
    position: relative;
    margin-left: 10px;
}

.br-small li.active>a, .br-small li.active>a:before {
    color: $white;
}