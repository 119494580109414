.bernafon-main {
    .footer-block {
        height: auto;

        &__top {
            color: $white;

            h4 {
                margin-bottom: 15px;
            }

            a {
                font-family: $Roboto-Regular;
                font-size: px-to-rem(18);
                color: $white;
                line-height: normal;
                margin-top: 3px;
                margin-bottom: 5px;
                padding: 3px 0px 5px;
                position: relative;

                &::after {
                    content: '';
                    height: 1px;
                    width: 100%;
                    background-color: $white;
                    position: absolute;
                    display: block;
                    bottom: 0;
                    opacity: 0;
                    @include default-transition(all);
                }

                &:hover {
                    &::after {
                        opacity: 1;
                    }
                }
            }

            p {
                margin-bottom: 20px;
            }
        }

        &__bottom {
            background-color: $white;
        }
    }

    .bottomlinks,
    .copyright {
        font-size: px-to-rem(16);
        font-family: $Roboto-Regular;
        color: $default-color;
        float: left;
    }

    .bottomlinks {
        // margin-top: 25px;
        font-size: px-to-rem(14);

        a {
            text-decoration: none;
            color: $default-activeColor;
            font-family: $Roboto-Regular;
        }

        li {
            &:first-of-type {
                margin-left: 20px;
            }
        }
    }
}