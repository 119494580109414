@font-face {
    font-family: 'MaxPro-Light';
    src:
        url('../fonts/MaxPro-Light.ttf') format('truetype'),
        url('../fonts/MaxPro-Light.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family: 'MaxPro-Bold';
    src:
        url('../fonts/MaxPro-Bold.ttf') format('truetype'),
        url('../fonts/MaxPro-Bold.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url('../fonts/OpenSans-Light.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'iconfont';
    src: url("../fonts/DGS-WebProgram.eot");
    src: url("../fonts/DGS-WebProgram.eot?#iefix") format("embedded-opentype"),
         url("../fonts/DGS-WebProgram.woff2") format("woff2"),
         url("../fonts/DGS-WebProgram.woff") format("woff"),
         url("../fonts/DGS-WebProgram.ttf") format("truetype"),
         url("../fonts/DGS-WebProgram.svg#DGS-WebProgram") format("svg");
    font-weight: normal;
    font-style: normal;
}