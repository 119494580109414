.bernafon-main {
    .welcome-msg {
        background: $white;
        margin: 0 auto;
        padding: 30px 0;

        @media (max-width: 767px) {
            padding: 30px 20px;

            p {
                width: 100% !important;
            }
        }
    }

    .my-order-block {
        &__table {
            tbody {
                tr {
                    .product-name {
                        font-family: $Roboto-Medium;
                    }
                }
            }
        }
    }
}