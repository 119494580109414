.phonicear-main {
    .category-menu-tile {
        box-sizing: border-box;
        border-radius: 50%;
        width: 274px;
        height: 274px;
        position: relative;
        margin-bottom: 110px;
        padding: 0;

        @media (max-width: 767px) {
            margin-right: auto;
        }

        .bernafon-logo-tile {
            left: 6%;
            border-radius: 0;
            position: absolute;
            top: 39%;
            width: 90%;
            img {
                border-radius: 0;
            }
        }

        & > div {
            position: absolute;
            bottom: -50px;
            text-align: center;
            transform: translateY(50%);

            & > a {
                color: $header-top;

                &:hover {
                    color: $header-top !important;
                }
            }
        }

        &__product-name {
            font-size: px-to-rem(20);
            font-weight: bold;
        }

        &__img {
            max-width: 100%;
            margin: auto;
            img {
                border-radius: 50%;
            }
        }
    }

    .category-menu {
        &__title {
            font-size: px-to-rem(32);
            margin: 20px auto;
        }

        &__list {
            justify-content: center;
        }
    }
}