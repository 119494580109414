.cms-side {
    a {
        color: $color-borderButton;
        line-height: 30px;
        font-family: $SohoGothicPro-Medium;
        padding-left: 4%;
        font-weight:500;
        display: inline-block;

        &.active {
            color: $default-color;
            border-left: 1px solid $default-activeColor;
        }

        &:hover {
            color: $default-color;
        }
    }

    li {
        &.current {
            border-left: 1px solid $default-activeColor;

            a {
                strong {
                    font-weight: 500; color: $default-color;
                }
            }
        }
    }
}

.cms-content {
    .messages {
        li {
            list-style: none;
            font-size: 14px;
            padding: 1%;
        }
    }
}

.sidebar {
    margin-top: 50px;
}

.contact-form {
    li {
        list-style: none;
    }

    textarea {
        font-family: inherit;
        font-size: 100%;
        margin: 0;
        overflow: auto;
        vertical-align: top;
        padding: 1%;
        width: 100%;
        height: 150px;
        resize: vertical;

        @media screen and (min-width: $breakpoint-medium) {
            width: 50%;
        }
    }
}