.horselbutik-main {
    .category-menu-tile {
        border: none;
        @include default-transition(box-shadow);
        box-shadow: $product-boxshadow;

        &:hover {
            box-shadow: $product-boxshadow-hover;
        }

        &__product-name {
            font-size: px-to-rem(20);
            font-family: $SourceSansPro-Regular;
        }

        &__img {
            max-width: 90%;
            margin: 15px;
        }

        a {
            color: $black;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .category-menu {
        &__title {
            font-family: $SourceSansPro-Bold;
            font-size: px-to-rem(22);
            text-align: left;
            color: $black;
        }
    }
}