.phonicear-main {
    .custom-select {
        &__btn {
            border: 2px solid $border-input;
            border-bottom: none;
            padding: 9px 42px 9px 10px;
            font-family: $MaxPro-Light;
        }

        &__dropdown-menu {
            border: 2px solid $border-input;
            border-top: none;
        }

        &__menu-item {
            padding: 9px 42px 9px 10px;

            &:hover,
            &--active {
                background: $border-input;
            }
        }
    }
}