.phonicear-main {
    .footer-block {
        height: auto;

        &__top {
            color: $white;

            h4 {
                margin-bottom: 15px;
            }

            a {
                font-family: $MaxPro-Light;
                font-size: px-to-rem(14);
                color: $white;
                line-height: normal;
                margin-top: 3px;
                margin-bottom: 3px;
                width: 100%;
                padding: 3px 10px 8px;
                @include default-transition(all);

                &:hover {
                    background-color: rgba($white, .2);
                    font-family: $MaxPro-Bold;
                    color: $white !important;
                }
            }

            p {
                margin-bottom: 20px;
            }

            ul {
                li {
                    border-top: 1px solid $white;

                    &:last-of-type {
                        border-bottom: 1px solid $white;
                    }
                }
            }
        }

        &__bottom {
            background-color: $white;
        }
    }

    .bottomlinks,
    .copyright {
        font-size: px-to-rem(16);
        font-family: $MaxPro-Light;
        color: $footer-color;
        float: none;
    }

    .bottomlinks {
        // margin-top: 25px;
        font-size: px-to-rem(14);

        a {
            text-decoration: none;
            color: $error-color;
            font-family: $MaxPro-Bold;
        }

        li {
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}