.horselbutik-main {
    .text-image-component {
        background: $white;

        .button {
            margin-left: auto;

            @media (min-width: $breakpoint-large) {
                margin-left: 0;
            }
        }
    }
}