.phonicear-main {
    .header {
        background: $white;
        border-bottom: 2px solid $border-product;

        @media (max-width: 767px) {
            padding-top: 40px;
        }

        &__menu {
            background-color: $white;

            @media (max-width: 767px) {
                padding: 2% 2%;

                li {
                    &.first {
                        a {
                            &.menu-link {
                                color: $color-border;
                                padding-bottom: 0;
                                padding-top: 0;
                            }
                        }
                    }
                }

                .menu-standard-navigation {
                    border-bottom: none;
                }
            }
        }

        .links {
            padding-top: 23px;

            @media (max-width: 767px) {
                margin-left: 0;
                padding-left: 13px;
                border-bottom: 1px solid $cart-background;
            }

            & > li {
                @media (max-width: 767px) {
                    &.customer-service {
                        a {
                            margin-left: 0;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }

                a {
                    color: $color-border;
                    font-size: px-to-rem(14);
                    font-family: $MaxPro-Bold;
                    padding-left: 0;
                    margin-left: 16px;
                    border-radius: 8px;
                    padding: 3px 7px;
                    padding-top: 0;
                    @include default-transition(all);

                    &:hover {
                        background-color: rgba($color-border, .1);
                    }
                }

                > a {
                    &:first-of-type {
                        &::before {
                            content: url('../../../images/user-icon-phonic-ear.svg');
                            transform: scale(.9);
                            top: 0px;
                            left: -30px;
                        }
                    }

                    &.logged-in {
                        &:first-of-type {
                            &::before {
                                content: url('../../../images/user-icon-logged-phonic-ear.svg');
                            }
                        }
                    }
                }
            }
        }

        .logo {
            width: 200px;
            margin-top: 33px;
        }
    }

    .headercart {
        margin-left: 30px;

        &__top {
            margin-top: 5px;

            a {
                color: $color-border;
                font-family: $MaxPro-Bold;
                font-size: px-to-rem(16);
                padding-bottom: 5px;

                .icon-top-cart {
                    line-height: 1;
                    height: auto;

                    &::before {
                        content: url('../../../images/shopping-cart-phonic-ear.svg');
                        transform: scale(.9);
                    }
                }

                span {
                    font-size: px-to-rem(14);
                    font-family: $OpenSans-Bold;
                    line-height: normal;

                    &.headercart-items {
                        margin-left: 12px;
                        font-family: $OpenSans-Bold;
                    }
                }
            }
        }
    }

    .headercart-inner {
        margin-top: 10px;

        &::before,
        &::after {
            border-bottom-color: $cart-background;
        }

        .product-name {
            margin-bottom: 3px;
            min-height: 75px;

            span {
                font-family: $MaxPro-Light;
                font-size: px-to-rem(16);
            }
        }

        .inner-options {
            font-family: $MaxPro-Light;
        }

        .mini-products-list {
            .item {
                position: relative;
                border-top: 2px solid $border-product;
                padding-top: 20px;
                padding-left: 10px;
                padding-right: 10px;

                &:first-of-type {
                    border-top: none;
                }
            }

            li {
                &.item {
                    &--shipping-price {
                        font-size: px-to-rem(16);

                        .price {
                            font-family: $OpenSans-Bold;
                        }
                    }
                }
            }
        }

        .product-details,
        .product-image {
            float: none;
            display: inline-block;
            font-size: px-to-rem(16);
            font-family: $OpenSans-Bold;
        }

        .product-details {
            width: 170px;
        }

        .product-details-lower {
            display: flex;
            flex-direction: column;
            margin-top: 0;
            margin-bottom: 20px;
        }

        .item-options {
            margin: 0;
        }
    }

    .headercart-button {
        .button {
            max-width: none;
        }
    }

    .deskstop-login {
        margin-top: 13px;
    }

    .mobile-header {
        background-color: $white;

        .headercart {
            background-color: $white;
        }

        .hamburger {
            .bread {
                background-color: $color-border;
            }
        }

        .phone-menu {
            a {
                color: $color-border;

                &.active {
                    background: url(../../../images/close-icon-phonicear.svg) 50% no-repeat;
                }
            }
        }

        .logo-min {
            @media (max-width: 767px) {
                margin: 0;
                transform: translate(-50%, -50%);
                width: 150px;
            }
        }
    }
}