.phonicear-main {
    font-family: $MaxPro-Light;

    h1, h2, h3, h4, h5, h6 {
        font-family: $MaxPro-Bold;
    }

    h1 {
        @media (min-width: $breakpoint-large) {
            font-size: px-to-rem(50);
        }
    }

    a {
        @include default-transition(color);
        color: $error-color;

        &:hover {
            color: $color-border !important;
        }
    }

    select {
        padding-left: 5px;
        padding-bottom: 5px;
        font-size: px-to-rem(16);
        cursor: pointer;
    }

    textarea, input {
        font-family: $OpenSans-Light;
        font-size: px-to-rem(16);
        border: 2px solid $border-input;

        &[type=radio] {
            + label {
                padding-top: 11px;
                padding-bottom: 15px;

                span {
                    border: 2px solid $border-input;
                    width: 20px;
                    height: 20px;
                    @include default-transition(all);
                    top: 50%;
                    transform: translateY(-50%);

                    &::before {
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        width: 16px;
                        height: 16px;
                    }

                    &:hover {
                        background-color: $border-input;
                        border: 2px solid $black;
                    }
                }
            }
            &:checked {
                + label {
                    span {
                        border: 2px solid $border-input;

                        &::before {
                            background-color: $color-border;
                        }
                    }
                }
            }
        }
    }

    .radio-input-container {
        input[type=radio] {
            + label {
                font-weight: normal;
                font-family: $MaxPro-Bold;
                font-size: px-to-rem(16);
                padding: 15px 16px;
                padding-top: 11px;
                border: 2px solid $border-input;
            }

            &:checked {
                + label {
                    border: 1px solid $black;
                }
            }
        }
    }

    .checkout-onepage-index {
        .checkout-container {
            input[type=radio] {
                + label {
                    font-family: $MaxPro-Bold;
                    font-weight: normal;
                }
            }

            .qc-shipping-choice {
                > p {
                    padding-bottom: 0;
                }
            }
        }
    }

    #search_mini_form,
    #search_mini_form-1 {
        max-width: none;

        @media (max-width: 767px) {
            border-bottom: none;
        }

        .input-wrapper {
            &::before {
                left: auto;
                right: 20px;
            }
        }

        input {
            background: $white;
            border: 1px solid $border-input;
            box-sizing: border-box;
            border-radius: 24px;
            width: 200px;
            height: 43px;
            padding: 0 50px 0 20px;
            font-family: $MaxPro-Light;
            font-size: px-to-rem(16);
            padding-bottom: 3px;

            @media (min-width: $breakpoint-large) {
                width: 300px;
            }

            @media (min-width: $breakpoint-xlarge) {
                width: 424px;
            }

            @media (max-width: 767px) {
                margin-left: 0;
                width: 100%;
                padding-bottom: 5px;
            }

            &::-webkit-input-placeholder {
                color: $border-input;
            }

            &:-ms-input-placeholder {
                color: $border-input;
            }

            &::placeholder {
                color: $border-input;
            }
        }
    }

    .br-small {
        #search_mini_form-1 {
            input {
                &:focus {
                    background-color: $white;
                    color: $footer-color;
                    opacity: 1;
                }
            }
        }
    }

    .button,
    .button-addToCart {
        background: $color-border;
        border-radius: 27px;
        max-width: 280px;
        width: calc(100% - 30px);
        height: 44px;
        border: none;
        color: $white;
        @include default-transition(background);
        font-size: px-to-rem(16);
        z-index: 2;
        position: relative;
        margin: 15px auto;
        box-sizing: border-box;
        font-family: $MaxPro-Bold;
        text-decoration: none;
        padding: 0px 10px 4px;
        font-weight: 400;
        line-height: 40px;

        &:hover {
            background: $button-blue;
            color: $white !important;
        }

        &:active,
        &:focus {
            animation-name: pulse;
            animation-duration: 300ms;
            animation-timing-function: ease-in-out;
        }

        .icon-add-to-cart {
            display: none;
        }
    }

    .product-incart {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: transparent;

        .icon {
            background: url('../images/phonic-ear-tick.svg');
            width: 60px;
            height: 60px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            margin: 0;
            border: none;

            &::before {
                display: none;
            }
        }

        .text {
            color: $black;
            font-size: px-to-rem(20);
            line-height: 31px;
        }
    }

    .breadcrumbs {
        &__list {
            border-bottom: none;
            margin-bottom: 10px;

            li {
                font-family: $MaxPro-Bold;
                font-size: px-to-rem(16);
                margin-left: 0;

                a,
                span {
                    font-family: $MaxPro-Light;
                    font-size: px-to-rem(16);
                }

                a {
                    color: $header-top;

                    &:hover {
                        color: $header-top !important;
                    }
                }

                span {
                    top: 2px;
                    height: 15px;
                    width: 7px;
                    margin: 0 12px;
                    background-image: url('../images/breadcrumb-arrow.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    vertical-align: baseline;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    #recently-added-container {
        top: 30px;
        width: 380px;

        .viewing {
            background: $cart-background;
            color: $cart-color;
            font-size: px-to-rem(20);
            padding: 14px 15px;

            p {
                font-family: $OpenSans-Bold;

                span {
                    font-size: px-to-rem(20);
                }
            }

            .sum {
                font-size: px-to-rem(20);
                vertical-align: baseline;
                font-family: $OpenSans-Bold;
            }
        }
    }

    .showcart {
        font-family: $MaxPro-Bold;

        span {
            &.price {
                font-family: $OpenSans-Bold;
                font-size: px-to-rem(16);
            }

            &.price-amount {
                position: absolute;
                right: 10px;
                bottom: 20px;
            }
        }

        .mini-products-list {
            .product-image {
                display: inline-block !important;
                vertical-align: top;
            }

            .btn-remove {
                position: absolute;
                top: 14px;
                right: 10px;
                width: auto;
                background-image: url('../images/minicart-cross.svg');
                text-indent: -9999px;
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }

        .item-options {
            li {
                font-size: px-to-rem(16);
                font-weight: normal;
                font-family: $MaxPro-Light;

                span {
                    font-family: $MaxPro-Bold;
                }
            }
        }
    }

    .cart-collateral {
        .title {
            background: $cart-background;
            font-family: $MaxPro-Bold;
            font-size: px-to-rem(16);
        }

        .cart-info {
            background: $white;
            border: 2px solid $border-product;
            border-top: none;

            .button-addToCart {
                width: 100%;
                max-width: none;
            }
        }

        .continue-link {
            padding: 0;
            margin-top: 0;
            font-family: $MaxPro-Bold;

            &::before {
                display: none;
            }
        }
    }

    .cart-stickybanner {
        padding-bottom: 5px;

        &__wrapper {
            font-family: $OpenSans-Bold;
            font-size: px-to-rem(18);
        }

        .button-addToCart {
            width: 100%;
            max-width: none;
        }
    }

    .cart-block {
        .product-name {
            font-family: $MaxPro-Bold;
            margin-bottom: 10px;
            font-size: px-to-rem(18);

            > a {
                display: inline-block;
                margin-bottom: 10px;
                color: $header-top;

                &:hover {
                    color: $header-top !important;
                }
            }
        }

        .product-name-wrapper,
        .product-info {
            .item-number,
            .price {
                font-family: $OpenSans-Light;
            }
        }

        .increment-input {
            &__minus {
                background: url('../images/phonic-ear-minus.svg');
                background-position: center center;
            }

            &__plus {
                background: url('../images/phonic-ear-plus.svg');
                background-position: center center;
            }

            &__qty {
                font-family: $OpenSans-Bold;
                font-size: px-to-rem(16);
                margin: 0 5px !important;
            }
        }

        .btn-remove {
            background: url('../images/phonic-ear-cross.svg');
            background-position: center center;
            background-repeat: no-repeat;
            text-indent: -9999px;
        }

        .item-options {
            font-family: $MaxPro-Light;
            font-size: px-to-rem(16);

            .price-option {
                display: none;
            }

            strong {
                font-family: $OpenSans-Bold;
            }
        }

        .short-description {
            font-family: $MaxPro-Light;
            font-size: px-to-rem(16);
        }

        .product-detailing {
            .cart-price {
                font-family: $OpenSans-Bold;
                font-size: px-to-rem(16);
            }
        }

        .cell-hide {
            text-align: center;
        }

        table {
            font-family: $MaxPro-Light;
        }
    }

    .cart-table {
        thead {
            th {
                font-family: $MaxPro-Light;
                font-size: px-to-rem(16);

                &.ph-center {
                    text-align: center;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 2px solid $border-product;

                &:first-child {
                    border-top: 2px solid $border-product;
                }
            }

            td {
                padding: 15px 0;
                padding-top: 15px;
            }
        }

        &#shopping-cart-table {
            tbody {
                td {
                    padding: 0;
                }
            }
        }
    }

    #shopping-cart-totals-table {
        tr {
            td {
                padding-bottom: 10px;

                .price {
                    font-family: $OpenSans-Bold;
                }
            }
        }

        tfoot {
            td {
                padding-top: 10px;
                border-top: 2px solid $border-product;
            }
        }
    }

    .table-condensed {
        tbody {
            td {
                padding-bottom: 10px;
                font-family: $MaxPro-Light;
                font-size: px-to-rem(16);

                .price {
                    font-family: $OpenSans-Bold;
                }
            }

            tr.last {
                td {
                    border-top: 2px solid $border-product;
                    padding-top: 10px;
                    font-size: px-to-rem(20);

                    &.a-right {
                        strong {
                            font-family: $MaxPro-Bold;
                        }
                    }
                }
            }
        }
    }

    .checkout-step {
        &__title {
            background: $cart-background;
            padding: 13px 15px;

            span {
                &.text {
                    font-family: $MaxPro-Bold;
                    font-size: px-to-rem(16);
                }
            }
        }

        label {
            font-family: $MaxPro-Light;
            padding-top: 11px;
            padding-bottom: 15px;
            font-size: px-to-rem(16);

            &.css-label {
                font-weight: normal;
            }
        }

        input {
            height: 40px;
            border: 2px solid $border-input;
        }
    }

    .content-container {
        background: $white;
        border: 2px solid $border-product;
        border-top: none;
        min-height: 0px;

        .checkout-final {
            .button-addToCart {
                max-width: none;
                width: 100%;
            }
        }
    }

    #click-here-to-show-login {
        font-family: $MaxPro-Bold;

        span {
            font-family: $MaxPro-Light;
        }
    }

    #click-here-to-show-login,
    .back-link {
        color: $error-color;

        &:hover {
            color: $black;
        }
    }

    .validation-advice {
        color: $error-color;
        font-family: $MaxPro-Bold;
    }

    .new-users,
    .registered-users {
        background: $white;
        width: 100%;
        max-width: 100%;
        margin: 0;
        margin-bottom: 30px;

        @media (min-width: $breakpoint-medium) {
            max-width: 50%;
            margin-bottom: 120px;
        }

        input {
            height: 40px;
            @include default-transition(border);

            &:focus {
                border: 1px solid $black;
            }
        }
    }

    .new-users {
        @media (min-width: $breakpoint-medium) {
            padding-left: 130px;
            padding-right: 60px;
        }

        .buttons-set {
            .button {
                float: left;
                max-width: 220px;
            }
        }
    }

    .registered-users {
        @media (min-width: $breakpoint-medium) {
            border-right: 2px solid $border-product;
            padding-right: 130px;
            padding-left: 60px;
        }

        .login-block {
            form {
                > div {
                    > a {
                        color: $error-color;
                        font-family: $MaxPro-Bold;
                    }

                    &:last-of-type {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

                .button {
                    margin-top: 25px;
                    max-width: 170px;
                    float: none;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    .account-login {
        font-size: px-to-rem(16);

        h2 {
            font-size: px-to-rem(20);
        }

        p {
            margin: 25px 0;
            font-family: $MaxPro-Light;
        }
    }

    .form-list {
        label {
            font-size: px-to-rem(16);
            font-family: $MaxPro-Light;
        }
    }

    .account-create {
        .wr-block {
            background: $white;

            .form-validate {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .col-2 {
                    width: 50%;
                    margin: 0;

                    h2 {
                        font-size: px-to-rem(20);
                        margin-bottom: 20px;
                    }

                    .form-list {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .form-list {
            input {
                margin-bottom: 10px;
            }
        }

        .button {
            margin-top: 50px;
            float: none;
        }
    }

    .cart-empty-block {
        color: $header-top;

        .wrapper {
            h1 {
                font-size: px-to-rem(50);
            }

            p {
                font-family: $MaxPro-Light;
            }

            a {
                font-family: $MaxPro-Bold;
                color: $error-color;
                text-decoration: none;
            }
        }
    }

    .page-not-found {
        h1 {
            font-size: px-to-rem(50);
        }
    }

    .termsAndConditionsCheckbox {
        font-family: $MaxPro-Light;

        a {
            text-decoration: none;
            color: $error-color;
            font-family: $MaxPro-Bold;
            @include default-transition(color);
        }

        input {
            + label {
                .terms-checkbox {
                    font-family: oticon-icons;
                    left: 0;
                    line-height: 18px;
                    position: absolute;
                    border-radius: 10px;
                    text-align: center;
                    border: 2px solid $border-input;
                    width: 20px;
                    height: 20px;
                    transition: 0.2s ease all;
                    top: 50%;
                    transform: translateY(-50%);

                    &:hover {
                        background-color: $border-input;
                    }

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        border-radius: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            &:checked {
                + label {
                    .terms-checkbox {
                        &::before {
                            background-color: $color-border;
                        }
                    }
                }
            }
        }

        label {
            &::before {
                display: none !important;
            }
        }
    }

    #checkout-step-review {
        .content-container {
            padding-bottom: 9px;
        }
    }

    .catalog-category-searchresult {
        .message-block {
            text-align: center;
            font-size: px-to-rem(32);
            font-family: $MaxPro-Bold;
            border: none;
            margin-top: 28px;

            .note-msg {
                margin-top: 50px;
                font-size: px-to-rem(20);
                font-family: $MaxPro-Light;
            }
        }
    }

    .oticon-address {
        .block-account {
            &__content {
                li {
                    border-left: 2px solid transparent;

                    &.current {
                        border-left: 2px solid $error-color;
                    }
                }

                a {
                    font-family: $MaxPro-Bold;
                    font-size: px-to-rem(16);
                    color: $footer-color;

                    strong {
                        color: $error-color;
                    }
                }
            }
        }

        .address-book {
            .form-list {
                input {
                    padding: 10px;
                }
            }
        }
    }

    .box-account {
        &__box {
            .box-top {
                h3 {
                    font-size: px-to-rem(18);
                }
            }

            .box-content {
                .button {
                    margin-left: 0;
                }

                p {
                    font-family: $OpenSans-Light;
                }
            }
        }
    }

    .address-book {
        .button {
            z-index: 0;
        }
    }

    .error-msg {
        color: $error-color;
    }

    .default-font {
        font-family: $SohoGothicPro-Regular;
    }

    .order-info-block {
        h1 {
            font-family: $OpenSans-Bold;
        }

        .order-date {
            font-family: $OpenSans-Light;
            font-size: px-to-rem(14);
        }

        .box-account {
            &__box {
                .box-content {
                    font-family: $OpenSans-Light;
                    line-height: 21px;
                }
            }
        }
    }

    .order-hash {
        visibility: hidden;
        padding: 0;
        width: 8px;
        display: inline-block;
    }
}