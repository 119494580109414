.image-gallery {
    position: static !important;

    .image-gallery-content,
    .image-gallery-slide-wrapper {
        position: static;
    }

    &__nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 80px;
        width: 80px;
        z-index: 9;
        @include default-transition(opacity);
        background: none;
        border: none;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 2px;
            height: 30px;
            background-color: $lightgrey;
            left: 50%;
            @include default-transition(background-color);
        }

        &::before {
            top: 15px;
        }

        &::after {
            top: 35px;
        }

        &:hover {
            &::before,
            &::after {
                background-color: $black;
            }
        }

        &--left {
            left: 0;

            &::before {
                transform: rotate(45deg);

            }

            &::after {
                transform: rotate(-45deg);
            }
        }

        &--right {
            right: 0;

            &::before {
                transform: rotate(-45deg);

            }

            &::after {
                transform: rotate(45deg);
            }
        }

        &--disabled {
            opacity: 0;
        }
    }
}