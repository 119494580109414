.horselbutik-main {
    .welcome-banner {
        &__heading {
            font-family: $SourceSansPro-Bold;

            @media (min-width: $breakpoint-large) {
                font-size: px-to-rem(50);
            }
        }
    }
}