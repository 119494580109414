.custom-select {
    position: relative;
    z-index: 3;

    &.open {
        z-index: 4;
    }

    &__btn-wrapper {
        height: 2.5625rem;
        position: relative;
    }

    &__btn {
        width: 100%;
        font-size: 14px;
        padding: 11px 42px 15px 10px;
        border: 1px solid $color-borderInput;
        border-bottom: none;
        font-family: $SohoGothicPro-Regular;
        font-weight: 400;
        color: $default-color;
        text-align: left;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url('/static/media/select-down-arrow.54a16b4d.svg') no-repeat center right;
        background-color: $white;
        background-size: 29px;
        outline: none;

        &.placeholder {
            color: $lightgrey;
        }

        &:hover {
            background: url('/static/media/select-hover-down.516e56ea.svg') no-repeat center right;
            background-color: $white;
            background-size: 29px;
        }

        @media (max-width:767px) {
            padding: 10px 30px 10px 10px;
        }
    }

    &__dropdown-menu {
        position: absolute;
        left: 0;
        z-index: 1;
        width: 100%;
        max-width: 100%;
        border: 1px solid $color-dropdown;
        border-top: none;
        font-size: 14px;
        text-align: left;
        list-style: none;
        background-color: $white;
        @include default-transition(all);
        max-height: 0;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0;
    }

    &__menu-list {
        padding-left: 0;
    }

    &__menu-item {
        cursor: pointer;
        padding: 11px 42px 15px 10px;
        font-size: .875rem;
        font-weight: normal;
        background-color: $white;
        @include default-transition(background-color);
        list-style-type: none;
        text-indent: 0;
        margin-top: 0;

        &:hover,
        &--active {
            background-color: $color-dropdownHover;
        }

        @media (max-width:767px) {
            padding: 10px 30px 10px 10px;
        }
    }
}