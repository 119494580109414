.horselbutik-main {
  color: $black;
  font-family: $SourceSansPro-Regular;
  font-size: px-to-rem(16);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $SourceSansPro-Bold;
    font-weight: normal;
  }

  a {
    color: $default-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    font-family: $SourceSansPro-Bold;
    font-weight: normal;
  }

  table,
  label {
    font-family: $SourceSansPro-Regular;
    font-weight: normal;
  }

  textarea,
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    border: none;
    border-bottom: 1px solid $default-inputColor;
    color: $default-activeColor;
    font-family: $SourceSansPro-Regular;
    transition: all 0.2s ease;
    height: auto;
    padding: 8px 0;

    ::-webkit-input-placeholder {
      display: none;
    }

    :-ms-input-placeholder {
      display: none;
    }

    ::placeholder {
      display: none;
    }

    & + label {
      position: absolute;
      pointer-events: none;
      cursor: text;
      opacity: 0.6;
      transition: all 0.3s ease;
      left: 0;
      top: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100% - 14px);
      overflow: hidden;
      font-size: px-to-rem(16) !important;
      margin: 0;
      padding: 0;
    }

    &:active,
    &:focus,
    &.has-value {
      border-bottom: 1px solid $black;
      color: $black;

      & + label {
        left: 0;
        top: -10px;
        font-size: px-to-rem(12) !important;
        color: $default-labelColor;
      }
    }
  }

  input[type="radio"] {
    & + label {
      span {
        border: 1px solid $checkbox-stroke;
        @include default-transition(all);

        &::before {
          top: 2px;
          left: 2px;
          width: 12px;
          height: 12px;
        }
      }

      &:hover {
        span {
          background: $light-grey;
        }
      }
    }

    &:checked {
      & + label {
        span {
          &::before {
            background-color: $default-color;
          }
        }
      }
    }
  }

  input[type="checkbox"] {
    &.css-checkbox + label.css-label:before {
      top: 12px;
    }
  }

  textarea {
    border: none;
    border-top: 1px solid $default-inputColor;
    border-bottom: 1px solid $default-inputColor;
    font-family: $SourceSansPro-Regular;
    transition: all 0.2s ease;
    height: auto;
    padding: 8px 0;
    padding-top: 22px;

    &:focus {
      outline: none;
    }

    &:active,
    &:focus,
    &.has-value {
      & + label {
        top: -6px;
      }
    }
  }

  .input-container,
  .select-container {
    position: relative;
  }

  .input-container {
    margin-top: 20px;
    padding-bottom: 20px;
  }

  .custom-checkbox {
    padding-left: 26px;
    font-size: px-to-rem(14);
    line-height: 20px;

    &:hover {
      input {
        & ~ span {
          background-color: $light-grey;
        }

        &:checked {
          & ~ span {
            background-color: $default-color;
          }
        }
      }
    }

    &__input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        & ~ span {
          background-color: $default-color;

          &::after {
            display: block;
          }
        }
      }
    }

    &__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 2px;
      background-color: $white;
      border: 1px solid $checkbox-stroke;
      @include default-transition(all);

      &::after {
        content: "";
        position: absolute;
        display: none;
        width: 100%;
        height: 100%;
        background: url("../images/horselbutik-tick.svg") center center
          no-repeat;
      }
    }

    &.inverted {
      &:hover {
        input:not(:checked) {
          & ~ span {
            border-color: $default-color;
          }
        }
      }

      .custom-checkbox__input {
        &:checked {
          & ~ span {
            background-color: $white;
          }
        }
      }

      .custom-checkbox__checkmark {
        border: 1px solid $white;

        &::after {
          background-image: url("../images/horselbutik-tick-invert.svg");
        }
      }
    }
  }

  .cms-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $SourceSansPro-Bold;
      font-weight: normal;
    }
  }

  #search_mini_form,
  #search_mini_form-1 {
    @media (min-width: $breakpoint-medium) {
      margin-top: 0;
    }

    .input-wrapper {
      &::before {
        display: none;
      }

      .search-icon {
        display: inline-block;
        position: absolute;
        width: 19px;
        height: 19px;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        background: none;
        border: none;

        &::before {
          content: url("../images/horselbutik-search.svg");
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          color: $lightgrey;
          font-family: iconfont;
          font-size: 19px;
        }

        @media (min-width: $breakpoint-medium) {
          transform: none;
          left: auto;
          right: 10px;
          margin-top: -10px;
        }
      }
    }

    input {
      font-family: $SourceSansPro-SemiBold;
      background: $input-background;
      border-radius: 5px;
      margin-left: 0;
      width: 100%;
      color: black;

      @media (min-width: $breakpoint-medium) {
        font-size: px-to-rem(16);
        padding: 0 12px;
        height: 32px;
        line-height: normal;
      }

      @media (min-width: $breakpoint-large) {
        width: 289px;
      }

      &:focus {
        background: darken($input-background, 5%);
        color: black;
      }

      &::-webkit-input-placeholder {
        color: $default-activeColor;
      }

      &:-ms-input-placeholder {
        color: $default-activeColor;
      }

      &::placeholder {
        color: $default-activeColor;
      }
    }
  }

  .button,
  .button-addToCart {
    background: $default-buttonColor;
    border-radius: 27px;
    max-width: 280px;
    width: calc(100% - 30px);
    border: none;
    color: $white;
    transition: 0.2s ease color, 0.2s ease background-color;
    font-size: px-to-rem(16);
    z-index: 2;
    position: relative;
    margin: 15px auto;
    box-sizing: border-box;
    font-family: $SourceSansPro-Bold;
    text-decoration: none;
    padding: 10px;

    &.buy-button {
      background: $default-buyButtonColor;
      &:hover {
        background: $white;
        color: $default-buyButtonColor;
        box-shadow: 0px 0px 8px rgba(233, 85, 110, 0.3);
      }
    }

    &:hover {
      background: $white;
      color: $default-color;
      box-shadow: 0px 0px 8px rgba(96, 97, 165, 0.3);
    }

    &:active,
    &:focus {
      animation-name: pulse;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      color: $default-buttonColor;
      background: rgba($default-buttonColor, 0.2);
    }

    .icon-add-to-cart {
      display: none;
    }
  }

  #recently-added-container {
    .viewing {
      background: $default-backgroundColor;
    }
  }

  .breadcrumbs {
    &__list {
      li {
        font-family: $SourceSansPro-Bold;
        font-size: px-to-rem(16);

        a,
        span {
          font-family: $SourceSansPro-SemiBold;
        }

        a {
          font-size: px-to-rem(16);
        }
      }
    }
  }

  .page-big-title {
    font-family: $SourceSansPro-Bold;

    @media (min-width: $breakpoint-medium) {
      font-size: px-to-rem(40);
    }
  }

  .cart-block {
    .product-name {
      font-family: $SourceSansPro-Bold;

      a {
        color: $black;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .short-description {
      font-family: $SourceSansPro-Regular;
    }

    .product-detailing {
      .cart-price {
        font-family: $SourceSansPro-Bold;
        font-weight: normal;
        font-size: px-to-rem(16);
      }
    }

    .increment-input {
      width: px-to-rem(14);
      height: px-to-rem(14);
      margin: 0 8px !important;

      &__minus {
        background: url("../images/horselbutik-minus.svg");
        background-position: center center;
        background-repeat: no-repeat;
      }

      &__plus {
        background: url("../images/horselbutik-plus.svg");
        background-position: center center;
        background-repeat: no-repeat;
      }

      &__qty {
        width: 1.5rem !important;
        font-family: $SourceSansPro-SemiBold;
        font-weight: normal;
        font-size: px-to-rem(16);
      }
    }
  }

  .cart-collateral {
    > div {
      box-shadow: $product-boxshadow;
    }

    .title,
    .cart-info {
      background: $white;
      font-family: $SourceSansPro-Regular;
      font-weight: normal;
    }

    .title {
      font-family: $SourceSansPro-Bold;
      background: $default-backgroundColor;
    }
  }

  .continue-link {
    padding-left: 0;
    margin-top: 2px;
    color: $default-color;
    font-size: px-to-rem(16);
    font-family: $SourceSansPro-SemiBold;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      display: none;
    }
  }

  .vat-block {
    label {
      strong {
        font-family: $SourceSansPro-Bold;
        font-weight: normal;
      }
    }
  }

  .checkout-step {
    &__title {
      background: $white;

      > span {
        font-family: $SourceSansPro-Bold;
        font-weight: normal;
        font-size: px-to-rem(20);
      }
    }

    label {
      &.css-label {
        font-weight: normal;
        font-family: $SourceSansPro-Bold;
      }
    }
  }

  .content-container {
    background: $white;
  }

  .checkout-container {
    .section {
      box-shadow: $product-boxshadow;
    }
  }

  .checkout-onepage-index {
    .section {
      .payment-methods {
        padding: 0 15px;
      }
    }
  }

  .discount {
    label {
      font-family: $SourceSansPro-Bold;
    }
  }

  .showcart {
    span {
      &.price {
        font-family: $SourceSansPro-Bold;
        font-weight: normal;
      }
    }

    .btn-remove {
      position: absolute;
      top: 14px;
      right: 10px;
      width: auto;
      background-image: url("../images/minicart-cross.svg");
      text-indent: -9999px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .new-users,
  .registered-users {
    background: $white;
    box-shadow: $product-boxshadow;
  }

  .registered-users {
    .login-block {
      form {
        > div {
          &:last-of-type {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        a {
          font-family: $SourceSansPro-SemiBold;
        }

        .button {
          float: none;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  #shopping-cart-totals-table {
    tr {
      td {
        padding-bottom: 10px;
      }
    }

    tfoot {
      td {
        font-family: $SourceSansPro-Bold;
        font-weight: normal;
        font-size: px-to-rem(20);
        padding-top: 10px;
        border-top: 2px solid $default-backgroundColor;
      }
    }

    .total-discount {
      font-weight: bold;
      color: $default-buyButtonColor;
    }
  }

  .account-login {
    font-family: $SourceSansPro-Regular;
    font-weight: normal;

    h2 {
      font-family: $SourceSansPro-Bold;
      font-size: px-to-rem(20);
    }

    p {
      font-family: $SourceSansPro-Regular;
    }
  }

  .oticon-address {
    .block-account {
      &__content {
        li {
          padding-bottom: 5px;

          &.current {
            border-left: none;
          }
        }

        a {
          font-family: $SourceSansPro-Regular;
          font-weight: normal;
          font-size: px-to-rem(20);
          color: $default-color;

          strong {
            font-family: $SourceSansPro-Bold;
            color: $default-color;
          }
        }
      }
    }

    .account-wr {
      h2,
      h3 {
        font-family: $SourceSansPro-Bold;
        font-weight: normal;
      }
    }

    .form-list {
      .custom-select {
        &__label {
          margin-left: 9px;
        }
      }

      &--flex-rev {
        display: flex;
        flex-direction: column-reverse;
        margin-left: 0;

        .custom-select__label {
          margin-left: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .box-account {
    &__box {
      .box-content {
        p {
          font-family: $SourceSansPro-Regular;
        }

        .button {
          margin-left: 0;
          width: 120px;
        }
      }
    }
  }

  .wr-block {
    background: $white;
    box-shadow: $product-boxshadow;

    .legend {
      margin-bottom: 40px;
    }
  }

  .product-incart {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;

    .icon {
      background: url("../images/horselbutik-checkmark.svg");
      width: 60px;
      height: 60px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      margin: 0;
      border: none;

      &::before {
        display: none;
      }
    }

    .text {
      color: $black;
      font-size: px-to-rem(20);
      line-height: 31px;
      font-family: $SourceSansPro-Bold;
    }
  }

  #click-here-to-show-login,
  .back-link {
    font-family: $SourceSansPro-Bold;
    font-weight: normal;
    color: $default-color;

    &:hover {
      color: $default-color;
    }

    span {
      font-family: $SourceSansPro-Regular;
    }
  }

  .table-condensed {
    tbody {
      td {
        padding-bottom: 10px;
        font-family: $SourceSansPro-Regular;
        font-size: px-to-rem(16);

        .price {
          font-family: $SourceSansPro-Bold;
          font-weight: normal;
        }
      }

      tr.last {
        td {
          border-top: 2px solid $border-product;
          padding-top: 10px;
          font-size: px-to-rem(20);

          &.a-right {
            strong {
              font-family: $SourceSansPro-Bold;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  .validation-advice {
    font-family: $SourceSansPro-Regular;
    color: $error-color;
  }

  .footer-validation-advice {
    font-family: $SourceSansPro-Regular;
    color: lighten($color: $error-color, $amount: 25%);
  }

  .std {
    ul,
    ol,
    dl,
    p,
    address {
      font-family: $SourceSansPro-Regular;
    }
  }

  .message-block {
    font-family: $SourceSansPro-Regular;
    border: 1px solid $color-borderInput;
  }

  .discount {
    .coupon-box {
      padding-left: 0;
    }

    label {
      font-family: $SourceSansPro-Bold;
      font-weight: bold;
    }

    input {
      width: 58%;
    }

    .button {
      width: 30%;
    }

    .response-disclaimer {
      padding-top: 10px;
      font-family: $SourceSansPro-Regular;
      font-size: 12px;
      color: $default-color;

      &.error {
        color: $error-color;
      }
    }
  }

  .product-block {
    .favourites-button {
      &:hover {
        span {
          color: $default-color;
        }
      }
    }

    .favourites-button-full {
      color: $default-color;
    }
  }

  .pagination {
    li {
      a {
        cursor: pointer;
        border: none;
        border-radius: 27px;
        @include default-transition(background-color);

        &:hover {
          text-decoration: none;
          background-color: $default-backgroundColor;
        }

        &.active {
          background-color: $default-color !important;
        }
      }

      &.disabled {
        a {
          pointer-events: none;
          color: $lightgrey;
        }
      }
    }
  }

  .category-products.favourites {
    & > .category-products {
      width: 100%;

      @media (min-width: $breakpoint-medium) {
        margin: 20px auto;
      }
    }
  }

  .breakpoint-medium-new-line {
    &::before {
      @media (min-width: $breakpoint-medium) {
        content: "\A";
        white-space: pre;
      }
    }
  }
}
