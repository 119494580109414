.phonicear-main {
    .products-grid {
        &__item {
            background-color: $white;
            border: 2px solid $border-product;
            @include default-transition(box-shadow);

            &:hover {
                box-shadow: 0px 0px 10px rgba($black, 0.15);
            }

            .product-detailing-top {
                height: 100%;
                z-index: -1;
                @include default-transition(all);

                &.is-active {
                    z-index: 1;
                    background: rgba($white, 0.95);
                }
            }

            .actions {
                height: auto;
                color: $default-color;

                .price-box {
                    margin-top: 10px;

                    .regular-price {
                        font-size: px-to-rem(20);
                    }
                }

                &__inner {
                    height: auto;
                }
            }

            .product-name {
                font-family: $MaxPro-Bold;
                margin-bottom: 10px;
                font-size: px-to-rem(20);
                max-height: none;
                height: 62px;
            }

            .short-description {
                font-size: px-to-rem(16);
                height: auto;
                min-height: 25px;

                &:first-of-type {
                    margin-bottom: 10px;
                    font-size: px-to-rem(16);
                }

                span {
                    @media (max-width: $breakpoint-large) {
                        display: block;
                    }
                }
            }

            .long-description {
                margin-top: 10px;
                font-size: px-to-rem(16);
            }
        }
    }

    .product-block {
        background-color: $white;

        &__shop {
            background-color: $white;

            .product-name {
                font-size: px-to-rem(32);
                margin-bottom: 30px;
                font-family: $MaxPro-Bold;
            }

            .price-box {
                .regular-price {
                    font-size: px-to-rem(32);
                    margin-bottom: 20px;
                    font-family: $OpenSans-Bold;
                }
            }

            .info-block {
                font-size: px-to-rem(20);
                margin-bottom: 40px;

                .inStock,
                .delivery-status {
                    padding-left: 40px;
                    font-weight: bold;
                    position: relative;
                    font-family: $MaxPro-Bold;

                    &::before {
                        content: url('../../../images/phonic-ear-tick.svg');
                        position: absolute;
                        top: 5px;
                        left: 0;
                    }
                }

                .inStock {
                    margin-right: 60px;

                    @media (max-width: 1023px) {
                        display: block;
                        margin-bottom: 20px;
                    }
                }
            }

            .short-description {
                margin-bottom: 30px;
                font-family: $MaxPro-Light;
            }

            .description {
                font-family: $MaxPro-Light;
                line-height: normal;
                font-size: px-to-rem(16);
            }
        }

        &__media {
            .product-image-container {
                max-width: 500px;
            }
        }
    }

    .add-to-cart {
        &__wrapper {
            display: inline-block;
            margin-right: 60px;
            white-space: nowrap;
        }

        &__button {
            display: inline-block;
            background: none;
            border: none;
            outline: none;
            width: 20px;
            height: 20px;
            padding: 0;
            vertical-align: middle;
            background-position: center center;
            background-repeat: no-repeat;

            &--dec {
                background-image: url('../../../images/phonic-ear-minus.svg');
            }

            &--inc {
                background-image: url('../../../images/phonic-ear-plus.svg');
            }
        }

        .qty {
            margin-right: 0;
            float: none;
            min-width: 68px;
            height: 44px;
            margin: 0 10px;
            border: 2px solid $border-input;
            vertical-align: middle;
            font-family: $OpenSans-Light;
        }

        .button-addToCart {
            float: none;
            display: inline-block;
        }
    }
}