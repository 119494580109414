.horselbutik-main {
    .summary-table {
        width: 100%;
        
        &__column {
            &--total {
                font-family: $SourceSansPro-Bold;
                background-color: $default-backgroundColor;
            }
        }
    }
}