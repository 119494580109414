.adyen-checkout {
    &__payment-method {
        margin: 16px 0;
        border: 1px solid $color-borderInput;
        transition: border .2s ease-out;

        &:first-child,
        &:last-child {
            border-radius: 0;
        }

        &--selected {
            border-radius: 0;
            background: $white;
            border: 1px solid $black;
            margin: 16px 0;

            & + .adyen-checkout__payment-method {
                border-radius: 0;
            }

            .adyen-checkout__payment-method__brands {
                .adyen-checkout__payment-method__image__wrapper {
                    margin-bottom: 0;

                    @media (max-width: 991px) {
                        margin-bottom: 4px;
                    }
                }
            }
        }

        &--next-selected {
            border-radius: 0;
        }

        &__name {
            font-weight: 700;
            font-size: px-to-rem(14);
            line-height: 18px;
        }

        &__radio {
            background-color: $white;
            border: 1px solid $color-borderInput;
            width: 18px;
            height: 18px;

            &::after {
                background-color: $default-activeColor;
                height: 10px;
                width: 10px;
            }

            &--selected {
                &:hover {
                    box-shadow: none;
                }
            }
        }

        &:hover {
            border: 1px solid $black;

            &:not(.adyen-checkout__payment-method--selected) {
                .adyen-checkout__payment-method__radio {
                    box-shadow: none;
                    border-color: $black;
                }
            }
        }

        &__image {
            border-radius: 0;
            width: 100%;
            height: 100%;

            &__wrapper {
                height: 18px;
                width: 30px;
                margin-bottom: 0;

                &::after {
                    border-radius: 0;
                }
            }
        }

        &__brands {
            margin: 0;
            height: 18px;

            .adyen-checkout__payment-method__image__wrapper {
                height: 18px;
                width: 30px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        &__header {
            &__title {
                @media (max-width: 991px) {
                    margin-top: 4px;
                    margin-bottom: 4px;
                }
            }
        }
    }

    &__input {
        border-radius: 0;
        caret-color: $black !important;
        padding: 11px 12px;

        &:hover {
            border-color: $color-borderInput;
        }

        &--focus,
        &--focus:hover,
        &:focus,
        &:active,
        &:active:hover {
            border: 1px solid $default-activeColor;
            box-shadow: none;
        }
    }

    &__label {
        &--focused {
            .adyen-checkout__label__text {
                color: $black;
            }
        }

        &__text {
            font-size: px-to-rem(14);
            padding-bottom: 7px;
            color: $black;
        }
    }

    &__button {
        background: $default-activeColor;
        border-radius: 0;
        font-size: px-to-rem(16);

        &:hover {
            box-shadow: none;
            background: $default-color;
        }

        &__icon {
            display: none;
        }
    }

    &__dropdown {
        &__button {
            color: $black;
            border: 1px solid $color-borderInput;
            border-radius: 0;

            &:hover {
                border-color: $default-activeColor;
            }

            &--active,
            &--active:hover,
            &:active,
            &:focus {
                box-shadow: none;
                border-color: $default-activeColor;
            }
        }

        &__list {
            border-radius: 0;
        }
    }

    &__card {
        &__cardNumber {
            &__input {
                padding: 11px 12px;
                font-size: px-to-rem(12);
            }
        }
    }

    &__field {
        margin-bottom: 17px;
    }

    &__icon {
        vertical-align: baseline;
    }
}

.nets-wrapper {
    display: flex;
    justify-content: center;
    @media (max-width: 991px) {
        flex-direction: column;
    }
}

.dibs-checkout-wrapper {
    display: block;
    width: 50%;

    margin-left: 24px;
    @media (max-width: 991px) {
        width: 100%;
    }
}

.nets-summary {
    margin-top: 60px;
    margin-right: 24px;
    width: 35%;
    display: block;

    @media (max-width: 991px) {
        width: 100%;
    }
}

.payments-container {
    margin-bottom: 40px;

    @media (max-width: 991px) {
        margin-bottom: 20px;
    }

    &__heading {
        padding: 16px;
        color: $black;
        background-color: $color-background2;
    }

    &__inner {
        padding: 16px;
        background: $color-grey;
    }
}

.payments-label {
    &--h1 {
        font-size: px-to-rem(36);
        font-family: $SohoGothicPro-Bold;
        margin: 60px 0;

        @media (max-width: 991px) {
            font-size: px-to-rem(24);
            margin: 24px 0;
        }
    }

    &--h2 {
        .payment-product-list & {
            padding: 0 16px;
            padding-bottom: 24px;
        }

        font-size: px-to-rem(18);
        font-family: $SohoGothicPro-Bold;
    }

    &--p-bold {
        .payment-product-list & {
            padding: 0 16px;
            padding-bottom: 16px;
        }

        font-size: px-to-rem(14);
        font-family: $SohoGothicPro-Bold;
        color: $black;
        position: relative;
    }
}

.payments-list-toggle {
    position: absolute;
    right: 16px;
    border: none;
    background: none;
    width: 14px;
    height: 14px;
    padding: 0;
    top: 5px;
    
    &:focus {
        outline: none;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background: $black;
        top: 6px;
        right: 0;
        transform: rotate(0);
        transition: all .2s ease-in-out;
    }

    &::after {
        transform: rotate(90deg);
    }

    .product-list--open & {
        &::before {
            transform: rotate(180deg);
        }
        
        &::after {
            transform: rotate(180deg);
        }
    }
}

.payment-product-list {
    padding: 16px 0;
    margin-bottom: 50px;

    @media (max-width: 991px) {
        padding-bottom: 0;
    }

    .product-image {
        margin: 0;
        min-width: 0;
        width: 119px;
        display: table-cell;
        vertical-align: middle;

        img {
            width: 87px;
        }
    }

    .product-info {
        .product-name {
            margin-bottom: 13px;

            .short-description {
                padding-top: 8px;
            }
        }

        .item-options {
            font-size: px-to-rem(14);

            &:not(:last-of-type) {
                margin-bottom: 5px;
            }
        }
    }

    .product-list {
        .list-content {
            overflow: hidden;
            max-height: 1px;
        }

        &--open {
            .list-content {
                max-height: initial;
            }
        }
    }
}