.cms-index-index {
    .col1-layout {
        .wrapper {
            &.testimonial {
                .bottom-info-block {
                    margin-top: 50px;
                }
            }
        }
    }
}

.bottom-info-block {
    margin: 27px 0;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;

    div {
        position: relative;
        margin-bottom: 25px;
        border-right: 1px solid $color-dropdown;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &:last-child {
            border-right: none;
        }
    }

    .link {
        position: relative;
        width: 44px;
        height: 44px;
        font-size: 24px;
        display: block;
        color: $color-border;
        border: 3px solid $color-border;
        border-radius: 50%;
        margin: 0 auto;
        cursor: pointer;
        overflow: hidden;
        margin-top: auto;

        &:before {
            content: '\e603';
            position: absolute;
            left: 8px;
            top: 6px;
            font-family: oticon-icons;
            animation: cta-right .4s;
        }

        &:hover {
            background-color: $color-border;
            color: $white;

            &:before {
                color: $white;
                animation: cta-right-hover .4s;
            }
        }
    }

    .link-text {
        display: none;
    }

    h6,
    p {
        font-family: $SohoGothicPro-Light;
        font-weight: 300;
    }

    h6 {
        font-size: 24px;
        font-family: $SohoGothicPro-Bold;
        line-height: 30px;
        margin-bottom: 20px;
    }

    p {
        font-size: 16px;
        font-family: $SohoGothicPro-Regular;
        font-weight: 400;
        padding: 0;
        line-height: 27px;
        margin-bottom: 25px;
        margin-top: auto;
    }
}

@media (max-width:767px) {
    .bottom-info-block {
        padding: 0 15px;

        div,
        h6 {
            text-align: center;
        }

        h6 {
            margin-top: 20px;
        }

        div {
            border-right: none;
        }
    }
}

@media (min-width:768px) {
    .bottom-info-block {
        margin-top: 30px;

        div {
            margin-top: 20px;
            padding: 0 40px;
            width: 50%;
        }

        h6 {
            margin-top: 0;
        }
    }
}

@media (max-width:990px) {
    .bottom-info-block {
        h6 {
            font-size: 21px !important;
        }

        p {
            font-size: 16px !important;
        }
    }
}

@media (min-width: 1265px) {
    .bottom-info-block {
        flex-wrap: nowrap;
    }
}