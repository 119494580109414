.bernafon-main {
    .welcome-banner {
        height: 100%;

        &__img {
            position: relative;
            color: $white;
            background: url('../../../images/banner-mobile-bernafon.png');
            height: 300px;
            text-align: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            @media screen and (min-width: $breakpoint-medium) {
                background: url('../../../images/banner-desktop-bernafon.png');
                background-position: center center;
                height: 230px;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }

        &__wrapper {
            display: none;
        }

        &__heading {
            display: table-cell;
            top: auto;
            text-align: center;
            color: $black;
            height: 120px;
            padding: 30px;
            background-color: $header-background;

            @media (min-width: $breakpoint-large) {
                font-size: px-to-rem(50);
                height: 140px;
            }

            @media (max-width: 767px) {
                height: 140px;
            }
        }
    }
}