.summary-table {
    &__column {
        padding: 16px;
        padding-bottom: 0;
        color: $black;

        &:nth-of-type(2) {
            text-align: right;
        }

        &--total {
            padding: 10px 16px;
            background-color: $color-background2;
            font-family: $SohoGothicPro-Bold;
        }
    }

    tr {
        &:nth-last-child(2) {
            td {
                padding-bottom: 16px;
            }
        }
    }
}