.phonicear-main {
    .welcome-banner {
        height: 100%;

        .welcome-banner__img {
            background: url('../../../images/banner-phonic-ear.jpg');
            background-size: cover;
            background-position: center 20%;
            background-clip: content-box;
            background-repeat: no-repeat;
            height: 370px;

            @media (min-width: $breakpoint-large) {
                height: 455px;
            }

            @media (max-width: 767px) {
                background: none;
                height: auto;
            }

            &::before {
                content: '';
                background-color: $footer-color;
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                left: 0;
                z-index: -1;
            }
        }

        &__wrapper {
            background-color: $header-top;
            position: relative;
            top: auto;
            height: 120px;
            display: table;

            @media (min-width: $breakpoint-large) {
                height: 140px;
            }

            @media (max-width: 767px) {
                height: 140px;
            }
        }

        &__heading {
            display: table-cell;
            vertical-align: middle;
            color: $white;
            height: 120px;
            padding: 20px;

            @media (min-width: $breakpoint-large) {
                font-size: px-to-rem(50);
                height: 140px;
            }

            @media (max-width: 767px) {
                height: 100%;
            }
        }

        &+.category-top {
            background: $page-background;
        }
    }
}