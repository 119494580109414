.notice-cookie {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $default-color;
  color: $white;
  font-size: 12px;
  z-index: 1000;

  .notice-inner {
    margin: 10px 3%;
    overflow: hidden
  }

  .notice-text {
    float: left;
    width: 90%;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .actions {
    float: right;

    .close-cookie {
      display: block;
      width: 20px;
      height: 20px;
      background: url(/images/close-icon.png) no-repeat;
      margin-top: 4px;
      cursor: pointer
    }
  }
}
