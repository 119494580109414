.phonicear-main {
    .summary-table {
        &__column {
            font-family: $MaxPro-Bold;

            &:last-of-type {
                font-family: $OpenSans-Bold;
            }

            &--total {
                background-color: transparent;
                border-top: 2px solid $border-product;
            }
        }
    }
}