.forgotpassword-page,
.resetpassword-page {
  .form-list {
    input {
      width: 100%;
    }
  }

  .wr-block {
    .button {
      width: 50%;
      float: right;
      margin-top: 3%;
    }

    @media (min-width:768px) {
      width: 500px;
    }
  }

  .button,
  p {
    margin-top: 2%;
  }
}

.resetpassword-page {
  padding-bottom: 2%;
}