.text-component {
    color: $default-color;
    text-align: center;
    background: $default-gradient;
    padding: 60px 30px;

    &__heading {
        font-size: px-to-rem(30);
        font-family: $SohoGothicPro-Bold;
        margin-bottom: 20px;
    }

    &__text {
        font-size: px-to-rem(16);
        display: block;

        a {
            color: $default-activeColor;
            @include default-transition(color);

            &:hover {
                color: $color-weblinkMagenta;
            }
        }

        ul {
            list-style: disc;
            text-align: center;
            list-style-position: inside;
        }
    }

    .button {
        width: auto;
        margin: 0 auto;
        margin-top: 40px;
        padding: 10px 30px;
        line-height: normal;
        height: auto;
    }
}