.phonicear-main {
    .adyen-checkout {
        &__payment-method {
            &:hover {
                &:not(.adyen-checkout__payment-method--selected) {
                    .adyen-checkout {
                        &__payment-method {
                            &__radio {
                                background-color: $border-input;
                                border: 2px solid $black;
                            }
                        }
                    }
                }
            }

            &__radio {
                border: 2px solid $border-input;

                &::after {
                    background-color: $color-border;
                    height: 12px;
                    width: 12px;
                }
            }

            &__name {
                font-family: $MaxPro-Bold;
                font-weight: normal;
            }
        }

        &__button {
            background: $color-border;
            border-radius: 27px;
            height: 44px;
            border: none;
            color: $white;
            @include default-transition(background);
            font-size: px-to-rem(16);
            z-index: 2;
            position: relative;
            margin: 15px auto;
            box-sizing: border-box;
            font-family: $MaxPro-Bold;
            padding: 0;
            line-height: 24px;

            &:hover {
                background: $button-blue;
            }

            &:active,
            &:focus {
                animation-name: pulse;
                animation-duration: 300ms;
                animation-timing-function: ease-in-out;
            }
        }

        &__input {
            &--focus,
            &--focus:hover,
            &:hover,
            &:focus,
            &:active,
            &:active:hover {
                border: 1px solid $footer-color;
            }

            &--error,
            &--error:hover,
            &--invalid,
            &--invalid:hover {
                border-color: $error-color;
            }
        }

        &__error-text {
            color: $error-color;
        }
    }

    .payments-label {
        &--h1,
        &--h2,
        &--p-bold {
            font-family: $MaxPro-Bold;
        }
    }

    .payments-container {
        &__heading {
            background-color: $cart-background;
        }

        &__inner {
            background: $white;
            border: 2px solid $border-product;
            border-top: none;
        }
    }
}