.horselbutik-main {
    .checkout-success,
    .checkout-failed {
        text-align: center;
    }

    .order-confirmation-wrapper {
        margin-bottom: 0;

        a,
        span {
            color: $default-color;
        }
    }
}