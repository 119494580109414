.bernafon-main {
    .cms-side {
        li {
            &:not(:last-of-type) {
                margin-bottom: 12px;
            }
        }

        a {
            font-family: $Roboto-Medium;
            font-weight: normal;
            font-size: px-to-rem(20);
            color: $default-color;
            border-left: 2px solid transparent;
            @include default-transition(color);

            &.active {
                color: $default-activeColor;
                border-left: 2px solid $default-activeColor;

                &:hover {
                    color: $default-activeColor !important;
                }
            }

            &:hover {
                color: $default-activeColor !important;
            }
        }
    }

    .contact-form {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;

        .input-container {
            flex-basis: calc(50% - 40px);
            box-sizing: border-box;
            margin: 20px;

            &.email-container {
                height: 50px;
            }

            &:first-of-type,
            &:last-of-type {
                flex-basis: 100%;
            }

            input,
            textarea {
                width: 100%;
            }
        }

        textarea {
            border: none;
            border-top: 1px solid $default-color;
            border-bottom: 1px solid $default-color;

            &:focus,
            &:active {
                outline: none;
            }
        }
    }
}