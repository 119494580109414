.bernafon-main {
    .products-grid {
        &__item {
            background-color: $white;
            border: none;
            @include default-transition(box-shadow);
            box-shadow: 0px 2px 4px rgba($default-color, 0.3);
            border-radius: 3px;

            &:hover {
                box-shadow: 0px 2px 12px rgba($default-color, 0.3);
            }

            .product-detailing-top {
                height: 100%;
                z-index: -1;
                @include default-transition(all);

                &.is-active {
                    z-index: 1;
                    background: rgba($white, 0.95);
                }
            }

            .actions {
                height: auto;
                color: $default-color;

                .price-box {
                    margin-top: 15px;

                    .regular-price {
                        font-size: px-to-rem(20);
                        font-family: $Roboto-Medium;
                        font-weight: normal;
                    }
                }

                &__inner {
                    height: auto;
                }
            }

            .product-name {
                font-family: $Roboto-Medium;
                margin-bottom: 10px;
                font-size: px-to-rem(20);
                max-height: none;
                height: 62px;
                font-weight: normal;
                color: $default-color;
            }

            .short-description {
                font-size: px-to-rem(16);
                height: auto;
                min-height: 25px;

                &:first-of-type {
                    margin-bottom: 10px;
                    font-size: px-to-rem(18);
                }
            }

            .long-description {
                margin-top: 10px;
                font-size: px-to-rem(16);
            }
        }
    }

    .product-block {
        background-color: $white;

        &__shop {
            background-color: $white;

            .product-name {
                font-size: px-to-rem(32);
                margin-bottom: 30px;
                font-family: $Roboto-Regular;
                font-weight: normal;
                color: $default-color;
            }

            .price-box {
                .regular-price {
                    font-size: px-to-rem(32);
                    margin-bottom: 20px;
                    font-family: $Roboto-Regular;
                    font-weight: normal;
                }
            }

            .info-block {
                font-size: px-to-rem(20);
                margin-bottom: 40px;

                .inStock,
                .delivery-status {
                    padding-left: 40px;
                    position: relative;
                    font-family: $Roboto-Medium;
                    font-weight: normal;

                    &::before {
                        content: url('../../../images/bernafon-checkmark.svg');
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .inStock {
                    margin-right: 60px;

                    @media (max-width: 1023px) {
                        display: block;
                        margin-bottom: 20px;
                    }
                }
            }

            .short-description {
                margin-bottom: 30px;
                font-family: $Roboto-Regular;
            }

            .description {
                font-family: $Roboto-Regular;
            }
        }

        &__media {
            .product-image-container {
                max-width: 500px;
            }
        }
    }

    .add-to-cart {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        @media (max-width: 767px) {
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        &__wrapper {
            display: inline-block;
            margin-right: 60px;
            white-space: nowrap;

            @media (max-width: 767px) {
                margin-top: 10px;
            }
        }

        &__button {
            display: inline-block;
            background: none;
            border: none;
            outline: none;
            width: 20px;
            height: 20px;
            padding: 0;
            vertical-align: middle;
            background-position: center center;
            background-repeat: no-repeat;

            &--dec {
                background-image: url('../../../images/bernafon-minus.svg');
            }

            &--inc {
                background-image: url('../../../images/bernafon-plus.svg');
            }
        }

        .qty {
            margin-right: 0;
            float: none;
            min-width: 68px;
            height: 44px;
            margin: 0 10px;
            border: none;
            vertical-align: middle;
            font-family: $Roboto-Regular;
            font-size: px-to-rem(16);
            color: $default-color;
        }

        .button-addToCart {
            float: none;
            display: inline-block;

            @media (max-width: 767px) {
                line-height: 40px;
                max-width: 300px;
            }
        }
    }
}