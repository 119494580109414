.horselbutik-main {
    .payments-label {
        &--h1,
        &--h2,
        &--p-bold {
            font-family: $SourceSansPro-Bold;
        }
    }

    .adyen-checkout {
        &__payment-method {
            border: 1px solid #EAEDEF;
            border-radius: 4px;

            &--selected {
                border: 1px solid $default-color;
            }

            &__radio {
                border: 1px solid $checkbox-stroke;
                @include default-transition(all);

                &::after {
                    background-color: $default-color;
                    height: 13px;
                    width: 13px;
                }
            }

            &__header {
                &:hover {
                    .adyen-checkout__payment-method__radio {
                        background-color: $light-grey;
                    }
                }
            }
        }

        &__input {
            border: none;
            border-bottom: 1px solid $default-inputColor;
        }

        &__button {
            background: $default-buttonColor;
            border-radius: 27px;
            width: 100%;
            border: none;
            color: $white;
            transition: .2s ease color, .2s ease background-color;
            font-size: px-to-rem(16);
            z-index: 2;
            position: relative;
            box-sizing: border-box;
            font-family: $SourceSansPro-Bold;
            text-decoration: none;
            padding: 10px;
            height: auto;
    
            &:hover {
                background: $white;
                color: $default-buttonColor;
                box-shadow: 0px 0px 8px rgba(233, 85, 110, 0.3);
            }
    
            &:active,
            &:focus {
                animation-name: pulse;
                animation-duration: 300ms;
                animation-timing-function: ease-in-out;
                color: $default-buttonColor;
                background: rgba($default-buttonColor, .2);
            }
    
            .icon-add-to-cart {
                display: none;
            }
        }
    }

    .payments-container {
        &__heading {
            background-color: $default-backgroundColor;
        }

        &__inner {
            background-color: $white;
        }
    }

    .klarna-checkout {
        .payment-summary {
            flex-wrap: wrap;

            @media (min-width: $breakpoint-medium) {
                flex-wrap: nowrap;
            }

            .klarna-wrapper {
                width: 100%;

                @media (min-width: $breakpoint-medium) {
                    width: 63%;
                }
            }

            .order-summary {
                margin: 0 auto;
                width: calc(100% - 30px);

                @media (min-width: $breakpoint-medium) {
                    margin: 0;
                    width: auto;
                }

                .payment-product-list {
                    @media (min-width: $breakpoint-medium) {
                        padding-right: 15px;
                    }

                    .cart-table {
                        width: 100%;

                        @media (min-width: $breakpoint-medium) {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}