// .products-grid {
//     width: 1275px;

//     &__item {
//         float: left;
//         background-color: $color-grey;
//         margin: 1% 0 0 1%;
//         position: relative;
//         max-width: 300px;

//         .product-detailing-top {
//             position: absolute;
//             top: 0;
//             left: 0;
//             right: 0;
//        }

//         .text-badge {
//             color: $darkgrey;
//         }

//         a {
//             text-decoration: none;c

//             img {
//                 max-width: 100%;
//                 height: auto;
//                 vertical-align: middle;
//                 border: 0;
//             }
//         }

//         .product-image {
//             display: block;
//             text-align: center;
//         }
//     }
// }

// .pagers-block {
//     width: 100%;
//     min-height: 50px;
//     position: relative;

//     .category-name {
//         width: 100%;
//         font-size: 44px;
//         font-family: $SohoGothicPro-Bold,sans-serif;
//     }

//     .amount {
//         position: absolute;
//         top: 23px;
//         left: 0;
//         margin-right: 0;
//     }

//     .sort-by {
//         display: block;
//         width: 200px;
//         position: absolute;
//         top: 9px;
//         right: 0;
//     }
// }

.products-grid {
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    &__item {
        background-color: $color-grey;
        position: relative;

        /*float: left;
        margin: 3% 0 0 3%;*/

        flex: 0 0 100%;
        max-width: 100%;

        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        .actions {
            margin: 6%;
            position: relative;
            height: auto;
            text-align: left;

            &__inner {
                overflow: hidden;
                margin-bottom: 10px;
            }
        }

        .product-image {
            display: block;
            text-align: center;
            height: 100%;

            img {
                width: 100%;
                padding: 45px;
            }
        }

        .product-name {
            max-height: 59px;
            font-size: 14px;
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
            overflow: hidden;
            text-align: left;
            margin-bottom: 10px;
        }

        .attr-tax,
        .long-description,
        .short-description {
            display: block;
            font-size: 12px;
        }

        .attr-tax {
            font-size: 10px;
            height: 16px;
        }

        .short-description {
            height: 19px;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            overflow: hidden;
            margin-bottom: 5px;
        }

        .button {
            margin: 15px auto;
            max-width: calc(100% - 30px);
            margin-top: auto !important;
        }
    }
}
@media (min-width:575px) {
	.products-grid {
        margin: 0 -1%;
    }
}
@media (min-width:1245px) {
    .products-grid {
        margin: 0 -1%;
    }
}

@media (min-width:575px) {
    .products-grid__item {
        flex: 0 0 47.8%;
    	max-width: 47.8%;
    	margin: 0 1% 2%;
    }
}

@media (min-width:992px) {
    .products-grid__item {
        flex: 0 0 23%;
    	max-width: 23%;
    	margin: 0 1% 2%;
    }

}

@media (min-width:768px) {
    .products-grid__item .actions {
        margin: 15px;
    }

}

@media (min-width:768px) {
    .products-grid__item .product-name {
        font-size: 18px;
    }

}

@media (min-width:768px) {
    .products-grid__item .attr-tax {
        font-size: 11px;
    }

}

.favourites {
    .products-grid{
        @media (min-width:992px) {
            .products-grid__item {
                flex: 0 0 31%;
                max-width: 31%;
                margin: 0 1% 2%;
            }
        
        }
    }
}

.icon-add-to-cart {
    display: inline-block;
    position: relative;
    width: 26px;
    height: 24px;
    margin-right: 12px;

    &::before {
        content: '\e800';
        display: block;
        position: absolute;
        top: 9px;
        left: 0;
        font-size: 18px;
        font-family: top-basket;
        width: 26px;
        height: 24px;
    }
}

@media (min-width:768px) {
    .icon-add-to-cart:before {
        top: 7px;
        font-size: 24px;
        line-height: 24px;
    }

}

.price-box {
    &::before,
    &::after {
        content: "";
        display: table;
    }

    .regular-price {
        float: left;
        font-family: $SohoGothicPro-Bold;
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 3px;
    }
}

@media (min-width:768px) {
    .price-box .regular-price {
        font-size: 14px;
    }
}

.price-box-configurable,
.price-box {
    .disclaimer {
        float: left;
        clear: both;

        span {
            display: block;
            font-size: 12px;
        }
    }
}

.sort-by {
    display: none;
}

@media (min-width:768px) {
    .sort-by {
        display: block;
        position: absolute;
        right: 0;
    }

}

.product-block__media .price-box .regular-price, .product-block__media .product-name, .product-block__shop .info-block .inStock, .product-block__shop .product-name {
    font-family: $SohoGothicPro-Bold;
    font-weight:bold;
}

.add-to-box,
.add-to-cart {
    overflow: hidden;
}

.product-essential {
    padding-bottom: 2%;
}

.product-block {
    background: $color-grey;
    align-items: center;
    min-height: 500px;
    flex-direction: column;

    &__media {
        text-align: center;

        .product-image-container {
            max-width: 250px;
            margin: 0 auto;
        }

        img {
            padding: 0 20px 20px;
        }

        .product-name {
            font-size: 24px;
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
        }

        .info-top {
            text-align: left;
            padding: 20px;
        }

        .image-gallery-right-nav {
            right: -50%;
        }

        .image-gallery-left-nav {
            left: -50%;
        }
    }

    &__shop {
        padding: 20px;
        background-color: $color-grey;
        align-self: flex-start;
        white-space: pre-wrap;

        .product-name {
            font-size: 36px;
            line-height: 36px;
            margin-bottom: 1%;
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
        }

        .attr-tax {
            font-size: 12px;
        }

        .info-block {
            margin-bottom: 20px;

            .inStock {
                position: relative;
                padding-left: 18px;
                font-family: $SohoGothicPro-Bold;
                font-weight: bold;

                &::before {
                    content: '\e60b';
                    position: absolute;
                    color: #393;
                    font-family: oticon-icons;
                    top: 5px;
                    left: 0;
                }
            }
        }

        .delivery-status {
            font-family: $SohoGothicPro-Regular;
        }

        .description {
            margin-bottom: 30px;
            line-height: 22px;
        }

        .info-links {
            margin-bottom: 20px;

            a {
                display: block;
                color: $default-activeColor;
                font-family: $SohoGothicPro-Medium;
                font-weight:500;
            }
        }

        .short-description {
            position: relative;
            font-size: 16px;
            margin-bottom: 15px;
            font-family: $SohoGothicPro-Regular;
            font-weight: 400;
        }

        .price-box {
            .regular-price {
                font-size: 16px;
                font-family: $SohoGothicPro-Bold;
                font-weight:bold;
            }
        }
    }
}

@media (min-width:768px) {
    .product-block {
        display: flex;
        margin-bottom: 30px;
        flex-direction: row;
    }

}

@media (min-width:768px) {
    .product-block__media img {
        padding: 30px 0;
    }

    .product-block__shop .product-name {
        margin-bottom: 1%;
    }

}

@media (min-width:1245px) {
    .product-block__shop {
        padding: 20px 50px;
    }

    .product-block__shop .product-name {
        margin-bottom: 15px;
        font-family: $SohoGothicPro-Bold;
        font-weight: bold;
    }

}

@media (min-width:768px) {
    .product-block__media, .product-block__shop {
        width: 50%;
    }

}

.product-options-bottom {
    clear: both;
    padding-top: 2px;

    .required {
        display: none;
    }
}

.add-to-cart {
    margin: 10px 0 30px;

    &__button {
        display: none;
    }

    .qty {
        float: left;
        width: 20%;
        max-width: 60px;
        height: 40px;
        margin-right: 1%;
        text-align: center;
        font-family: $SohoGothicPro-Regular;
        margin-top: 10px;
    }

    .button-addToCart {
        float: left;
        width: 100%;
        margin-top: 10px;
    }
}

@media (min-width:1245px) {
    .add-to-cart .qty {
        margin-right: 15px;
    }

}

@media (min-width:768px) {
    .add-to-cart .qty {
        height: 50px;
    }

    .add-to-cart .button-addToCart {
        max-width: 340px;
    }

}

.product-options {
    &::after,
    &::before {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }
}

.product-price {
    vertical-align: bottom;
    text-align: right;
}

.select-block-wr {
    float: left;
    width: 200px;
    margin-top: 15px;
    margin-right: 5px;

    label {
        font-size: 12px;
        margin-bottom: 10px;
        font-family: $SohoGothicPro-Regular;
    }
}

@media (min-width:1245px) {
    .select-block-wr {
        margin-right: 15px;
    }

}

/* Class: std - styles for admin-controlled content ================================================================================ */
.std {
    a {
        text-decoration: underline;
        text-transform: none;

        &::hover {
            text-decoration: none;
            color: #333;
        }
    }

    ol {
        list-style: decimal outside;
        padding: 0 0 0 40px;

        &.ol {
            list-style: decimal outside;
            padding-left: 16px;
        }

        ol,
        ul {
            margin: .5em 0;
        }
    }

    dl  {
        dt {
            font-weight: bold;
        }

        dd {
            margin: 0 0 10px;
        }
    }

    ul,
    ol,
    dl,
    p,
    address {
        margin: 0 0 15px 0;
        padding: 0;
        font-family: $SohoGothicPro-Regular;
    }

    ul {
        list-style: disc outside;
        padding: 0 0 0 40px;

        ul {
            list-style-type: circle;
            margin: .5em 0;
        }

        ol {
            margin: .5em 0;
        }
    }

    dt {
        font-weight: bold;
    }

    dd {
        padding: 0 0 0 16px;
    }

    address {
        font-style: normal;
    }

    strong {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }

    /* Headings */

    h2 {
        margin-bottom: 10px;
    }

    h3 {
        margin-bottom: 8px;
    }

    h4 {
        margin-bottom: 6px;
    }

    h5 {
        margin-bottom: 4px;
    }

    h6 {
        margin-bottom: 2px;
    }

    /* Tables */

    table {
        border: 1px solid $color-dropdownHover;

        th,
        td {
            border: 1px solid $color-dropdownHover;
            padding: 5px;
        }
    }
}