.horselbutik-main {
    .category-top {
        background: $white;
    }

    .category-description {
        &__title {
            h2 {
                font-family: $SourceSansPro-Bold;
            }

            p {
                font-family: $SourceSansPro-Regular;
            }
        }
    }

    .category-bottom {
        padding: 0 15px 49px 15px;

        h2 {
            color: $default-color;
            font-family: $SourceSansPro-Regular;
            margin-top: 1.8rem;
            margin-bottom: 1.2rem;
        }

        p {
            line-height: 1.5rem;

            &:not(:last-of-type) {
                margin-bottom: .5rem;
            }
        }
    }
}