.horselbutik-main {
    .text-component {
        color: $black;
        background: $default-backgroundColor;

        &__heading {
            font-size: px-to-rem(36);
            font-family: $SourceSansPro-Bold;
        }

        &__text {
            font-size: px-to-rem(18);
            display: block;
            line-height: 30px;

            a {
                color: black;
                font-family: $SourceSansPro-Bold;

                &:hover {
                    color: $default-color;
                }
            }

            ul {
                max-width: 750px;
                margin: 0 auto;
                text-align: left;
                margin-top: 20px;
            }
        }

        .button {
            width: auto;
            margin: 0 auto;
            margin-top: 40px;
            padding: 10px 30px;
            line-height: normal;
            height: auto;
            // color: $white;

            &:focus,
            &:active {
                background: $input-background;
                color: $default-color;
                box-shadow: 0px 0px 8px rgba(96, 97, 165, 0.3);
            }
        }
    }
}