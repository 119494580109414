.category-menu {
    padding-top: 20px;

    @media (min-width: $breakpoint-small) {
        padding-top: 40px;
    }

    &__title {
        font-family: $SohoGothicPro-Bold;
        text-align: center;
        color: $color-menuTile;
        font-size: 21px;

        @media (min-width: $breakpoint-small) {
            font-size: 30px;
        }
    }

    &__list {
        margin-top: 30px;
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;

        @media (min-width: $breakpoint-small) {
            margin-top: 50px;
            margin-bottom: 40px;
        }

        @media (min-width: $breakpoint-medium) {
            margin-left: -13px;
            margin-right: -13px;
        }
    }
}

.category-menu-tile {
    max-width: 280px;
    padding: 20px;
    color: $color-menuTile;
    font-family: $SohoGothicPro-Medium;
    border: 1px solid $color-dropdown;
    background-color: $white;
    margin: 0 auto;
    margin-bottom: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    @media (min-width: $breakpoint-small) {
        max-width: 315px;
        width: calc(50% - 13px);
        margin-bottom: 26px;

        &:nth-child(2n + 1) {
            margin-right: 26px;
        }
    }

    @media (min-width: $breakpoint-medium) {
        width: calc(33.3% - 26px);
        margin-left: 13px;
        margin-right: 13px;
        margin-bottom: 26px;

        &:nth-child(2n + 1) {
            margin-right: 13px;
        }
    }

    @media (min-width: 992px) {
        width: calc(25% - 26px);
    }

    &__img {
        max-width: 60%;
        margin: 0 auto;
        margin-bottom: 20px;
        margin-top: 48px;
    }

    &__product-name {
        font-size: px-to-rem(18);
    }
}