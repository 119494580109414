.bernafon-main {
    .header {
        background: $white;
        border-bottom: 2px solid $border-product;

        @media (max-width: 767px) {
            background: $header-top;
            padding-top: 40px;
        }

        &__top {
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
        }

        &__menu {
            background-color: $white;

            @media (max-width: 767px) {
                padding: 2% 2%;

                li {
                    &.first {
                        a {
                            &.menu-link {
                                color: $default-color;
                                padding-bottom: 0;
                                padding-top: 0;
                            }
                        }
                    }
                }

                .menu-standard-navigation {
                    border-bottom: none;
                }
            }
        }

        .links {
            padding-top: 13px;

            @media (max-width: 767px) {
                margin-left: 0;
                padding-left: 13px;
                border-bottom: 1px solid $cart-background;
            }

            &.static-links {
                margin-top: 13px;

                & > li {
                    &:last-of-type {
                        @media (min-width: $breakpoint-medium) {
                            margin-left: 20px;
                        }

                        @media (min-width: $breakpoint-large) {
                            margin-left: 45px;
                        }
                    }
                }
            }

            & > li {
                &.customer-service {
                    a {
                        &::before {
                            content: '';
                            top: 0px;
                            width: 25px;
                            height: 25px;
                            position: relative;
                            display: block;
                            margin-bottom: 5px;
                            background: url('../../../images/customer-service-bernafon.svg');
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }

                @media (max-width: 767px) {
                    &.customer-service {
                        a {
                            margin-left: 0;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }

                a {
                    color: $default-activeColor;
                    font-size: px-to-rem(14);
                    font-family: $Roboto-Regular;
                    margin: 0;
                    padding: 0;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;

                    @media (max-width: 767px) {
                        flex-direction: row;
                        padding-bottom: 10px;
                        margin-left: 0;

                        &::before {
                            display: none;
                        }
                    }
                }

                &.deskstop-login {
                    a {
                        &::before {
                            content: url('../../../images/login-bernafon.svg');
                            top: 0px;
                            width: 23px;
                            position: relative;
                            display: block;
                            margin-bottom: 5px;
                        }

                        &.logged-in {
                                &::before {
                                    content: url('../../../images/login-bernafon-logged-in.svg');
                                }
                        }
                    }
                }
            }
        }

        .logo {
            width: 145px;
            margin-top: 23px;
        }
    }

    .headercart {
        margin-left: 30px;

        &__top {
            margin-top: 0;

            a {
                color: $default-color;
                font-family: $Roboto-Regular;
                font-size: px-to-rem(16);
                padding-bottom: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .icon-top-cart {
                    line-height: 1;
                    height: auto;
                    display: block;

                    &::before {
                        content: '\e906';
                        top: 0;
                        font-family: $BernafonIcons;
                        position: static;
                        font-size: 25px;
                        margin-bottom: 5px;
                    }
                }

                span {
                    font-size: px-to-rem(14);
                    font-family: $Roboto-Regular;
                    line-height: normal;
                    color: $default-activeColor;

                    &.headercart-items {
                        margin-left: 10px;
                        font-family: $Roboto-Regular;
                        color: $default-activeColor;
                    }
                }
            }
        }
    }

    .headercart-inner {
        margin-top: 10px;

        &::before,
        &::after {
            border-bottom-color: $header-background;
        }

        .product-name {
            margin-bottom: 0;
            min-height: 75px;

            span {
                font-family: $Roboto-Regular;
                font-size: px-to-rem(16);
            }
        }

        .inner-options {
            font-family: $Roboto-Regular;
        }

        .mini-products-list {
            .item {
                position: relative;
                border-top: 2px solid $border-product;
                padding-top: 20px;
                padding-left: 10px;
                padding-right: 10px;

                &:first-of-type {
                    border-top: none;
                }
            }

            li {
                &.item {
                    &--shipping-price {
                        font-size: px-to-rem(16);
                    }
                }
            }
        }

        .product-details,
        .product-image {
            float: none;
            display: inline-block;
            font-size: px-to-rem(16);
            font-family: $OpenSans-Bold;
        }

        .product-details {
            width: 170px;
        }

        .product-details-lower {
            display: flex;
            flex-direction: column;
            margin-top: 8px;
            margin-bottom: 20px;
        }

        .item-options {
            margin: 0;
        }
    }

    .headercart-button {
        .button {
            max-width: none;
            width: calc(100% - 30px);
        }
    }

    .deskstop-login {
        margin-top: 13px;
    }

    .mobile-header {
        background-color: $white;

        .headercart {
            background-color: $white;
        }

        .hamburger {
            .bread {
                background-color: $default-color;
            }
        }

        .phone-menu {
            a {
                color: $default-color;

                &.active {
                    background: url('../../../images/close-icon-phonicear.svg') 50% no-repeat;
                }
            }
        }

        .logo-min {
            @media (max-width: 767px) {
                margin: 0;
                transform: translate(-50%, -50%);
                width: 114px;
                top: 28px;
            }
        }
    }
}