.partners-spot {
    color: $black;;
    text-align: center;
    background: $white;
    padding: 60px 30px;

    &__heading {
        font-size: px-to-rem(30);
        font-family: $SohoGothicPro-Bold;
    }

    img {
        max-height: 60px;
        margin: 30px 30px 0 30px;
    }
}