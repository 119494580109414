.horselbutik-main {
    .payment-methods {
        box-shadow: none !important;

        &__label {
            &--bold {
                font-family: $SourceSansPro-Bold;
                font-size: px-to-rem(18);
            }
        }
    }
}