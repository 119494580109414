.horselbutik-main {
    .footer-block {
        height: auto;

        &__top,
        &__bottom {
            color: $white;
            background: $default-activeColor;
        }

        &__top {
            h4 {
                font-family: $SourceSansPro-Bold;
                margin-bottom: 24px;
            }

            a,
            p {
                color: $white;
                font-family: $SourceSansPro-Regular;
                margin-bottom: 16px;
                line-height: normal;
            }

            .input-container {
                &:last-of-type {
                    margin-top: 4px;
                }

                input {
                    &[type=email] {
                        width: 100%;
                        max-width: 280px;
                        background-color: transparent;
                        border-bottom: 2px solid $white;
                        color: $white;

                        &:focus,
                        &:active {
                            color: $white;
                            border-bottom: 2px solid $white;
                        }

                        + label {
                            opacity: 1;
                        }
                    }
                }
            }

            .button {
                line-height: normal;
                margin-left: 0;
                background: $white;
                color: $default-color;
                box-shadow: 0px 0px 8px rgba(96, 97, 165, 0.3);
                display: inline-block;
                width: auto;
                padding: 10px 30px;

                &:hover {
                    background: $default-color;
                    color: $white;
                    box-shadow: none;
                }

                &:focus,
                &:active {
                    background: $input-background;
                    color: $default-color;
                    box-shadow: 0px 0px 8px rgba(96, 97, 165, 0.3);
                }
            }
        }

        &__bottom {
            display: none;
        }

        .bottomlinks,
        .copyright {
            font-family: $SourceSansPro-Regular;
        }
    }
}