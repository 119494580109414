footer {
    display: none;
    
    .oticon-main &,
    .phonicear-main &,
    .horselbutik-main &,
    .bernafon-main & {
        display: block;
    }
}

.footer-block {
    overflow: hidden;

    .logo {
        border-bottom: 1px solid $border;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    &__bottom {
        background: #2d2d2d;
        color: $color-footer;
        padding: 15px;
    }

    &__top {
        background: $default-color;
        color: #aaa;
        padding: 40px 15px 0;

        h4,
        p {
            font-family: $SohoGothicPro-Light;
            font-weight: 300;
        }

        h4 {
            font-size: 24px;
            margin-bottom: 20px;
            font-family: $SohoGothicPro-Regular;
        }

        p {
            font-size: 16px;
            font-family: $SohoGothicPro-Light;
            font-weight: 300;
        }

        .button {
            margin-top: 15px;
            line-height: 35px;

            &:focus,
            &:hover {
                color: $color-footer;
                border-color: $color-footer;
            }
        }

        a {
            font-family: $SohoGothicPro-Medium;
            font-weight: 500;
            font-size: 16px;
            display: inline-block;
            color: #aaa;
            line-height: 36px;
        }


        .wrap_col-4 {
            &:after,
            &:before {
                content: "";
                display: table;
            }
        }

        .wrap_col-4:after {
            clear: both;
        }

        .col-4 {
            float: left;
            width: 100%;
            margin-bottom: 50px;

            &:first-child {
                padding-left: 0;
            }
        }
    }
}

@media (min-width:768px) {
    .footer-block {
        &__bottom {
            padding: 30px 15px;
        }

        &__top {
            padding: 65px 15px 0;
            min-height: 396px;

            h4 {
                margin-bottom: 30px;
            }

            .button {
                line-height: 45px;
            }

            .col-4 {
                width: 100%;
                margin-bottom: 0;
                padding-left: 2%;
            }

            .wrap_col-4 {
                display: flex;
                justify-content: center;
            }
        }
    }

    .copyright {
        float: left;
    }
}


@media (min-width:1245px) {
    .footer-block {
        height: 470px;

        &__top {
            .wrap_col-4 {
                margin: 0 -15px;
            }

            .col-4 {
                margin: 0 0 0 15px;
            }
        }
    }
}