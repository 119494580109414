.spinner,
.spinner-default {
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &__element {
        position: absolute;
        background-color: $default-activeColor;
        width: 7px;
        height: 7px;
        border-radius: 5px;
        animation-name: bounce_loader;
        animation-duration: 1.04s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        opacity: 0;

        &--1 {
            left: 0;
            top: 13px;
            animation-delay: .39s;
        }

        &--2 {
            left: 3px;
            top: 3px;
            animation-delay: .52s;
        }

        &--3 {
            top: 0;
            left: 13px;
            animation-delay: .65s;
        }

        &--4 {
            right: 3px;
            top: 3px;
            animation-delay: .78s;
        }

        &--5 {
            right: 0;
            top: 13px;
            animation-delay: .91s;
        }

        &--6 {
            right: 3px;
            bottom: 3px;
            animation-delay: 1.04s;
        }

        &--7 {
            left: 13px;
            bottom: 0;
            animation-delay: 1.17s;
        }

        &--8 {
            left: 3px;
            bottom: 3px;
            animation-delay: 1.3s;
        }
    }
}

.spinner-default {
    &__element {
        background-color: $black;
    }
}