.horselbutik-main {
    .authentication-wrapper {
        margin-right: 0;
        margin-left: auto;

        @media (min-width: $breakpoint-medium) {
            margin-right: 15px;
            margin-left: 15px;
            max-width: 420px;
        }

        > a#click-here-to-show-login {
            position: relative;
            padding-right: 25px;
            margin-bottom: 20px;
            display: inline-block;

            @media (min-width: $breakpoint-medium) {
                margin-right: 15px;
            }

            > span {
                font-family: $SourceSansPro-SemiBold;
                color: $default-color;
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 14px;
                height: 2px;
                background: $black;
                top: 10px;
                right: 0;
                transform: rotate(0);
                transition: all .2s ease-in-out;
            }

            &::after {
                transform: rotate(90deg);
            }

            &.active {
                &::before {
                    transform: rotate(180deg);
                }

                &::after {
                    transform: rotate(180deg);
                }
            }
        }

        .login-box {
            animation: fade-in .3s ease forwards;
            border: none;
            box-shadow: 0px 0px 8px $light-grey;

            .button {
                margin-left: auto;
                margin-right: 0;
                margin-top: 30px !important;
                width: auto !important;
                font-size: 14px !important;
                padding: 10px 32px;
            }

            a {
                font-family: $SourceSansPro-SemiBold;
                margin-top: 0;
            }
        }

        .account-create {
            width: 100%;
            padding: 0;

            & > .wr-block {
                padding: 25px;
                margin-top: 0 !important;
            }

            .col-2 {
                width: 100%;

                h2.legend,
                .form-list .control label {
                    text-align: left;
                }

                input[type=checkbox].css-checkbox + label.css-label:before {
                    top: 12px;
                }

                .button {
                    margin-top: 25px;
                    margin-left: 0;
                    float: none;
                }
            }
        }
    }
}