.horselbutik-main {
    .product-info-block {
        .product-block {
            &__shop {
                .product-name {
                    font-family: $SourceSansPro-Bold;
                    font-weight: normal;
                    font-size: px-to-rem(36);
                }
            }

            &__media {
                position: relative;
            }

            .product-detailing-top {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                @include default-transition(all);
                opacity: 0;
                z-index: -1;

                &.is-active {
                    background: rgba($white, 0.95);
                    opacity: 1;
                    z-index: 1;
                }
            }
        }
    }
}