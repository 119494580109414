.phonicear-main {
    .pager {
        .amount {
            strong {
                font-family: $OpenSans-Bold;
            }
        }

        .limiter {
            select {
                font-family: $OpenSans-Light;
                font-size: px-to-rem(14);
                padding-top: 4px;
            }
        }
    }

    .my-order-block {
        &__table {
            font-family: $OpenSans-Light;

            a {
                color: $error-color;
                font-family: $MaxPro-Bold;
                @include default-transition(color);

                &:hover {
                    color: $black;
                }
            }

            tbody {
                tr.border {
                    td {
                        font-family: $OpenSans-Light;
                    }
                }
            }

            tfoot {
                td {
                    .price {
                        font-family: $OpenSans-Light;
                    }
                }

                .grand_total {
                    strong,
                    .price {
                        font-weight: normal;
                        font-family: $MaxPro-Bold;
                    }

                    .price {
                        font-family: $OpenSans-Bold;
                    }
                }
            }
        }
    }

    .address-book-table {
        .buttons-set {
            .button {
                margin-left: 0;
            }
        }

        table {
            td {
                p {
                    font-family: $OpenSans-Light;
                }
            }
        }
    }

    .welcome-msg {
        margin-left: 20px;
        margin-right: 20px;
        width: calc(100% - 40px);

        @media (min-width: $breakpoint-medium) {
            width: 100%;
        }

        @media (min-width: $breakpoint-large) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}