.horselbutik-main {
    .product-list-slider {
        &__header {
            font-family: $SourceSansPro-Bold;
        }

        &__item-wrapper {
            background-color: $white;
            box-shadow: $product-boxshadow;
            @include default-transition(box-shadow);

            &:hover {
                box-shadow: $product-boxshadow-hover;
            }
        }

        &__link {
            font-size: px-to-rem(18);
            color: $default-color;
        }

        &__item {
            color: $black;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        &__price {
            font-family: $SourceSansPro-Bold;
        }

        .slider-price {
            font-size: 1rem;
            .strikethrough {
                font-size: 0.7rem;
            }
        }

        .button {
            margin-top: 0;
            max-width: none;
            height: auto;
        }
    }
}