.bernafon-main {
    color: $default-color;
    font-family: $Roboto-Regular;
    font-size: px-to-rem(16);

    h1, h2, h3, h4, h5, h6 {
        font-family: $Roboto-Regular;
        font-weight: normal;
    }

    h1 {
        @media (min-width: $breakpoint-large) {
            font-size: px-to-rem(50);
        }
    }

    button,
    input,
    select {
        font-size: px-to-rem(16);
    }

    select {
        padding-left: 5px;
        padding-bottom: 5px;
        font-size: px-to-rem(16);
        cursor: pointer;
    }

    input {
        font-family: $Roboto-Regular;

        &[type=text],
        &[type=email],
        &[type=password] {
            border: none;
            border-bottom: 1px solid $default-color;

            ::-webkit-input-placeholder {
                display: none;
            }

            :-ms-input-placeholder {
                display: none;
            }

            ::placeholder {
                display: none;
            }

            &:focus,
            &.has-value {
                & + label {
                    left: .54688rem;
                    top: -25px;
                    font-size: .75rem;
                }
            }

            & + label {
                position: absolute;
                pointer-events: none;
                cursor: text;
                opacity: .6;
                transition: all .3s ease;
                left: .4375rem;
                top: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: calc(100% - 14px);
                overflow: hidden;
            }
        }
    }

    a {
        color: $default-activeColor;
    }

    .input-container,
    .select-container {
        position: relative;
        margin-top: 45px;
    }

    .select-container {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    input[type=radio] {
        + label {
            border: none !important;
            font-size: px-to-rem(16);
            font-weight: normal !important;

            span {
                border: 1px solid $default-color;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;

                &::before {
                    font-family: "bernafon-iconfont";
                    width: 100%;
                    height: 100%;
                    border: none;
                    font-size: 20px;
                    line-height: 1;
                    background-color: $white;
                    top: 50% !important;
                    transform: translateY(-50%);
                    content: "";
                    color: $default-activeColor;
                    border-radius: 50%;
                    left: 0;
                    opacity: 0;
                }
            }
        }

        &:checked,
        &:hover {
            + label {
                span {
                    border: none;

                    &::before {
                        opacity: 1;
                    }
                }
            }
        }

        &:hover {
            + label {
                span {
                    &::before {
                        color: $default-color;
                    }
                }
            }
        }
    }

    #search_mini_form,
    #search_mini_form-1 {
        max-width: none;
        margin-top: 17px;

        @media (max-width: 767px) {
            border-bottom: none;
        }

        .input-wrapper {
            &::before {
                left: auto;
                right: 20px;
                color: $black;
            }
        }

        input {
            background: $white;
            border-bottom: 1px solid $border-input;
            box-sizing: border-box;
            border-radius: 0;
            width: 200px;
            height: 43px;
            padding: 0 50px 0 20px;
            font-family: $Roboto-Regular;
            font-size: px-to-rem(16);
            padding-bottom: 3px;

            @media (min-width: $breakpoint-large) {
                width: 300px;
            }

            @media (min-width: $breakpoint-xlarge) {
                width: 424px;
            }

            @media (max-width: 767px) {
                margin-left: 0;
                width: 100%;
                padding-bottom: 5px;
            }

            &::-webkit-input-placeholder {
                color: $border-input;
            }

            &:-ms-input-placeholder {
                color: $border-input;
            }

            &::placeholder {
                color: $border-input;
            }
        }
    }

    .button,
    .button-addToCart {
        border-radius: 40px;
        border: 1px solid $default-activeColor;
        color: $default-activeColor;
        background-color: $white;
        transition: .2s ease color, .2s ease background-color;
        font-family: $Roboto-Regular;
        font-size: px-to-rem(20);
        padding-bottom: 0;
        background-color: $default-activeColor;
        color: $white;
        line-height: 45px;
        font-weight: 500;
        padding: 0px 10px 4px;
        text-decoration: none;
        height: 49px;
        width: 100%;

        &:hover {
            color: $default-activeColor;
            background-color: $white;
        }

        .icon-add-to-cart {
            display: none;
        }
    }

    .breadcrumbs {
        &__list {
            border-bottom: none;
            margin-bottom: 10px;

            li {
                font-family: $Roboto-Regular;
                font-weight: normal;
                font-size: px-to-rem(16);
                margin-left: 0;
                color: $default-activeColor;

                a,
                span {
                    font-family: $Roboto-Regular;
                    font-size: px-to-rem(16);
                }

                span {
                    top: 2px;
                    color: $default-color;
                }
            }
        }
    }

    #recently-added-container {
        top: 50px;
        width: 380px;
        right: -20px;

        .viewing {
            background: $header-background;
            color: $cart-color;
            font-size: px-to-rem(20);
            padding: 14px 15px;
            width: 100%;

            p {
                font-family: $Roboto-Regular;

                span {
                    font-size: px-to-rem(20);
                    color: $default-color;

                    &.headercart-info-items {
                        vertical-align: middle;
                        padding-bottom: 2px;
                    }
                }
            }

            .sum {
                font-size: px-to-rem(20);
                vertical-align: baseline;
                font-family: $Roboto-Medium;
                font-weight: normal;
                color: $default-color;
            }
        }
    }

    .showcart {
        font-family: $Roboto-Medium;
        font-weight: normal;

        span {
            &.price {
                font-family: $Roboto-Medium;
                font-weight: normal;
                font-size: px-to-rem(16);
            }

            &.price-amount {
                position: absolute;
                right: 10px;
                bottom: 20px;
                font-family: $Roboto-Medium;
                font-weight: normal;
            }
        }

        .mini-products-list {
            .product-image {
                display: inline-block !important;
                vertical-align: top;
                width: 85px !important; 
                
                img {
                    width: 100%;
                }
            }

            .btn-remove {
                position: absolute;
                top: 14px;
                right: 10px;
                width: auto;
                background-image: url('../images/minicart-cross.svg');
                text-indent: -9999px;
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                background-position: center center;
            }

            .product-details {
                .product-name {
                    a {
                        margin-bottom: -6px;
                        align-items: flex-start;
                    }
                }
            }
        }

        .item-options {
            li {
                font-size: px-to-rem(16);
                font-weight: normal;
                font-family: $Roboto-Regular;

                span {
                    font-family: $Roboto-Medium;
                    font-weight: normal;
                }
            }
        }
    }

    .cart-collateral {
        > div {
            &:first-of-type {
                box-shadow: 0px 2px 4px rgba($default-color, 0.3);
            }
        }

        .title {
            background: $header-background;
            font-family: $Roboto-Medium;
            font-weight: normal;
            font-size: px-to-rem(16);
        }

        .cart-info {
            background: $white;

            .button-addToCart {
                width: 100%;
                max-width: none;
            }
        }

        .continue-link {
            padding: 0;
            margin-top: 0;
            font-family: $Roboto-Medium;
            font-weight: normal;
            font-size: px-to-rem(20);

            &::before {
                display: none;
            }
        }

        .link-wrapper {
            margin-top: 15px;
        }
    }

    .cart-stickybanner {
        &__wrapper {
            font-size: px-to-rem(18);
        }

        .button-addToCart {
            line-height: 43px;
            height: 43px;
        }
    }

    .cart-block {
        .product-name {
            font-family: $Roboto-Medium;
            font-weight: normal;
            margin-bottom: 10px;
            font-size: px-to-rem(18);

            > a {
                display: inline-block;
                margin-bottom: 10px;
                color: $default-color;
            }
        }

        .increment-input {
            width: px-to-rem(14);
            height: px-to-rem(14);
            margin: 0 8px !important;

            &__minus {
                background: url('../images/bernafon-minus.svg');
                background-position: center center;
                background-repeat: no-repeat;
            }

            &__plus {
                background: url('../images/bernafon-plus.svg');
                background-position: center center;
                background-repeat: no-repeat;
            }

            &__qty {
                font-family: $Roboto-Medium;
                font-weight: normal;
                font-size: px-to-rem(16);
            }
        }

        .btn-remove {
            background: url('../images/bernafon-cross.svg');
            background-position: center center;
            background-repeat: no-repeat;
            text-indent: -9999px;
        }

        .item-options {
            font-family: $Roboto-Regular;
            font-size: px-to-rem(16);

            .price-option {
                display: none;
            }

            strong {
                font-family: $Roboto-Medium;
                font-weight: normal;
            }
        }

        .short-description {
            font-family: $Roboto-Regular;
            font-size: px-to-rem(16);
        }

        .product-detailing {
            .cart-price {
                font-family: $Roboto-Medium;
                font-weight: normal;
                font-size: px-to-rem(16);
            }
        }

        .cell-hide {
            text-align: center;
        }

        table {
            font-family: $Roboto-Regular;
        }
    }

    .product-incart {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: transparent;

        .icon {
            background: url('../images/bernafon-checkmark.svg');
            width: 60px;
            height: 60px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            margin: 0;
            border: none;

            &::before {
                display: none;
            }
        }

        .text {
            color: $black;
            font-size: px-to-rem(20);
            line-height: 31px;
        }
    }

    .validation-advice {
        color: $error-color;
        font-family: $Roboto-Medium;
        font-weight: normal;
        order: 1;
    }

    .new-users,
    .registered-users {
        background: $white;
        width: 100%;
        max-width: 100%;
        margin: 0;
        margin-bottom: 30px;

        @media (min-width: $breakpoint-medium) {
            max-width: 50%;
            margin-bottom: 120px;
        }
    }

    .new-users {
        @media (min-width: $breakpoint-medium) {
            padding-left: 130px;
            padding-right: 60px;
        }

        .buttons-set {
            .button {
                float: left;
                max-width: 220px;
            }
        }
    }

    .registered-users {
        @media (min-width: $breakpoint-medium) {
            border-right: 2px solid $border-product;
            padding-right: 130px;
            padding-left: 60px;
        }

        .login-block {
            form {
                > div {
                    > a {
                        color: $error-color;
                        font-family: $Roboto-Medium;
                        font-weight: normal;
                    }

                    &:last-of-type {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

                .button {
                    margin-top: 25px;
                    max-width: 170px;
                    float: none;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    .account-login {
        font-size: px-to-rem(16);

        h2 {
            font-size: px-to-rem(20);
        }

        p {
            margin: 25px 0;
            font-family: $Roboto-Regular;
        }
    }

    .form-list {
        label {
            font-size: px-to-rem(16);
            font-family: $Roboto-Regular;
        }
    }

    .wr-block {
        background: $white;
    }

    .account-create {
        .wr-block {
            .form-validate {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .col-2 {
                    width: 50%;
                    margin: 0;

                    h2 {
                        font-size: px-to-rem(20);
                        margin-bottom: 20px;
                    }

                    .form-list {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .form-list {
            input {
                margin-bottom: 10px;
            }
        }

        .button {
            margin-top: 50px;
            float: none;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .table-condensed {
        tbody {
            td {
                padding-bottom: 10px;
                font-family: $Roboto-Regular;
                font-size: px-to-rem(16);

                .price {
                    font-family: $Roboto-Medium;
                    font-weight: normal;
                }
            }

            tr.last {
                td {
                    border-top: 2px solid $border-product;
                    padding-top: 10px;
                    font-size: px-to-rem(20);

                    &.a-right {
                        strong {
                            font-family: $Roboto-Medium;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }

    .checkout-step {
        box-shadow: 0px 2px 4px rgba($default-color, 0.3);

        &__title {
            background: $header-background;
            padding: 13px 15px;

            span {
                &.text {
                    font-family: $Roboto-Medium;
                    font-weight: normal;
                    font-size: px-to-rem(20);
                }
            }
        }

        li {
            position: relative;
            flex-direction: column !important;
        }

        label {
            font-family: $Roboto-Regular;
            padding-top: 11px;
            padding-bottom: 9px;
            font-size: px-to-rem(16);
            order: -1;

            &.css-label {
                font-weight: normal;
            }
        }

        input {
            height: 40px;
            width: 100%;
        }
    }

    .content-container {
        background: $white;
        min-height: 0px;

        .checkout-final {
            .button-addToCart {
                max-width: none;
                width: 100%;
            }
        }
    }

    #click-here-to-show-login {
        font-family: $Roboto-Medium;
        font-weight: normal;

        span {
            font-family: $Roboto-Regular;
        }
    }

    #click-here-to-show-login,
    .back-link {
        color: $error-color;
        font-family: $Roboto-Medium;
        font-weight: normal;

        &:hover {
            color: $default-color;
        }
    }

    .back-link {
        color: $default-color;
        font-size: px-to-rem(20);

        &::before {
            content: '\e923';
            font-family: $BernafonIcons;
            font-size: px-to-rem(16);
            margin-right: 8px;
        }
    }

    #shopping-cart-totals-table {
        tr {
            td {
                padding-bottom: 10px;

                .price {
                    font-family: $Roboto-Medium;
                    font-weight: normal;
                }
            }
        }

        tfoot {
            td {
                padding-top: 10px;
                border-top: 2px solid $border-product;
                font-family: $Roboto-Medium;

                strong {
                    font-weight: normal;
                }
            }
        }
    }

    .std {
        ul,
        ol,
        dl,
        p,
        address {
            font-family: $Roboto-Regular;
        }
    }

    #login-form {
        button {
            margin-top: 30px;
            width: 100%;
            font-size: 20px;
        }
    }

    .forgotpassword-page {
        .button, p {
            margin-top: 2%;
            font-family: $Roboto-Regular;
        }

        .button {
            margin-top: 30px;
        }
    }

    .catalog-category-searchresult {
        .message-block {
            text-align: center;
            font-size: px-to-rem(50);
            font-family: $Roboto-Regular;
            font-weight: normal;
            border: none;
            margin-top: 28px;

            .note-msg {
                margin-top: 50px;
                font-size: px-to-rem(20);
                font-family: $Roboto-Regular;
            }
        }
    }

    .oticon-address {
        .block-account {
            &__content {
                li {
                    border-left: 2px solid transparent;

                    &:not(:last-of-type) {
                        margin-bottom: 12px;
                    }

                    &.current {
                        border-left: 2px solid $default-activeColor;
                    }
                }

                a {
                    font-family: $Roboto-Medium;
                    font-weight: normal;
                    font-size: px-to-rem(20);
                    color: $default-color;
                    @include default-transition(color);

                    &:hover {
                        color: $default-activeColor;
                    }

                    strong {
                        color: $default-activeColor;
                    }
                }
            }
        }

        .form-list {
            .custom-select {
                &__label {
                    margin-left: 9px;
                }
            }
        }
    }

    .box-account {
        &__box {
            .box-title {
                h3 {
                    font-size: px-to-rem(20);
                    padding-bottom: 5px;
                    font-family: $Roboto-Medium;
                }
            }

            .box-top {
                h3 {
                    font-size: px-to-rem(18);
                    font-family: $Roboto-Medium;
                }
            }

            .box-content {
                .button {
                    margin-left: 0;
                }

                p {
                    font-family: $Roboto-Regular;
                }
            }
        }
    }

    .order-details {
        .table-caption {
            font-size: px-to-rem(20);
            font-family: $Roboto-Medium;
        }
    }

    .order-hash {
        visibility: hidden;
        padding: 0;
        width: 8px;
        display: inline-block;
    }

    .cart-empty-block {
        color: $default-color;
        
        .wrapper {
            h1 {
                font-size: px-to-rem(60);
                font-weight: normal;
            }

            p {
                font-family: $Roboto-Regular;
            }

            a {
                font-family: $Roboto-Medium;
                font-weight: normal;
                color: $default-activeColor;
                text-decoration: none;

                &:hover {
                    color: $default-color;
                }
            }
        }
    }

    .page-not-found {
        h1 {
            font-size: px-to-rem(60);
            font-weight: normal;
        }
    }

    .cms-content {
        &::before {
            width: 2px;
            background: $border-background;
        }

        .buttons-set {
            margin-top: 20px;
        }
    }

    .cart-table {
        thead {
            th {
                font-family: $Roboto-Regular;
                font-size: px-to-rem(16);

                &.ph-center {
                    text-align: center;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 2px solid $border-background;

                &:first-child {
                    border-top: 2px solid $border-background;
                }
            }

            td {
                padding: 15px 0;
                padding-top: 15px;
            }
        }
    }

    .error-msg {
        color: $default-activeColor;
    }
}