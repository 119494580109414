.bernafon-main {
    .spinner {
        width: 80px;
        text-align: center;
        height: auto;

        &__element {
            width: 1rem;
            height: 1rem;
            background-color: $default-color;
            margin: 0 .3rem;
            border-radius: 100%;
            display: inline-block;
            animation: sk-bouncedelay 1.4s ease-in-out infinite both;
            opacity: 1;
            position: static;

            &--1 {
                -webkit-animation-delay: -.32s;
                animation-delay: -.32s;
            }

            &--2 {
                -webkit-animation-delay: -.16s;
                animation-delay: -.16s;
            }

            &--4,
            &--5,
            &--6,
            &--7,
            &--8 {
                display: none;
            }
        }
    }
}