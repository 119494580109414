.product-list-slider {
    padding: 65px 0 70px;

    &__wrapper {
        position: relative;
        margin-left: 20px;
        width: calc(100% - 20px);

        @media (min-width: $breakpoint-small) {
            margin-left: auto;
            width: calc(100% - 40px);
        }

        @media (min-width: $breakpoint-medium) {
            width: calc(100% - 80px);
        }
    }

    &__slider-container {
        padding: 40px 11px !important;
        margin-left: -11px !important;
        margin-right: -11px !important;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $SohoGothicPro-Bold;
    }

    &__heading-title {
        font-weight: normal;
        font-size: px-to-rem(22);
    }
    
    &__link {
        color: $default-activeColor;
        @include default-transition(color);

        &:hover {
            color: $color-weblinkMagenta;
        }
    }

    &__item-wrapper {
        background-color: $color-grey;
        display: flex;
        flex-direction: column;
    }

    &__item {
        padding: 26px;
        font-size: px-to-rem(16);
        display: block;
    }

    &__img {
        max-width: 256px;
        margin: 0 auto;
        margin-bottom: 35px;
    }

    &__product-heading {
        font-size: px-to-rem(18);
        margin-bottom: 4px;
    }

    &__price {
        font-family: $SohoGothicPro-Medium;
        font-weight: normal;
    }

    &__arrow {
        position: absolute;
        top: 50%;
        right: -36px;
        transform: translateY(-50%);
        width: 13px;
        height: 31px;
        background-image: url('../../../images/product-slider-arrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent;
        padding: 0;
        border: none;
        outline: none;
        z-index: 2;
        @include default-transition(opacity);
        display: none;

        @media (min-width: $breakpoint-medium) {
            display: inline-block;
            right: -28px;
        }

        @media (min-width: $breakpoint-xlarge) {
            right: -36px;
        }

        &--prev {
            transform: translateY(-50%) rotate(180deg);
        }

        &--disabled {
            opacity: 0;
            cursor: default;
            z-index: 1;
        }
    }

    .button {
        margin: 0 auto;
        margin-bottom: 26px;
        max-width: none;
        width: calc(100% - 52px);
    }
}