@font-face {
  font-family: 'SohoGothicPro-Light';
  src: url('../fonts/SohoGothicPro-Light.woff2') format('woff2'), url('../fonts/SohoGothicPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SohoGothicPro-Regular';
  src: url('../fonts/SohoGothicPro-Regular.woff2') format('woff2'), url('../fonts/SohoGothicPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SohoGothicPro-Medium';
  src: url('../fonts/SohoGothicPro-Medium.woff2') format('woff2'), url('../fonts/SohoGothicPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SohoGothicPro-BoldItalic';
  src: url('../fonts/SohoGothicPro-BoldItalic.woff2') format('woff2'), url('../fonts/SohoGothicPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SohoGothicPro-Bold';
  src: url('../fonts/SohoGothicPro-Bold.woff2') format('woff2'), url('../fonts/SohoGothicPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'oticon-icons';
  src: url('../fonts/oticon-icons.woff2') format('woff2'), url('../fonts/oticon-icons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.woff2') format('woff2'), url('../fonts/fontello.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/FontAwesome.woff2') format('woff2'), url('../fonts/FontAwesome.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SohoPro';
  src: url('../fonts/SohoPro-Regular.woff2') format('woff2'), url('../fonts/SohoPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Soho Pro';
  src: url('../fonts/SohoPro-Medium.woff2') format('woff2'), url('../fonts/SohoPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'iconfont';
  src: url('../fonts/iconfont.woff2') format('woff2'), url('../fonts/iconfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'top-basket';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/top-basket.eot);
  src: url(../fonts/top-basket.eot?) format("eot"), url(../fonts/top-basket.woff) format("woff"), url(../fonts/top-basket.ttf) format("truetype");
}