.phonicear-main {
    $color-border: #00579D !global;
    $default-color: #002D52 !global;
    $border-product: #F1F1F1 !global;
    $border-input: #d9d5d5 !global;
    $cart-background: #E2DFDB !global;
    $cart-color: #515151 !global;
    $error-color: #B61339 !global;
    $header-background: #E2DFDB !global;
    $footer-color: #3B3C3D !global;
    $button-blue: #04487E !global;
    $header-top: #404040 !global;
    $page-background: #e3dfdb !global;

    //fonts
    $MaxPro-Light: 'MaxPro-Light', 'Arial', sans-serif !global;
    $MaxPro-Bold: 'MaxPro-Bold', 'Arial', sans-serif !global;
    $OpenSans-Light: "OpenSans-Light", "Arial", sans-serif !global;
    $OpenSans-Bold: "OpenSans-Bold", "Arial", sans-serif !global;
}