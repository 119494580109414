.horselbutik-main {
    .bottom-info-block {
        margin: 0 auto;
        width: 100%;

        @media (min-width: $breakpoint-medium) {
            margin-left: -20px;
            margin-right: -20px;
            margin-top: 30px;
            margin-bottom: 27px;
            width: auto;
        }

        h6 {
            font-family: $SourceSansPro-Bold;
            font-size: px-to-rem(20);
        }

        p {
            font-family: $SourceSansPro-Regular;
        }
    }
}