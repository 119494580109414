
//colors
$default-color: #404040;
$default-activeColor: #c6168d;
$color-border: #3B3C3D;
$color-border2: #c4c4c4;
$color-borderInput: #dddddd;
$color-grey: #f5f5f5;
$color-white: #fff;
$color-dropdown: #E3DFDB;
$color-dropdownHover: #ccc;
$color-weblinkMagenta: #963270;
$color-dataTable: #d9d9d9;
$color-footer: #ededed;
$color-borderButton: #7f7f7f;
$color-borderMenu: #595959;
$color-menuTile: #19191A;
$color-background: #f3f3f3;
$color-background2: #dcdcdc;
$color-scrollbar: #B0B0B0;
$color-defaultError: #0000EE;

$orange: #f36f21;
$black: #000;
$white: #fff;
$lightgrey: #999;
$darkgrey: #6a6e66;
$border: #e2e1d2;
$default-border-radius: 7px;
$error-color: #ff544c;
$green: #008000;

$default-gradient: linear-gradient(to right, #f3f3f3 0, #ddd 100%);

$breakpoint-xlarge: 1338px;
$breakpoint-large: 1245px;
$breakpoint-medium: 768px;
$breakpoint-small: 576px;
$breakpoint-xsmall: 470px;


// fonts
$SohoGothicPro-Light: 'SohoGothicPro-Light', 'Arial', sans-serif;
$SohoGothicPro-Regular: 'SohoGothicPro-Regular', 'Arial', sans-serif;
$SohoGothicPro-Medium: 'SohoGothicPro-Medium', 'Arial', sans-serif;
$SohoGothicPro-Bold: 'SohoGothicPro-Bold', 'Arial', sans-serif;