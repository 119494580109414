.horselbutik-main {
    .header {
        &__top {
            margin-top: 10px;
            margin-bottom: 10px;
            align-items: center;
            justify-content: center;
        }

        .header-bottom-links {
            background-color: $white;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: flex-start;

            &__list-item {
                padding-bottom: 14px;

                &:not(:last-of-type) {
                    margin-right: 0;
                    padding-right: 40px;
                }

                &--arrow-down {
                    &::before {
                        font-family: 'oticon-icons';
                        font-style: normal;
                        font-weight: 400;
                        font-variant: normal;
                        text-transform: none;
                        font-size: .75rem;
                        line-height: 1;
                        content: "\e601";
                        position: absolute;
                        top: 6px;
                        right: 20px;
                    }
                }
            }

            &__list-link {
                font-family: $SourceSansPro-Bold;
                text-decoration: none;
                z-index: 9;
            }

            &__lvl2 {
                top: 34px;
                box-shadow: 0px 0px 8px rgba(200, 207, 214, 0.5);
                
                a {
                    font-size: px-to-rem(16);
                    font-family: $SourceSansPro-Bold;
                }
            }

            &__lvl2-item {
                a {
                    font-family: $SourceSansPro-Regular;
                    text-decoration: none;
                }

                &:hover {
                    background-color: $input-background;
                    border-left: 2px solid $default-color;
                }
            }
        }

        .logo {
            @media (min-width: $breakpoint-medium) {
                position: relative;
                right: 20px;
                width: 240px;
                margin-top: 0;
            }
        }

        .links {
            @media (min-width: $breakpoint-medium) {
                padding-top: 10px;
            }

            > li {
                a {
                    color: $black;
                    padding-left: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &.customer-service {
                    a {
                        font-family: $SourceSansPro-SemiBold;
                        font-size: px-to-rem(14);
                        font-weight: normal;

                        &::before {
                            content: '';
                            top: 0px;
                            width: 25px;
                            height: 25px;
                            position: relative;
                            display: block;
                            margin-bottom: 5px;
                            background: url('../../../images/customer-service-horselbutik.svg');
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }

                @media (max-width: 767px) {
                    &.customer-service {
                        a {
                            margin-left: 0;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }

                &.deskstop-login {
                    margin-top: 0;

                    a {
                        color: $black;
                        font-size: px-to-rem(14);
                        font-family: $SourceSansPro-SemiBold;
                        margin: 0;
                        padding: 0;
                        border-radius: 8px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;

                        @media (max-width: 767px) {
                            flex-direction: row;
                            padding-bottom: 10px;
                            margin-left: 0;

                            &::before {
                                display: none;
                            }
                        }

                        &::before {
                            content: url('../../../images/login-horselbutik.svg');
                            top: 0px;
                            width: 23px;
                            height: 26px;
                            position: relative;
                            display: block;
                            margin-bottom: 5px;
                        }

                        &.logged-in {
                                &::before {
                                    content: url('../../../images/login-horselbutik.svg');
                                }
                        }
                    }
                }

                .header-link-container {
                    position: relative;


                }
            }

            &.static-links {
                > li {
                    padding-left: 35px;

                    &:first-of-type {
                        padding-left: 0;

                        a {
                            padding-left: 0;
                        }
                    }
                }
            }
        }

        &__border-wrapper {
            border-bottom: 1px solid $header-border;
        }
    }

    .header-dark {
        background: $white;
    }

    .headercart {
        margin-left: 30px;
        padding-top: 10px;

        &__top {
            margin-top: 0;

            > a {
                align-items: center;
            }

            a {
                color: $black;
                font-size: px-to-rem(16);
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-family: $SourceSansPro-Bold;
                font-weight: normal;

                .icon-top-cart {
                    line-height: 1;
                    height: auto;
                    display: block;

                    &::before {
                        content: url('../../../images/shopping-cart-horselbutik.svg');
                        top: 0;
                        position: static;
                        font-size: 26px;
                        margin-bottom: 6px;
                    }
                }

                span {
                    font-size: px-to-rem(14);
                    font-family: $SourceSansPro-SemiBold;
                    line-height: 21px;
                    color: $black;

                    &.headercart-items {
                        margin-left: 10px;
                        font-family: $SourceSansPro-SemiBold;
                        color: $black;
                    }
                }
            }
        }
    }

    .headercart-inner {
        &::before,
        &::after {
            border-bottom-color: $white;
        }

        .product-name {
            span {
                font-family: $SourceSansPro-Regular;
            }
        }

        .product-details {
            font-size: px-to-rem(14);
            font-family: $SourceSansPro-Regular;
            padding-right: 15px;

            .price-amount {
                font-family: $SourceSansPro-Bold;
            }
        }

        .item-options {
            font-family: $SourceSansPro-Regular;
        }

        .mini-products-list {
            li {
                border-top: 2px solid $default-backgroundColor;
                padding-top: 20px;

                &:first-of-type {
                    border-top: none;
                }

                &.item {
                    font-family: $SourceSansPro-SemiBold;

                    &--shipping-price {
                        font-size: px-to-rem(16);
                    }
                }
            }
        }
    }

    .header-notification-bar {
        padding: 1% 2%;
        background-color: $default-activeColor;
        color: $white;
        font-size: px-to-rem(14);
        font-family: $SourceSansPro-Bold;
        font-weight: normal;
        position: relative;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: $breakpoint-medium) {
            padding: 0 10px;
        }

        &__close-btn {
            position: absolute;
            top: 50%;
            right: 2%;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            background: none;
            border: none;
            padding: 0;
            background-image: url('../../../images/minicart-cross.svg');
            background-repeat: no-repeat;
            background-size: cover;
            outline: none;

            @media (min-width: $breakpoint-medium) {
                right: 17px;
            }
        }
    }

    .header-top-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 100%;
        border: 1px solid $header-border;
        padding: 0 2%;

        @media (min-width: $breakpoint-medium) {
            padding: 0;
        }

        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media (min-width: $breakpoint-medium) {
                justify-content: space-between;
            }
        }

        &__list {
            display: none;
            align-items: center;
            justify-content: center;
            font-size: px-to-rem(14);

            @media (min-width: $breakpoint-medium) {
                display: flex;
            }
        }

        &__list-item {
            position: relative;
            padding-left: 27px;

            &:not(:last-of-type) {
                margin-right: 60px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 1px;
                left: 0;
                background-image: url('../../../images/horselbutik-checkmark.svg');
                width: 16px;
                height: 15px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        
        &__phone-wrapper {
            display: flex;
            align-items: center;
        }

        &__phone {
            font-size: px-to-rem(14);
            font-family: $SourceSansPro-Bold;
            position: relative;
            padding-left: 27px;
            display: block;
            color: $black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            &::before {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                background-image: url('../../../images/horselbutik-phone.svg');
                width: 16px;
                height: 15px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }

        &__contact-hours {
            font-size: px-to-rem(14);
            margin-left: 10px;
        }
    }

    .mobile-header {
        background-color: $white;

        .hamburger {
            width: 32px;

            .bread {
                background-color: $default-color;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            & + span {
                visibility: visible;
                text-indent: 0;
                font-size: px-to-rem(16);
                padding-left: 6px;
                line-height: 1rem;
                font-family: $SourceSansPro-SemiBold;
            }
        }

        .headercart {
            background-color: $white;
            padding-top: 17px;

            .headercart-info {
                flex-direction: row;
            }
        }

        .logo-min {
            width: 180px;
            height: auto;
            margin: 0;
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translate(-50%);
            margin-left: 10px;

            @media (min-width: $breakpoint-xsmall) {
                margin-left: 0;
            }
        }

        .header {
            &__menu {
                background-color: $white;
                opacity: 1;

                li {
                    &.level1 {
                        a {
                            font-family: $SourceSansPro-Regular;
                        }
                    }

                    a {
                        color: $default-color;
                        font-family: $SourceSansPro-Bold;
                        font-weight: normal;
                    }
                }

                .links {
                    a {
                        padding-left: 36px; 

                        &:first-of-type {
                            &::before {
                                content: url('../../../images/login-horselbutik.svg');
                            }
                        }
                    }

                    .customer-service {
                        a {
                            flex-direction: row;
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    .phone-menu {
        a {
            color: $default-color;
            display: flex;
            text-decoration: none;
            margin-top: 6px;

            &.active {
                background: url('../../../images/close-icon-horselbutik.svg') 50% no-repeat;
                height: 24px;
                width: 24px;
                background-size: cover;
                background-position: center center;
            }
        }
    }
}