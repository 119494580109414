.phonicear-main {
    .info-blocks-wrapper {
        background-color: $white;
    }

    .bottom-info-block {
        margin-left: -20px;
        margin-right: -20px;

        h6 {
            font-family: $MaxPro-Bold;
            font-size: px-to-rem(20);
        }

        p {
            font-family: $MaxPro-Light;
        }

        div {
            background: $white;
            padding: 20px;
            margin: 20px;
            border: 2px solid $color-border;
            border-radius: 4px;
        }

        .link {
            color: $white;
            position: relative;
            display: inline-flex;
            align-items: center;
            font-family: $MaxPro-Light;
            line-height: 1;
            transition: .3s;
            cursor: pointer;
            font-size: px-to-rem(16);
            width: auto;
            height: auto;
            box-sizing: border-box;
            overflow: visible;
            background-color: $color-border;
            border-radius: 2rem;
            padding: 10px 11px;
            border: 2px solid $color-border;

            &::before {
                content: '';
                border-radius: 50%;
                animation: none;
                left: 0;
                top: auto;
                box-sizing: border-box;
                border: none;
                @include default-transition(all);
                position: static;
                width: 28px;
                height: 28px;
                background-color: $white;
            }

            &::after {
                content: "";
                font-family: iconfont;
                position: absolute;
                top: 50%;
                font-weight: 800;
                transform: translateY(-45%);
                font-size: px-to-rem(14);
                color: $color-border;
                left: 20px;
            }

            &:hover {
                background-color: $white;
                color: $error-color;
                border-color: $white;

                &::before {
                    background-color: $color-border;
                    animation: none;
                }

                &::after {
                    color: $white;
                }
            }
        }

        .link-text {
            font-size: px-to-rem(14);
            display: block;
            margin-left: 14px;
            padding-right: 17px;
        }
    }
}