.bernafon-main {
    .category-menu-tile {
        border: none;
        box-sizing: border-box;
        width: 290px;
        height: 290px;
        position: relative;
        margin-bottom: 25px;
        padding-bottom: 35px;
        box-shadow: 0px 2px 4px rgba($default-color, 0.3);
        @include default-transition(box-shadow);

        @media (max-width: 767px) {
            margin-right: auto;
            margin-bottom: 75px;
        }

        &:hover {
            box-shadow: 0px 2px 12px rgba($default-color, 0.3);
        }

        & > div {
            position: absolute;
            bottom: 15px;
        }

        &__product-name {
            font-size: px-to-rem(20);
            font-weight: normal;
            color: $default-color;
            font-family: $Roboto-Medium;
        }

        &__img {
            max-width: 90%;
            margin: 48px auto 15px;
        }
    }

    .category-menu {
        &__title {
            font-size: px-to-rem(32);
            margin: 20px auto;
        }

        &__list {
            justify-content: center;
        }
    }
}