@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto-Light.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../fonts/Roboto-Medium.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'bernafon-iconfont';
    src:  url('../fonts/bernafon-iconfont.eot');
    src:  url('../fonts/bernafon-iconfont.eot#iefix') format('embedded-opentype'),
          url('../fonts/bernafon-iconfont.ttf') format('truetype'),
          url('../fonts/bernafon-iconfont.woff') format('woff'),
          url('../fonts/bernafon-iconfont.svg#bernafon-iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}