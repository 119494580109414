.bernafon-main {
    .page-print {
        .col2-set {
            .col-1,
            .col-2 {
                float: left;
                width: 50%;
                padding-right: 30px;
            }
        }

        .print-head {
            .logo {
                width: 170px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        .buttons-set {
            button {
                width: auto;
                border: none;
                padding-bottom: 12px;
            }
        }
    }
}