.bernafon-main {
    .adyen-checkout {
        &__payment-method {
            &:hover {
                &:not(.adyen-checkout__payment-method--selected) {
                    .adyen-checkout {
                        &__payment-method {
                            &__radio {
                                background-color: $default-color;

                                &::after {
                                    height: 8px;
                                    width: 8px;
                                }
                            }
                        }
                    }
                }
            }

            &__radio {
                border: none;
                background-color: $default-color;

                &::after {
                    background-color: $white;
                    height: 16px;
                    width: 16px;
                    transform: translateY(-50%) scale(1);
                }

                &--selected {
                    background-color: $default-activeColor;

                    &::after {
                        height: 8px;
                        width: 8px;
                    }
                }
            }

            &__name {
                font-family: $Roboto-Medium;
                font-weight: normal;
                font-size: px-to-rem(18);
            }
        }

        &__button {
            border-radius: 40px;
            border: 1px solid $default-activeColor;
            color: $default-activeColor;
            background-color: $white;
            transition: .2s ease color, .2s ease background-color;
            font-family: $Roboto-Regular;
            font-size: px-to-rem(20);
            padding-bottom: 0;
            background-color: $default-activeColor;
            color: $white;
            padding: 0;

            &:hover {
                color: $default-activeColor;
                background-color: $white;
            }
        }

        &__dropin {
            outline: none;
        }

        &__input {
            border: none;
            border-bottom: 1px solid $default-color;

            &--focus,
            &--focus:hover,
            &:hover,
            &:focus,
            &:active,
            &:active:hover {
                border: none;
                border-bottom: 1px solid $default-color;
            }

            &--error,
            &--error:hover,
            &--invalid,
            &--invalid:hover {
                border-color: $default-activeColor;
            }
        }

        &__error-text {
            color: $default-activeColor;
        }
    }

    .payments-label {
        &--h1 {
            font-family: $Roboto-Regular;
        }

        &--h2,
        &--p-bold {
            font-family: $Roboto-Medium;
        }
    }

    .payments-container {
        box-shadow: 0px 2px 4px rgba($default-color, 0.3);

        &__heading {
            background-color: $header-background;
        }

        &__inner {
            background: $white;
            border: none;
        }
    }
}