.horselbutik-main {
    .welcome-msg {
        margin-top: 40px;
        margin-bottom: 10px;
        padding-bottom: 40px;
        border-bottom: 1px solid $header-border;

        h2 {
            font-family: $SourceSansPro-Bold;
            font-weight: normal;
        }
    }

    .address-book-table {
        .buttons-set {
            .button {
                margin-left: 0;
            }
        }
    }

    .my-account {
        .dashboard.account-wr {
            .form-list {
                .custom-select__label {
                    font-size: px-to-rem(16);
                }
            }

            .buttons-set {
                .button {
                    margin-left: 0;
                    width: 120px;
                }
            }
        }
    }
}