.page-print {
    background: $white;
    padding: 20px;
    text-align: left;
    font-size: .68rem;

    h1 {
        font-size: 1.125rem;
        font-weight: normal;
        color: $black;
    }

    h2 {
        font-size: 1rem;
        font-weight: normal;
        color: $black;
        margin-bottom: 15px;
    }

    h3 {
        display: block;
        font-size: 1.17em;
        font-weight: bold;
    }

    .a-right {
        text-align: right !important;
    }

    .nobr {
        white-space: nowrap !important;
    }

    .print-head {
        display: flex;
        padding-bottom: 18px;

        .logo {
            width: 145px;
        }

        img {
            float: left;
        }
    }

    .col2-set {
        margin: .68rem 0 20px;
        display: flex;

        .col-1,
        .col-2 {
            float: left;
            width: 50%;
            padding-right: 30px;
        }
    }

    .data-table {
        border: 1px solid $color-dataTable;
        border-bottom: 0;
        width: 100%;
        border-collapse: separate;

        thead {
            background-color: $color-footer;
            font-size: 11px;
        }

        tbody {
            display: table-row-group;
            vertical-align: middle;
            border-color: inherit;

            tr:nth-child(odd),
            .data-table tr.odd {
                background-color: $white;
            }
        }

        tfoot {
            background-color: $color-footer;
        }

        th,
        td {
            vertical-align: top;
            text-align: left;
            white-space: nowrap;
        }

        td {
            padding: 5px 12px;
        }

        th {
            padding: 5px 12px;
            color: #2d2d2d;
            border-bottom: 1px solid $color-dataTable;
            font-weight: normal;
            text-transform: uppercase;
            white-space: nowrap;
            display: table-cell;
        }

        tr:last-child td,
        .data-table tr:last-child th {
            border-bottom: 1px solid $color-dataTable;
        }

        .product-name {
            font-size: 12px;
            font-weight: bold;
            color: #333;
            white-space: pre-wrap;
        }
    }

    .buttons-set {
        clear: both;
        margin: 15px 0;
        float: right;
        margin-left: 7px;

        button {
            border: 0;
            padding: 10px 35px;
            margin: 0;
            cursor: pointer;
            background-position: 0 0;
            display: -moz-inline-stack;
            display: inline-block;
            text-decoration: none;
            border: 2px solid $color-borderButton;
            font-size: 13px;
            height: auto;
            line-height: 20px;

            &:hover {
                background-color: $color-borderButton;
            }
        }
    }

    .table-overflow-wrapper {
        overflow: auto;
        padding: 2px 0;
    }
}