.category-top {
    background: $color-background;
    background: $default-gradient;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f3f3', endColorstr='#dddddd', GradientType=1);
    padding: 0 15px;
}

.category-description {
    &:after {
        clear: both;
    }

    &__img{
        text-align: center;
        float: right;
        width: 100%;

        img {
            max-height: 340px;
        }
    }

    &__title {
        float: left;
        width: 100%;
        padding-bottom: 50px;

        h2 {
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
            font-size: 42px;
            line-height: 48px;
            margin-bottom: 20px;
            padding-top: 25px;
        }

        p {
            font-family: $SohoGothicPro-Light;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
        }
    }

    .button {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media (min-width:768px) {
    .category-description {
        &__title {
            width: 50%;
            padding-bottom: 50px;
            padding-top: 15px;

            h2 {
                padding-top: 0;
            }
        }

        &__img {
            width: 50%;
            padding-top: 20px;
        }

        .button {
            width: 270px;
        }
    }
}

@media (min-width:1245px) {
    .category-description {
        &__title {
            h2 {
                font-size: 58px;
            }

            p {
                font-size: 20px;
                margin-bottom: 0;
            }
        }
    }
}