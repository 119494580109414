/* Widgets =============================================================================== */
.input-wrapper input::placeholder {
    color: $default-color;
}

select:focus {
    outline: none;
}

body, input {
    font-family: $SohoGothicPro-Regular;
    font-weight:400;
}

.button-addToCart:active,
.button:active,
a,
a:active,
a:focus,
a:hover,
input {
    outline: 0;
}

.btn-remove,
input[type=file],
label,
legend {
    display:
    block;
}

.breadcrumbs:after,
.clearfix:after,
.messages:after,
.wr-block:after,
.wrapper:after {
    clear: both;
}

.button,
.button-addToCart {
    background: 0 0;
    color: $color-defaultError;
    font-size: px-to-rem(12);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    display: block;
    border: none;
    width: auto;
    height: auto;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
    cursor: pointer;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: underline;

    .oticon-main & {
        font-family: $SohoGothicPro-Medium;
        border: 4px solid $color-border;
        font-weight: 500;
        font-size: px-to-rem(16);
        width: 100%;
        padding: 0px 4px;
        line-height: 40px;
        text-decoration: none;
        color: $color-border;
    }
}

@media (max-width:767px) {
    .button {
        height: 52px;
    }

}

@media (min-width:768px) {
    .button,
    .button-addToCart {
        height: auto;
        font-size: 20px;

        .oticon-main & {
            height: 49px;
            font-size: 16px;
        }
    }

}

body,
html {
    height: 100%;
}

.button-addToCart:focus,
.button-addToCart:hover,
.button:focus,
.button:hover {
    background-color: transparent;
    border-color: $default-color;
    color: $color-defaultError;
    text-decoration: none;
    -webkit-transition: background-color .2s ease-in, color .2s ease-in;
    transition: background-color .2s ease-in, color .2s ease-in;
    outline: 0;

    .oticon-main & {
        background-color: $default-color;
        color: $white;
    }
}

.button-addToCart:disabled {
    background: $default-activeColor;
    opacity: .6;
}

.button-addToCart {
    background-color: $default-activeColor;
    border: $default-activeColor;
    color: $white;

    .oticon-main & {
        border: $default-activeColor;
        color: $white;
    }
}

.br-small
.static-links>li.active>a,
.br-small .static-links>li.active>a:before {
    color: $white;
}

a,
body,
input {
    color: $default-color;
}

*, :after, :before {
    box-sizing: border-box;
}

body {
    min-height: 100%;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 200;
    margin: 0;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 26px;
    font-family: $SohoGothicPro-Regular;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 15px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border: 0;
}

fieldset {
    padding: 0;
    border: 0;
}

legend {
    width: 100%;
    border: 0;
}

input {
    border: 1px solid $color-borderInput;
}

input[type=radio], input[type=checkbox] {
    margin: 1px 0 0;
}

input[type=text] {
    height: 40px;
}

@media (min-width:768px) {
    input[type=text] {
        height: 50px;
    }

}

input {
    padding: 1%;
    border-radius: 0;
}

select{
	border-radius: 0;
}

select[multiple],
select[size] {
    height: auto;
}

input[type=radio]:focus, input[type=checkbox]:focus, input[type=file]:focus {
    outline: #333 dotted thin;
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    height: auto;
}

.input-text:-moz-placeholder {
    color: $lightgrey;
}

.input-text::-moz-placeholder {
    color: $lightgrey;
}

.input-text:-ms-input-placeholder {
    color: $lightgrey;
}

.input-text::-webkit-input-placeholder {
    color: $lightgrey;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $white inset;
}

.input-text:focus {
    outline: 0;
}

.input-text[disabled],
.input-text[readonly],
fieldset[disabled] .input-text {
    cursor: not-allowed;
}

.checkbox, .radio, label {
    cursor: pointer;
    width: auto;
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

table {
    border: none;
}

.validation-advice, .footer-validation-advice {
    color: $default-activeColor;
    text-align: left;
    margin: 1% 0;
    font-size: 12px;
    font-family: $SohoGothicPro-Regular;
    order: -1;
}

.success {
    color: $default-color
}

.error {
    color: $default-activeColor
}

.notice {
    color: $color-dropdownHover;
}

.error-msg {
    color: $default-activeColor;

    a {
        color: $default-activeColor;
        text-decoration: underline;
    }
}

.error-msg, .note-msg, .success-msg {
    padding: 1%;
    list-style: none;
    // display: none;
}

.messages {
    margin: 20px 0;
    border: 1px solid $default-color
}

.messages {
    &::after,
    &::before {
        content: "";
        display: table;
    }

    ul {
        list-style: none;

        ul {
            list-style: none;
        }
    }
}

@media (min-width:768px) {
    .messages {
        width: 100%;
        margin: 1% auto
    }

}

.checkout-cart-index .messages {
    margin-bottom: 1%;
}

.clearfix {
    &::after,
    &::before {
        content: " ";
        display: table;
    }
}

.hidden {
    display: none;
}

input[type=checkbox].css-checkbox+label.css-label:before {
    display: inline-block;
}

.form-list {
    label {
        margin: 10px 0;
        font-size: 12px;
        font-family: $SohoGothicPro-Regular;
        font-weight: 400;
    }

    input,
    select {
        width: 100%;
        padding: 1%;
        box-sizing: border-box;
        height: 40px;
    }

    .control {
        overflow: hidden;

        input,
        label {
            display: inline-block;
        }
    }
}

@media (min-width:768px) {
    .form-list {
        input,
        select {
            width: 400px;
            height: 50px;
        }
    }
}

input[type=checkbox].hidden-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

label.css-label {
    position: relative;
    padding-left: 25px;
    line-height: 22px;
    font-family: $SohoGothicPro-Regular;
}

input[type=checkbox].css-checkbox:checked+label.css-label:before {
    content: '\e60b';
    top: 12px;
    font-weight: bold;
}

input[type=radio] + label span {
    width: 18px;
    height: 18px;
    font-family: oticon-icons;
    left: 0;
    line-height: 18px;
    position: absolute;
    border: 1px solid $color-borderInput;
    border-radius: 10px;
    text-align: center;
    top: 12px;
    font-size: px-to-rem(14);

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
}

label.css-label:before {
    content: '';
    top: 50%;
    width: 18px;
    height: 18px;
    font-family: oticon-icons;
    margin-top: -10px;
    font-size: 12px;
    left: 0;
    position: absolute;
    display: block;
    padding-left: 3px;
    background: $white;
    border: 1px solid $default-color;
    border-radius: 10px;
    line-height: 19px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input[type=radio] {
    display: none;
}

input[type=radio]+label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;
}

input[type=radio]:checked + label span {
    &::before {
        background-color: $default-activeColor;
    }
}

.mobile-wr {
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 10;

    .links {
        a {
            color: $lightgrey;
        }
    }
}

.wrapper {
    width: 100%;
    margin: 0 auto;
}

@media (min-width:768px) {
    .mobile-wr {
        position: static;
    }

    .wrapper.mobile-wr {
        margin: 0 auto;
    }

}

@media (min-width:1245px) {
    .wrapper.mobile-wr {
        margin: 0 auto;
    }

}

.wrapper {
    &:after,
    &::before {
        content: "";
        display: table;
    }
}

@media (min-width: $breakpoint-medium) {
    .wrapper {
        width: calc(100% - 40px);
        margin: 0 auto;
    }
}

@media (min-width: $breakpoint-large) {
    .wrapper {
        max-width: 1225px;
        margin: 0 auto;
    }
}

@media (min-width: $breakpoint-xlarge) {
    .wrapper {
        max-width: 1318px;
        margin: 0 auto;
    }
}

.wr-block {
    background: $color-grey;
    padding: 2%;
    margin-top: 40px !important;
    margin-bottom: 40px !important;

    &::after,
    &::before {
        content: "";
        display: table;
    }
}

.main-container {
    position: relative;
    min-height: 470px;
}

@media (max-width:767px) {
    .main-container {
        padding: inherit;
    }

}

.page-not-found {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 1000px;
    width: 100%;

    h1 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: px-to-rem(40);
        font-weight: 700;

        .oticon-main & {
            font-family: $SohoGothicPro-Regular;
        }
    }

    button {
        max-width: 260px;
        margin: 0 auto;
        margin-bottom: 55px;
    }
}

@media (max-width:767px) {
    .page-not-found {
        h1 {
            font-family: $SohoGothicPro-Regular;
            font-size: px-to-rem(25);
        }
    }
}

.br-small {
    display: block;
}

.br-large, .price-box .label {
    display: none;
}

.col-2, .col-2right, .col-3, .col-5, .col-7 {
    width: 100%;
}

.col-4 {
    width: 46%;
}

.col-6 {
    width: 49%;
    margin: 0 .5%;
}

.price-box .regular-price {
    display: block;
}

.breadcrumbs {
    display: none;

    &::after,
    &::before {
        content: "";
        display: table;
    }

    &__list {
        padding: 20px 0;
        margin-bottom: 60px;
        border-bottom: 1px solid $color-borderInput;
        background: $white;

        &::after {
            content: "";
            clear: both;
            display: table;
        }

        li {
            float: left;
            margin-left: 4px;
            font-size: 14px;
            line-height: normal;
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;

            &:first-child {
                margin-left: 0;
            }

            a,
            span {
                font-family: $SohoGothicPro-Regular;
                font-weight: 400;
                font-size: px-to-rem(14);
            }

            span {
                display: inline-block;
                width: 15px;
                margin: 0px 5px;
                position: relative;
                top: 0;
                font-size: 1.4rem;
                vertical-align: top;

                &::before {
                    font-family: 'oticon-icons';
                    speak: none;
                    font-style: normal;
                    font-weight: 400;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    content: "\e609";
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}

@media (min-width:768px) {
    .breadcrumbs {
        display: block;
    }

}

@media only screen and (min-width:480px) {
    .br-large {
        display: none;
    }
}

@media only screen and (min-width:768px) {
    .br-large {
        display: flex;
    }

    .br-small {
        display: none;
    }

    .col-2, .col-2right {
        width: 49%;
    }

    .col-2 {
        float: left;
    }

    .col-2right {
        float: right;
    }

    .col-3 {
        width: 82%;
    }

    .col-4 {
        width: 24%;
    }

    .col-5 {
        width: 17%;
    }

    .col-6 {
        width: 24%;
        margin: 0 .5%;
    }

    .col-7 {
        width: 32%;
    }

    .margin-r {
        margin-right: 2%;
    }

}

@media only screen and (min-width:1245px) {
    .br-large {
        display: flex;
    }

    .col-4 {
        width: 25%;
    }

    .col-6 {
        width: 15.6%;
        margin: 0 .5%;
    }

}

.cms-start .messages {
    display: none;
}

@media (min-width:1245px) {
    .hide-paymentpage {
        padding: 0;
    }
}

.logo {
    float: left;
    margin-top: 20px;
    width: 130px;
}

.checkout-onepage-index .header {
    background: 0 0;
}

.br-small {
    .icon-my-account:before,
    .static-links>li:before {
        content: '\e601';
        display: block;
        top: 0;
        width: 12px;
        font-size: 12px;
        color: $lightgrey;
        font-family: oticon-icons;
    }

    .icon-my-account {
        display: inline-block;
        width: 12px;
        height: 10px;
        position: relative;
        margin-left: 10px;

        &::before {
            position: absolute;
            left: 0;
            height: 10px;
        }
    }

    .static-links>li {
        padding-right: 16px;
        position: relative;
        min-width: 156px;

        &::before {
            position: absolute;
            left: 140px;
            height: 12px;
            margin-top: 7px;
        }

        &.active {
            &::before {
                color: $default-activeColor
            }

            a {
                color: $white;

                &::before {
                    color: $white;
                }
            }
        }

        ul {
            li {
                padding-top: 10px;
            }
        }
    }

    #search_mini_form-1 {
        input {
            margin-left: 42px;
            width: 76%;
            border-radius: 10px;
            background: $default-color;
            color: $lightgrey;
            opacity: 0.5;

            &:focus {
                background: #5d5d5d;
                color: $lightgrey;
            }
        }
    }
}

.account-nav,
.br-small .static-links>li ul {
    display: none;
}

.br-small #search_mini_form-1 input::placeholder {
    color: $lightgrey;
}


#recently-added-container {
    position: absolute;
    top: 37px;
    right: 0;
    width: 315px;
    z-index: 99999;
    visibility: hidden;

    .viewing {
        padding: 17px 15px;
        background: $default-color;
        color: $white;
        font-size: 11px;

        p {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                &.headercart-info-items {
                    padding-right: 2px;
                    display: inline-block;
                }
            }
        }

        .sum {
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
            padding-left: 12px;
        }
    }
}

#search_mini_form,
#search_mini_form-1 {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $color-borderMenu;

    .input-wrapper {
        position: relative;

        &::before {
            content: "\E01E";
            display: block;
            position: absolute;
            top: 50%;
            left: 10px;
            width: 19px;
            height: 19px;
            color: $lightgrey;
            font-family: iconfont;
            font-size: 19px;
            margin-top: -12px;
        }

        .search-icon {
            display: none;
        }
    }

    input {
        background: $color-grey;
        padding: 0 4%;
        width: 91%;
        border: 1px solid transparent;
        border-radius: 15px;
        background-clip: padding-box;
        font-size: 16px;
        height: 40px;
        font-family: $SohoGothicPro-Regular;
        font-weight: 400;
    }
}

#search_mini_form-1 {
    .input-wrapper {
        &::before {
            font-size: 24px;
            width: auto;
            height: auto;
        }
    }
}

@media (min-width:768px) {
    .menu-link {
        font-size: 18px;
    }

    #search_mini_form, #search_mini_form-1 {
        margin-top: 27px;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 0;
        border: none;
        position: relative;
        top: auto;
        left: 30px;

        input {
            font-size: 12px;
        }
    }

}

@media (min-width:768px) {
    #search_mini_form {
        input {
            padding: 0 12px 0 35px;
            line-height: 40px;
            width: 100%;
            max-width: 130px;
        }
    }
}

@media (min-width:992px) {
    #search_mini_form {
        input {
            max-width: none;
        }
    }
}

@media (min-width:1245px) {
    #search_mini_form {
        max-width: 311px;

        input {
            width: 311px;
        }
    }
}

#search_mini_form-1 {
    input {
        &:focus {
            background: $white;
        }
    }
}

.bottomlinks,
.copyright {
    font-size: 12px;
    font-family: $SohoGothicPro-Regular;
    line-height: 20px;
}

.bottomlinks {
    li {
        line-height: 30px;

        a {
            text-decoration: underline;
            color: $color-footer;
        }
    }
}

@media (min-width:768px) {
    .bottomlinks {
        float: left;
    }

    .bottomlinks li {
        float: left;
        margin-left: 20px;
        line-height: 20px;
    }

}

.category-description:after,
.category-description:before,
.toolbar:after,
.toolbar:before {
    content: "";
    display: table;
}

.category-top .wrapper, .pagers-block {
    position: relative;
}

.category-description__img, .category-name, .pagination, .text-badge {
    text-align: center;
}

.category-description:after, .price-box:after, .toolbar:after {
    clear: both;
}

.category-top {
    background: $color-background;
    background: $default-gradient;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f3f3', endColorstr='#dddddd', GradientType=1);
    padding: 0 15px;
}

.category-bottom {
    padding-bottom: 50px;
    h2 {
        line-height: 1.1;
        margin-top: 2.25rem;
        margin-bottom: 1.8rem;
    }
}

.text-badge {
    font-size: 16px;
    margin-top: 20px;
    text-transform: uppercase;
}

.category-description {
    &__title {
        float: left;
        width: 100%;
        padding-bottom: 50px;
    }
}
@media (min-width:768px) {
    .category-description {
        &__title {
            width: 50%;
            padding-bottom: 50px;
        }
    }
}

.category-description {
    &__title {
        h2 {
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
            font-size: 42px;
            line-height: 48px;
            margin-bottom: 20px;
            padding-top: 25px;
        }

        p {
            font-family: $SohoGothicPro-Light;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
        }
    }

    .button {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &__img {
        float: right;
        width: 100%;

        img {
            max-height: 340px;
        }
    }
}

@media (min-width:1245px) {
    .category-description {
        &__title {
            h2 {
                font-size: 58px;
            }

            p {
                font-size: 20px;
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width:768px) {
	.category-description {
        &__title {
            h2 {
                padding-top: 0;
            }
        }

        .button {
            width: 270px;
        }

        &__img {
            width: 50%;
        }
    }
}

.pagers-block {
    width: 100%;
    min-height: 50px;
    margin-bottom: 20px;

    .amount {
        float: right;
        margin-right: 3%;
    }
}

@media (min-width:768px) {
    .pagers-block {
        .amount {
            position: absolute;
            top: 23px;
            left: 0;
            margin-right: 0;
            font-family: $SohoGothicPro-Regular;
        }
    }
}

.products-grid {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

@media (min-width:575px) {
	.products-grid {
        margin: 0 -1%;
    }
}

@media (min-width:1245px) {
    .products-grid {
        margin: 0 -1%;
    }
}

.products-grid {
    &__item {
        background-color: $color-grey;
        position: relative;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px;

        .actions {
            margin: 6%;
            position: relative;
            height: 283px;
            display: flex;
            flex-direction: column;

            &__inner {
                overflow: hidden;
            }
        }

        .product-image {
            display: block;
            text-align: center;

            .image-div {
                display: flex;
                width: 100%;
            }
            
            .image-div::before {
                content: "";
	            display: block;
	            padding-top: 100%; 
            }

            img {
                width: 100%;
                margin: auto;
            }
        }

        .product-name {
            max-height: 59px;
            font-size: 14px;
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
            overflow: hidden;
            text-align: left;
            margin-bottom: 5px;
        }

        .attr-tax,
        .long-description,
        .short-description {
            display: block;
            font-size: 12px;
        }

        .attr-tax {
            font-size: 10px;
            height: 16px;
        }

        .short-description {
            height: 19px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .button {
            margin-top: 15px;
        }
    }
}

@media (min-width:575px) {
    .products-grid {
        &__item {
            flex: 0 0 47.8%;
            max-width: 47.8%;
            margin: 0 1% 2%;
        }
    }
}

@media (min-width:768px) {
    .products-grid {
        &__item {
            .actions {
                margin: 15px;
            }

            .product-name {
                font-size: 18px;
            }

            .attr-tax {
                font-size: 11px;
            }
        }
    }
}

@media (min-width:992px) {
    .products-grid {
        &__item {
            flex: 0 0 23%;
            max-width: 23%;
            margin: 0 1% 2%;
        }
    }
}

.icon-add-to-cart {
    display: inline-block;
    position: relative;
    width: 26px;
    height: 24px;
    margin-right: 12px;

    &::before {
        content: '\e800';
        display: block;
        position: absolute;
        top: 9px;
        left: 0;
        font-size: 18px;
        font-family: top-basket;
        width: 26px;
        height: 24px;
    }
}

@media (min-width:768px) {
    .icon-add-to-cart {
        &:before {
            top: 7px;
            font-size: 24px;
            line-height: 24px;
        }
    }

}

.price-box {
    flex-grow: 2;
    
    &::after,
    &::before {
        content: "";
        display: table;
    }
}

.price-box {
    .regular-price {
        float: left;
        font-family: $SohoGothicPro-Bold;
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 3px;
    }
}

@media (min-width:768px) {
    .price-box {
        .regular-price {
            font-size: 14px;
        }
    }
}

.sort-by {
    display: none;
}

@media (min-width:768px) {
    .sort-by {
        display: block;
        position: absolute;
        right: 0;
    }
}

.product-block__media .price-box .regular-price,
.product-block__media .product-name,
.product-block__shop .info-block .inStock,
.product-block__shop .product-name {
    font-family: $SohoGothicPro-Bold;
    font-weight:bold;
}

.add-to-box,
.add-to-cart
{
    overflow: hidden;
}

.product-essential {
    padding-bottom: 2%;
    .product-list-slider {
        padding-top: 0px;
    }
}

.product-block {
    background: $color-grey;
    position: relative;

    &__media {
        text-align: center;

        img {
            padding: 0 20px 20px;
        }

        .product-detailing-top {
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            z-index: -1;

            &.is-active {
                z-index: 1;
            }

            @media (max-width:767px) {
                width: 100%;
            }
        }

        .product-name {
            font-size: 24px;
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
        }

        .info-top {
            text-align: left;
            padding: 20px;

            &.br-small {
                display: none;
            }
        }
    }

    &__shop {
        padding: 20px;
        background-color: $color-grey;

        .product-name {
            font-size: 36px;
            line-height: 36px;
            margin-bottom: 1%;
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
        }

        .attr-tax {
            font-size: 12px;
        }

        .info-block {
            margin-bottom: 20px;

            .inStock {
                position: relative;
                padding-left: 18px;
                font-family: $SohoGothicPro-Bold;
                font-weight: bold;

                &::before {
                    content: '\e60b';
                    position: absolute;
                    color: #393;
                    font-family: oticon-icons;
                    top: 5px;
                    left: 0;
                }
            }

            .outOfStock {
                position: relative;
                padding-left: 18px;
                font-family: $SohoGothicPro-Bold;
                font-weight: bold;

                &::before {
                    position: absolute;
                    color: red;
                    font-family: oticon-icons;
                    top: 5px;
                    left: 0;
                }
            }
        }

        .delivery-status {
            font-family: $SohoGothicPro-Regular;
        }

        .description {
            margin-bottom: 30px;
            line-height: 22px;
        }

        .short-description {
            position: relative;
            font-size: 16px;
            margin-bottom: 15px;
            font-family: $SohoGothicPro-Regular;
            font-weight: 400;
        }

        .price-box {
            .regular-price {
                font-size: 16px;
                font-family: $SohoGothicPro-Bold;
                font-weight:bold;
            }
        }

        .favourites-button {
            height: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            border: none;
            background: none;
            padding-left: 0;

            span {
                padding-bottom: 2px;
                @include default-transition(color);
            }

            &:hover {
                span {
                    color: $default-activeColor;
                }
            }
        }
    }
}

@media (min-width:768px) {
    .product-block {
        margin-bottom: 30px;

        &__media {
            img {
                padding: 30px 0;
            }
        }

        &__shop {
            .product-name {
                margin-bottom: 1%;
            }
        }
    }
}

@media (min-width:1245px) {
    .product-block{
        &__shop {
            padding: 20px 50px;

            .product-name {
                margin-bottom: 15px;
                font-family: $SohoGothicPro-Bold;
                font-weight: bold;
            }
        }
    }
}

@media (min-width:768px) {
    .product-block {
        &__media,
        &__shop {
            width: 50%;
        }
    }
}

.product-options-bottom {
    .required {
        display: none;
    }
}

.add-to-cart {
    margin: 20px 0 30px;

    .qty {
        float: left;
        width: 20%;
        max-width: 60px;
        height: 40px;
        margin-right: 1%;
        text-align: center;
        font-family: $SohoGothicPro-Regular;
    }

    .button-addToCart {
        float: left;
        width: 100%;
    }
}

@media (min-width:1245px) {
    .add-to-cart {
        .qty {
            margin-right: 15px;
        }
    }
}

@media (min-width:768px) {
    .add-to-cart {
        .qty {
            height: 50px;
        }

        .button-addToCart {
            max-width: 340px;
        }
    }
}

.product-options {
    &::after,
    &::before {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }
}

.select-block-wr {
    float: left;
    width: 200px;
    margin-top: 15px;
    margin-right: 5px;

    label {
        font-size: 12px;
        margin-bottom: 10px;
        font-family: $SohoGothicPro-Regular;
    }
}

@media (min-width:1245px) {
    .select-block-wr {
        margin-right: 15px;
    }

}

/* Class: std - styles for admin-controlled content ================================================================================ */
.std {
    a {
        text-decoration: underline;
        text-transform: none;

        &:hover {
            text-decoration: none;
            color: #333;
        }
    }

    ul,
    ol,
    dl,
    p,
    address {
        margin: 0 0 15px 0;
        padding: 0;
        font-family: $SohoGothicPro-Regular;
    }

    ul ul,
    ol ol,
    ul ol,
    ol ul {
        margin: .5em 0;
    }

    ol {
        &.ol {
            list-style: decimal outside;
            padding-left: 16px;
        }
    }

    dl {
        dt {
            font-weight: bold;
        }

        dd {
            margin: 0 0 10px;
        }
    }

    ul {
        list-style: disc outside;
        padding: 0 0 0 40px;

        ul {
            list-style-type: circle;
        }
    }

    ol {
        list-style: decimal outside;
        padding: 0 0 0 40px;
    }

    dt {
        font-weight: bold;
    }

    dd {
        padding: 0 0 0 16px;
    }

    address {
        font-style: normal;
    }

    strong {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }

    h2 {
        margin-bottom: 10px;
    }

    h3 {
        margin-bottom: 8px;
    }

    h4 {
        margin-bottom: 6px;
    }

    h5 {
        margin-bottom: 4px;
    }

    h6 {
        margin-bottom: 2px;
    }

    table {
        border: 1px solid $color-dropdownHover;

        th,
        td {
            border: 1px solid $color-dropdownHover;
            padding: 5px;
        }
    }
}

.account-login {
    &::after {
        clear: both;
    }
}

.cms-content,
div.my-account {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background: #e5e5e5;
        position: absolute;
        top: 5px;
        left: 0;
    }
}

.account-create {
    margin-bottom: 50px;
    padding: 0 15px;

    .messages {
        margin-bottom: 20px;
    }
}

@media (min-width:768px) {
    .account-create .col-2,
    .account-create .col-2right {
        width: 49%;
    }
}

.forgotpassword-page {
    .form-list {
        input {
            width: 100%;
        }
    }

    .wr-block {
        .button {
            width: 50%;
            float: right;
            margin-top: 3%;
        }
    }
}

@media (min-width:768px) {
    .forgotpassword-page {
        .wr-block {
            width: 500px;
            margin: 0 auto;
        }
    }
}

.account-create {
    .button {
        width: 100%;
        margin: 20px 0;
    }

    .form-list {
        input {
            width: 100%;
        }
    }
}

@media (min-width:768px) {
    .account-create {
        .button {
            width: 100%;
        }
    }
}

@media (min-width:1245px) {
    .account-create {
        .button {
            width: 50%;
            float: right;
        }
    }
}

.account-login {
    &::after,
    &::before {
        content: "";
        display: table;
    }

    h2 {
        font-size: 18px;
        font-family: $SohoGothicPro-Regular;
        font-weight: 400;
    }

    p {
        margin: 2% 0;
        font-family: $SohoGothicPro-Regular;
        font-weight: 400;
    }

    .button {
        float: right;
        width: 100%;
        margin-top: 3%;
    }
}

.new-users {
    float: right;
}

@media (min-width:768px) {
    .account-login {
        .button {
            width: 50%;
        }
    }
}

.new-users,
.registered-users {
    background: $color-grey;
    padding: 2%;
    margin-top: 1%;
    overflow: hidden;
    margin-bottom: 20px;
}

@media (max-width:440px) {
    .new-users,
    .registered-users {
        padding: 4%;
    }

}

.account-wr,
.block-account {
    margin-top: 30px;
}

.new-users .form-list input,
.registered-users .form-list input {
    width: 100%;
}

.registered-users {
    margin-right: 1%;
}

.block-account {
    &__content {
        li {
            padding-left: 4%;
        }

        a {
            line-height: 30px;
            color: $color-borderButton;
            font-family: $SohoGothicPro-Medium;
            font-weight:500;

            strong {
                font-weight: 400;
                color: $default-color;
            }
        }
    }
}

.account-wr h2,
.box-account h3 {
    font-family: $SohoGothicPro-Bold;
    font-weight:bold;
}

.my-order-block__table a,
.order-info-block a {
    color: $default-activeColor;
}

.account-wr {
    padding: 0 10px 10px;
    min-height: 50vh;

    h2 {
        font-size: 30px;
        line-height: 30px;
    }

    .buttons-set {
        margin: 2% 0;
        width: 100%;
    }

    .fieldset {
        z-index: 2;

        li {
            strong {
                display: block;
                margin: 15px 0;
            }
        }
    }

    .messages {
        width: 100%;
        margin: 2% 0 3%;
        padding: 0;
    }

    .pagination {
        margin-top: 10px;
    }
}

@media (min-width:768px) {
    .registered-users {
        margin-bottom: 2%;
    }

    .account-wr {
        padding: 0 20px 20px;
    }

}
@media (min-width:768px) {
    .account-wr {
        .buttons-set {
            width: 100%;
            max-width: 392px;
        }
    }
}

.box-account {
    overflow: hidden;

    h2 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    h3 {
        font-size: 14px;
    }

    &__box {
        padding-top: 20px;
        margin-bottom: 15px;

        .box-content {
            margin-bottom: 50px;

            p {
                font-size: 18px;
                line-height: 30px;
                font-family: $SohoGothicPro-Light;
                font-weight:300;
            }

            .button {
                margin-top: 20px;
                width: 92px;
            }
        }
    }
}

.address-book {
    h1 {
        float: left;
        margin-bottom: 20px;
    }

    h2 {
        &.legend {
            margin-top: 20px;
        }
    }

    .button {
        width: 100%;
        margin-top: 20px;
    }

    .link-remove {
        display: block;
        color: $default-activeColor;
        margin-bottom: 10px;
    }

    address {
        margin: 10px 0;
    }

    .form-list {
        input {
            width: 380px;
        }
    }
}

@media (min-width:768px) {
    .address-book {
        .button {
            &.address-book__btn {
                width: 33%;
            }
        }

        .box-title {
            .button {
                float: right;
            }
        }
    }
}

.my-order-block {
    &__table {
        width: 100%;
        margin: 2% 0;

        a {
            display: block;
            margin: 5px 0;
            white-space: nowrap;
        }

        tbody {
            td {
                text-align: center;

                &:first-child {
                    text-align: left;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}

.order-info-block {
    h1 {
        margin-bottom: 2%;
        font-size: px-to-rem(30) !important;
    }

    .order-date {
        font-size: 16px;
        margin: 2% 0 4%;
    }

    .box-account {
        &__box {
            .box-content {
                margin-bottom: 0;
            }
        }
    }
}

.order-details {
    margin-top: 0 !important;

    .my-order-block {
        &__table {
            @media (max-width: 767px) {
                tbody {
                    tr {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }
        }
    }
}

.order-hash {
    padding-left: 10px;
}

@media only screen and (min-width:768px) {
    .box-account {
        .col-2,
        .col-2right {
            width: 48%;
        }
    }

    .my-order-block {
        td {
            padding: 3%;
        }
    }
}

.sidebar {
    display: none;
}

@media (min-width:768px) {
    .sidebar {
        display: block;
    }

}


.checkout-cart-index {
    .hide-paymentpage {
        display: block;
    }
}

.page-big-title {
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: $SohoGothicPro-Bold;
    font-weight: 700;
}

@media (min-width:768px) {
    .checkout-cart-index {
        .hide-paymentpage {
            display: none;
        }
    }

    .page-big-title {
        font-size: 36px;
        margin-top: 50px;
        margin-bottom: 60px;
    }

}

.cms-content {
    p {
        font-size: 14px;
    }
}

.cms-content .breadcrumbs,
.cms-page-view .breadcrumbs {
    display: none;
}

.cms-index-index {
    h1 {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 10px;
    }

    .sidebar {
        .block {
            margin: 0;
            border: none;
        }
    }
}

.cms-content {
    margin-top: 0;
    padding: 20px;

    h1, h2, h3, h4, h5,  h6 {
        font-family: $SohoGothicPro-Bold;
        font-weight:bold;
    }

    .title {
        line-height: 30px;
        margin-bottom: 20px;
    }

    a {
        color: $default-activeColor;
    }

    p {
        margin: 1% 0;
    }

    li, ul {
        font-size: 14px;
        list-style: disc inside;
        padding: 0;
        margin: 5px;
    }
}

@media (min-width:768px) {
    .cms-content {
        margin-top: 50px;
        padding: 0 0 0 70px;

        p {
            font-size: 18px;
        }

        li, ul {
            font-size: 18px;
        }

        .form-list {
            input {
                width: 50%;
            }

            label {
                margin: 1% 0;
                font-size: 14px;
            }
        }
    }
}

.control {
    margin: 2% 0;
}

.forgotpassword-page {
    .button, p {
        margin-top: 2%;
        font-family: $SohoGothicPro-Regular;
    }
}

.message-block {
    border: 1px solid $default-color;
    padding: 1% 0 25px 20px;
    margin-top: 20px;
    font-family: $SohoGothicPro-Regular;
    font-weight: 400;
    margin-bottom: 40px;
}


@media (min-width:768px) {
    .product-incart {
        margin-bottom: 15px;
    }

}

.products-grid {
    &__item {
        .product-detailing-top {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: -1;

            &.is-active {
                z-index: 1;
            }
        }

        .text-badge {
            color: #656565;
        }
    }
}

.cart-empty-block {
    padding: 4% 0;
    text-align: center;
    color: $black;
    
    .wrapper {
        max-width: 1245px;
        margin: 0 auto;
        h1 {
            font-weight: 700;
            font-size: px-to-rem(30);
            padding: 20px 10px;
            margin-bottom: 20px;
        }

        p {
            font-size: px-to-rem(16);
            margin-bottom: 20px;
            font-family: Arial, Helvetica, sans-serif;

            .oticon-main & {
                font-family: $SohoGothicPro-Regular;
            }

            a {
                color: $black;
                font-family: Arial, Helvetica, sans-serif;
                @include default-transition(color);
                text-decoration: underline;
                font-weight: bold;

                .oticon-main & {
                    color: $default-activeColor;
                    font-family: $SohoGothicPro-Bold;
                    text-decoration: none;
                }

                &:hover {
                    color: $black;
                    text-decoration: none;

                    .oticon-main & {
                        color: $color-weblinkMagenta;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .cart-empty-block {
        .wrapper {
            h1 {
                font-size: px-to-rem(50);
                padding: 20px;
            }

            p {
                font-size: px-to-rem(18);
            }
        }
    }
}

.product-incart {
    width: 100%;
    height: 40px;
    padding: 8px;
    background: $default-color;
    color: $color-grey;
    z-index: 1;
    text-align: center;
    display: block;

    span {
        display: inline-block;
        vertical-align: top;
    }

    .icon {
        background: $default-color;
        border: 1px solid $white;
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 5px;

        &::before {
            content: '\e60b';
            position: absolute;
            top: 4px;
            left: 4px;
            font-family: oticon-icons;
            color: $white;
        }
    }
}

.cart-block {
    overflow: hidden;
    padding: 10px 15px 10px;

    .product-name {
        font-size: 14px;
        font-family: $SohoGothicPro-Bold;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .short-description {
        display: block;
        font-family: $SohoGothicPro-Regular;
        font-size: 14px;
    }

    .item-options {
        font-size: 12px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;

        &:first-of-type {
            margin-bottom: 15px;
        }

        strong {
            font-weight: 400;
        }
    }

    .item-subtitle {
        display: block;
        font-weight: normal;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $color-border2;
        text-align: left;
        font-size: px-to-rem(12);

        &--center {
            text-align: center;
        }
    }

    table {
        width: 100%;
    }

    .table-header-group {
        display: none;
    }

    .cell-hide {
        display: none;
    }

    .info-mobile {
        text-align: center;
        font-size: 11px;

        .increment-block {
            margin: 10px 0;
        }

        .product-detailing {
            text-align: left;
        }
    }

    .product-image {
        display: block;
        text-align: center;
    }

    .btn-remove:not(.mobile) {
        display: block;
        width: 26px;
        height: 26px;
        background: url("../images/remove-icon.svg") no-repeat;
        margin: 0 auto;
    }

    .increment-wr {
        width: 100px;
        margin: 0 auto;

        @media (min-width: $breakpoint-medium) {
            overflow: hidden;
        }
    }

    .increment-input {
        float: left;
        width: 23px;
        height: 23px;
        cursor: pointer;
        font-size: 25px;
        text-align: center;
        margin: 0 5px;
        position: relative;
        background-size: contain;

        &__minus {
            background: url(https://shop.oticon.co.uk/skin/frontend/b2c_oticon/default/images/build/in-minus.png) no-repeat;
        }

        &__plus {
            background: url(https://shop.oticon.co.uk/skin/frontend/b2c_oticon/default/images/build/in-plus.png) no-repeat;
        }

        &__qty {
            color: $default-color;
            margin: 0;
            border: none;
            font-size: 14px;
        }
    }
}

@media (min-width:768px) {
    .cart-block {
        .product-name {
            font-size: 18px;
        }
    }
}

.cart-block .product-detailing .cart-price,
.cart-collateral .title, .discount label, .vat-block label strong {
    font-family: $SohoGothicPro-Bold;
    font-weight:bold;
}

@media (min-width:768px) {
    .cart-block {
        .table-header-group {
            display: table-header-group;
        }

        .cell-hide {
            display: table-cell;
        }
    }
}

.cart-table {
    thead {
        th {
            font-size: 12px;
            text-align: left;
            padding-bottom: 10px;
            font-weight: 400;

            &.center {
                text-align: center;
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid $color-dataTable;

            &:first-of-type {
                border-top: 1px solid $color-dataTable;
            }
        }

        td {
            padding: 20px 0;
        }
    }
}

.cart-collateral,
.shopping-cart {
    margin-bottom: 40px;
    width: 100%;
}

.shopping-cart {
    width: 100%;
}

@media (min-width:1245px) {
    .shopping-cart {
        width: calc(68% - 30px);
        float: left;
        margin-right: 30px;
    }
}

.cart-info {
    padding: 15px;
    background: $color-grey;

    > button {
        margin-top: 15px;
    }
}

.cart-collateral {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    .title {
        font-size: 18px;
        padding: 15px;
        background: #dcdcdc;
    }
}

@media (min-width:1245px) {
    .cart-collateral {
        flex-direction: column;
        width: 35%;
        float: left;
        max-width: 405px;
    }

}

.vat-block {
    margin-bottom: 20px;

    label {
        &::before {
            top: 14px;
        }

        strong {
            font-weight: 400;
            display: block;
            margin-bottom: 2px;
        }
    }

    a {
        color: $default-activeColor;
    }
}

.container {
    &::after {
        clear: both;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 744px;
    }

}

@media (min-width:992px) {
    .container {
        max-width: 970px;
        padding-left: 15px;
        padding-right: 15px;
    }

}

@media (min-width:1200px) {
    .container {
        max-width: 1170px;
        padding-left: 15px;
        padding-right: 15px;
    }

}

@media (max-width:767px) {
    .toolbar {
        .amount {
            line-height: 36px;
        }
    }

    .product-options dt label {
        margin-right: 10px;
    }
}

@media (max-width:991px) {
    .main-container {
        .col-main {
            .block-account {
                display: block;
                padding: 0;
                border: none;
                margin: 15px 0;
            }
        }
    }

    .my-account {
        .data-table {
            td.last {
                a {
                    display: block;
                }
            }
        }
    }

    .block-account {
        .block-title {
            padding-bottom: 0;
        }

        .block-content {
            border: none;

            ul {
                li {
                    font-size: 12px;
                }
            }
        }
    }

    #my-orders-table {
        font-size: 12px;
    }
}

@media (max-width:767px) {
    .cms-index-index ul.products-grid {
        margin-left: -20px;
    }

}

@media (max-width:767px) {
    .cart .totals {
        margin-bottom: 20px;
    }

    .cart-table {
        td.remove {
            width: 15px;
            padding-left: 0;
        }

        th {
            padding: 0 0 5px;
        }
    }
}

[class^="icon-"],
[class*=" icon-"] {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* cart css
---------------------------*/
.cart-block {
    overflow: hidden;
    padding: 10px 15px 10px;

    .product-name {
        font-size: 14px;
        font-family: $SohoGothicPro-Bold;
        font-weight: bold;
    }

    .short-description {
        display: block;
        font-family: $SohoGothicPro-Regular;
        font-weight: normal;
        font-size: 14px;
        margin-top: 3px;
    }

    .item-options {
        font-size: 12px;
        font-family: $SohoGothicPro-Regular;

        strong {
            font-weight: 700;
        }
    }

    table {
        width: 100%;
        font-family: $SohoGothicPro-Regular;
    }

    .table-header-group {
        display: none;
    }

    .cell-hide {
        display: none;
    }

    .info-mobile {
        text-align: center;
        font-size: 11px;
        margin-top: 10px;

        .increment-block {
            margin: 10px 0;
        }

        .product-detailing {
            text-align: left;
        }
    }

    .product-image {
        display: block;
        text-align: center;

        img {
            width: 132px;

            @media (max-width: 767px) {
                width: 135px;
            }
        }
    }

    .product-detailing {
        .cart-price {
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
            font-size: px-to-rem(14);
        }
    }

    .btn-remove:not(.mobile) {
        display: block;
        width: 26px;
        height: 26px;
        background: url("../images/remove-icon.svg") no-repeat center;
        margin: 0 auto;
    }

    .increment-wr {
        margin: 0 auto;
        overflow: hidden;
        width: auto;

        @media (max-width: 767px) {
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .increment-input {
        float: left;
        width: 23px;
        height: 23px;
        cursor: pointer;
        font-size: 25px;
        text-align: center;
        margin: 0 5px;
        position: relative;
        background-size: contain !important;

        &__minus {
            background: url("../images/in-minus.png") no-repeat;
        }

        &__plus {
            background: url("../images/in-plus.png") no-repeat;
        }

        &__qty {
            color: $default-color;
            margin: 0;
            border: none;
            font-size: 14px;
            font-family: $SohoGothicPro-Regular;
        }
    }

    .cart-table-flex {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 25px 0;

        @media (min-width: $breakpoint-medium) {
            flex-wrap: nowrap;
            padding: 25px;
        }

        .product-image {
            width: 45%;

            @media (min-width: $breakpoint-medium) {
                width: 240px;
                margin-right: 20px;
            }
        }

        .product-name-wrapper {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(55% - 15px);
            margin-left: 15px;

            @media (min-width: $breakpoint-medium) {
                width: 220px;
                margin-right: 20px;
                margin-bottom: 0;
                margin-left: 0;
            }
        }

        .product-name-color {
            margin-bottom: 20px;

            .item-subtitle {
                display: inline;
                padding-right: 5px;
            }
        }

        .product-name {
            margin-bottom: 10px;

            @media (min-width: $breakpoint-medium) {
                white-space: nowrap;
            }
        }

        .product-info {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            @media (min-width: $breakpoint-medium) {
                width: 100%;
                flex-wrap: nowrap;
                align-items: flex-end;
                justify-content: flex-end;
                position: relative;
            }

            .price-option,
            .btn-remove {
                width: 45%;
                margin: 0;
                margin-right: 15px;

                @media (min-width: $breakpoint-medium) {
                    width: auto;
                    margin: 0;
                }
            }

            .price-option {
                padding-bottom: 25px;

                @media (min-width: $breakpoint-medium) {
                    padding-bottom: 0;
                    margin-right: 70px;
                }
            }

            .price {
                white-space: nowrap;
            }

            .increment-block {
                @media (min-width: $breakpoint-medium) {
                    width: auto;
                    margin-right: 70px;
                }

                .item-subtitle {
                    @media (min-width: $breakpoint-medium) {
                        text-align: center;
                    }
                }

                .increment-wr {
                    justify-content: flex-start;
                    width: auto;
                    display: flex;
                    align-items: center;
                    min-height: 20px;

                    .increment-input {
                        margin: 0;

                        &__qty {
                            margin: 0 3px;
                        }
                    }
                }
            }

            .btn-remove {
                background-position: left;
                padding-left: 20px;
                height: auto;
                align-self: flex-end;
                text-align: left;
                font-size: px-to-rem(12);

                @media (min-width: $breakpoint-medium) {
                    position: absolute;
                    top: -5px;
                    right: -20px;
                    text-indent: -9999px;
                }
            }
        }
    }
}

@media (min-width:768px) {
    .cart-block {
        .product-name {
            font-size: 18px;
        }

        .table-header-group {
            display: table-header-group;
        }

        .cell-hide {
            display: table-cell;
        }
    }
}

.cart-table {
    tbody {
        tr {
            border-bottom: 1px solid $color-dataTable;
        }

        td {
            padding: 20px 16px;

            &:first-of-type {
                width: 180px;
                padding: 0;

                @media (max-width: 767px) {
                    width: 120px;
                }
            }

            &.product-info {
                padding-left: 0;
                padding-right: 0;
            }

            &.remove {
                position: relative;

                .btn-remove {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }
        }
    }

    thead {
        border-bottom: 1px solid $color-border2;

        th {
            font-size: 12px;
            text-align: left;
            padding-bottom: 10px;
            font-weight: normal;
            font-family: $SohoGothicPro-Regular;
            padding-left: 16px;
            padding-right: 16px;

            &.center {
                text-align: center;
            }
        }
    }

    &#shopping-cart-table {
        table-layout: fixed;
    }
}

.cart-collateral {
    max-width: 100%;

    .title {
        font-size: 18px;
        font-family: $SohoGothicPro-Bold;
        font-weight: bold;
        padding: 15px;
        background: #dcdcdc;
    }
}

.cart-stickybanner {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    background-color: $white;
    z-index: 99;

    @media (min-width: $breakpoint-medium) {
        display: none;
    }

    &.hidden {
        display: none;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        font-size: px-to-rem(16);
    }
}

@media (min-width:1245px) {
    .cart-collateral,
    .shopping-cart {
        margin-bottom: 25px;
    }

    .cart-collateral {
        width: 32%;
        float: left;
    }
}

.cart-info {
    padding: 15px;
    background: $color-grey;

    > button {
        margin-top: 15px;
    }
}

.discount {
    position: relative;
    margin-bottom: 20px;

    .coupon-box {
        margin-top: 10px;
        padding-left: 25px;
        display: flex;

        &::before,
        &::after {
            content: "";
            display: table;
        }

        &::after {
            clear: both;
        }
    }

    label {
        font-family: $SohoGothicPro-Bold;
        font-weight: bold;
    }

    input {
        font-size: 14px;
        padding: 8px 16px;
        width: 68%;
        margin-right: 2%;
        height: 48px;
    }

    .button {
        width: 30%;
        margin-top: 0;
        padding: 0px 20px 4px;

        &:nth-of-type(2){
            margin-left: 10px;
        }
    }
}

@media (min-width:768px) {
    .discount {
        input {
            padding: 12px 19px;
            height: 40px;
        }

        .button {
            margin-top: 0;
        }
    }
}

@media (max-width:767px) {
    .discount {
        .button {
            line-height: 1;
            padding: 12px 15px;
        }
    }
}

@media (max-width:767px) and (-webkit-min-device-pixel-ratio: 0) {
    .discount {
        input {
            font-size: 16px;
        }
    }
}

.vat-block {
    margin-bottom: 20px;

    label {
        &::before {
            top: 12px;
        }

        strong {
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
            display: block;
            margin-bottom: 2px;
        }
    }

    a {
        color: $default-activeColor;
    }
}

.link-wrapper {
    text-align: center;
}

.continue-link {
    display: inline-block;
    font-family: $SohoGothicPro-Medium;
    font-size: px-to-rem(16);
    position: relative;
    margin: 20px auto 3px;
    padding-left: 20px;
    color: $black;
    line-height: 24px;

    &::before {
        content: '\e603';
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        font-family: oticon-icons;
        font-size: 16px;
        font-weight: bold;
    }
}

.cart-shipping-disclaimer {
    font-size: 11px;
}

#shopping-cart-totals-table {
    tr {
        td {
            text-align: right;
            line-height: 24px;
            width: 200px;

            &:first-child {
                text-align: left;
            }

            &.a-right {
                strong {
                    font-weight: bold;
                }

                &.total {
                    padding-top: 20px !important;
                    font-size: 18px;
                }
            }
        }
    }

    tfoot {
        td {
            padding-top: 20px;
            font-size: 18px;
            font-family: $SohoGothicPro-Bold;
        }
    }
}

.showcart {
    font-family: $SohoGothicPro-Medium;

    .sum {
        display: inline-block;
        white-space: nowrap;
    }

    .viewing {
        text-align: left;
    }

    .mini-products-list {
        .product-image {
            width: inherit !important;
            text-align: left !important;
            display: inline-block !important;
        }

        .product-details {
            .product-name {
                a {
                    display: inherit !important;
                    text-align: left;
                    color: inherit;
                    margin-bottom: -2px;
                    padding-bottom: 0;
                }
            }
        }

        .btn-remove {
            display: inherit !important;
            text-align: left;
        }
    }

    .short-description {
        font-weight: 400;
        font-family: $SohoGothicPro-Regular;
    }

    span {
        &.price {
            font-family: $SohoGothicPro-Regular;
            font-weight: 400;
        }
    }

    .item-options {
        li {
            font-weight: 300;

            span {
                margin-left: 4px;
            }
        }
    }

    .btn-remove {
        color: $default-color;

        &:hover {
            color: $default-activeColor;
        }
    }
}

.headercart {
    &__top {
        &.open {
            #recently-added-container {
                visibility: visible;
            }
        }
    }
}

.catalog-category-view {
    .menu-link {
        color: $default-color;

        &:hover {
            color: $default-color;
        }
    }

    .category-products {
        padding: 40px 15px 49px 15px;
    }
}

@media (min-width: 768px) {
    .catalog-category-view {
        .category-products {
            padding: 40px 0 49px 0;
        }
    }
}


@media (min-width: 1265px){
	.catalog-category-view {
        .button {
            padding: 0 10px 1px;
        }
	}
}

.checkout-onepage-index {
    .checkout-container {
        input[type=radio]+label {
            font-weight: bold;
        }

        .page-big-title {
            margin-bottom: 58px;
        }
    }

    select {
        width: 100%;
        font-size: 14px;
        padding: 11px 42px 15px 10px;
        border: 1px solid $black;
        font-family: $SohoGothicPro-Regular;
        font-weight: 400;
        color: $default-color !important;
    }

    .content-container {
        padding: 15px 15px;
    }

    .col-main {
        padding: 0 15px;
    }

    .button-addToCart {
        padding: 0 10px 4px;
    }

    #opc-shipping {
        margin-top: -22px;
    }
}

#recently-added-container {
    .item {
        &.last {
            .button {
                a {
                    font-size: 14px;
                    color: $color-borderButton;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }
}

.catalog-category-searchresult, .favourites {
    .message-block {
        padding-top: 20px;
        margin-top: 58px;
        margin-bottom: 28px;
    }

    .col1-layout {
        padding: 0 15px;
    }

    .results-view {
        padding-bottom: 35px;
    }

    .toolbar {
        .pagers-block {
            .sort-by {
                select {
                    font-size: 14px;
                    padding: 11px 30px 15px 10px;
                    border: 1px solid $black;
                    font-family: $SohoGothicPro-Regular;
                    font-weight: 400;
                    color: $default-color !important;
                }
            }
        }
    }
}

.product-detailing-top {
    .product-incart {
        .text {
            margin-top: 0;
        }
    }
}

.catalog-category-view {
    .category-description {
        &__title {
            p {
                margin-bottom: 20px;
            }
        }
    }
}

header .links.static-links li strong span {
    font-size: x-large;
}

.cms-index-index {
    .category-description {
        &__title {
            .button {
                padding: 0 10px 4px;
            }
        }
    }
}

.text-link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.viewing li .button {
    padding: 7px 10px 7px;
}

.catalog-category-searchresult
.cms-index-index .col1-layout .wrapper.testimonial {
    padding: 0 15px;
}

@media (min-width:1265px) {
    .cms-index-index .col1-layout .wrapper.testimonial {
        padding: 0;
    }
}


@media (max-width:767px) {
    .catalog-category-view .category-description .category-description__img {
        margin-top: 40px;
    }

    .catalog-category-searchresult .col1-layout .message-block {
        padding: 10px 0 15px 20px;
    }
}

@media (max-width:440px) {
    .catalog-category-view .category-products .col-4 {
        width: 100%;
        margin: 0 0 30px 0;
    }

    .catalog-category-view .category-products {
        padding: 74px 15px 45px;
    }

    .results-view .category-products .products-grid__item {
        width: 100%;
        /*margin: 3% 0 1.5% 3%;*/
    }

    .catalog-category-searchresult .col1-layout .message-block {
        margin-bottom: 24px;
    }

    .category-products .pagers-block {
        margin-bottom: 0;
    }

}

/* Checkout page
-----------------------------------------------------*/
#click-here-to-show-login,
.back-link {
  font-family: $SohoGothicPro-Regular;
  color: $color-weblinkMagenta;
  @include default-transition(color);

  &:hover {
    color: $default-activeColor;
  }
}
.checkout-onepage-index .hide-paymentpage {
  display: block;
}

#checkout-step-billing .button-set,
#checkout-step-shipping .button-set,
#opc-review h3 {
  display: none;
}

#opc-shipping label.css-label {
  padding-left: 30px;
}

#checkout-step-shipping .content-container {
  padding-top: 0;
  padding-bottom: 25px;
}

.back-link {
  font-family: $SohoGothicPro-Bold;
  position: absolute;
  top: 30px;
  left: 0;
}

.checkout-container {
  position: relative;
  margin-bottom: 55px;

    .section {
        width: 100%;
        margin-bottom: 10px;

        &--summary {
            display: flex;
            flex-direction: column;

            li {
                &:nth-of-type(1) {
                    order: 2;
                }

                &:nth-of-type(2) {
                    order: 1;
                    margin-bottom: 13px;
                }
            }
        }

        dl {
            margin: 0;
        }

        .messages {
            li {
                margin-bottom: 0;
            }
        }
    }

    select {
        font-size: 14px;
    }
}

.validate-select {
    &:hover {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url(../images/select-hover-down.svg) no-repeat center right;
        background-color: $white;
        background-size: 29px;
    }
}

.klarna-checkout {
    .payment-summary{
        display: flex;
    }
    .authentication-wrapper {
        text-align: right;
        margin: 25px;
        .login-box {
            border: 1px solid #ccc;
            padding: 25px;
        }
    }
    .klarna-wrapper {
        width: 66.66666667%;
        margin-top: 20px;
    }
}

@media (min-width:768px) {
  .checkout-onepage-index .hide-paymentpage {
        display: none;
    }

    .checkout-container {
        .section {
            float: left;
            margin-bottom: 15px;

            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }
}

@media (min-width:1245px) {
    .checkout-container {
        .one-page-checkout {
            display: flex;

            .section {
                max-width: 405px;

                &--summary {
                    li {
                        &:nth-of-type(1) {
                            order: 1;
                        }
    
                        &:nth-of-type(2) {
                            order: 2;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

#qc-loginform {
  margin-top: 15px;
}

#login-form,.checkout-step>li {
  margin-bottom: 2%;
}

.forgot-password-link {
  display: block;
  color: $default-activeColor;
  margin-top: 60px;
}

#click-here-to-show-login span {
  color: $default-color;
  font-family: $SohoGothicPro-Regular;
  font-weight: 400;
}

#login-form button {
  float: right;
  width: 28%;
  margin-top: 0;
  font-size: 12px;
}

.checkout-step {
    p {
        padding: 2% 0;
    }

    label {
        padding: 10px 0;
        font-family: $SohoGothicPro-Regular;

        &.css-label {
            padding-left: 30px;
            font-weight: bold;

            &::before {
                background: transparent;
            }
        }

        & + span {
            font-size: 11px;
        }
    }

    input {
        width: 100%;
        height: 40px;
        padding: 0 10px;
    }

    input[type=radio] {
        width: auto;
    }

    input[type=password] {
        width: 70%;
        float: left;
    }

    input[type=checkbox] {
        &.css-checkbox:checked {
            & + label.css-label {
                &::before {
                    top: 50%;
                }
            }
        }
    }

    .error {
        float: left; width: 100%;
    }

    > li {
        &::after,
        &::before {
            content: "";
            display: table;
        }

        &::after {
            clear: both;
        }
    }

    .name-firstname,
    .name-lastname {
        overflow: hidden;
    }

    &__title {
        background: #dcdcdc;
        padding: 15px;
    }
}

.checkout-step__title span.text,.table-condensed tr.last td {
  font-size: 18px;
  font-family: $SohoGothicPro-Bold;
  font-weight: bold;
}

@media (min-width:768px) {
    .checkout-step {
        input {
            height: 50px;
        }
    }
}

.content-container {
  padding: 0 15px 25px;
  background: $color-grey;
  position: relative;
  min-height: 100px;
}

#co-shipping-method-form {
  padding: 15px 15px 0;
}

#opc-shipping_method .content-container {
  padding: 0 0 15px;
}

.table-condensed {
    width: 100%;

    .a-right {
        text-align: right;
        font-family: $SohoGothicPro-Regular;
    }

    tr {
        td {
            line-height: 24px;

            &:first-child {
                font-family: $SohoGothicPro-Regular;
                text-align: left;
                padding-right: 10px;
            }
        }

        &.last {
            td {
                padding-top: 20px;
            }
        }
    }

    .last {
        .a-right {
            strong {
                font-family: $SohoGothicPro-Bold;
                font-weight: bold;
            }
        }
    }
}

.button-set {
  position: relative;
  padding-top: 15px;
}

#checkout-payment-method-load dd div {
  display: none;
}

@media only screen and (min-width:768px) {
  input {
    padding: 4% 3%;
  }
}

#co-payment-form {
  position: relative;
}

.qc-shipping-choice .shipTo-address {
  font-weight: bold;
}

@media (max-width: 767px) {
    .back-link {
        top: 10px;
        left: 0;
    }

    .checkout-onepage-index {
      .login-form-validate {
          .button {
            line-height: 30px;
            padding: 2px 10px 4px;
          }
        }
    }
}


@media (max-width: 1244px) {
	#checkout-step-billing #login-form{
        margin-bottom: 0;
    }

	#opc-payment,
	#opc-billing,
	.checkout-container {
        .section{
            margin-bottom: 25px;
        }
    }

	#opc-billing {
        .checkout-step {
            &__title{
                padding-top: 30px;
                padding-bottom: 35px;
            }
        }
    }
	.checkout-onepage-index {
        .content-container {
            padding-top: 24px;
            padding-bottom: 6px;
        }
    }
	#checkout-step-shipping {
        .content-container {
            padding-top: 23px;
            padding-bottom: 38px;
        }
    }

	#checkout-step-review {
        .content-container {
            padding-bottom: 40px;
        }
    }
}

@media(max-width: 767px) {
	.menu-standard-navigation {
        padding-bottom: 0;
    }
	.checkout-onepage-index {
        .col1-layout {
            .checkout-container {
                margin-bottom: 42px;

                .page-big-title {
                    margin-top: 45px;
                }
            }
        }
    }

	.forgotpassword-page {
        .wr-block {
            .button {
                width: 100%;
            }
        }
    }

	.br-small {
        .static-links {
            > li {
                padding-right: 0;

                &::before {
                    margin-top: 5px;
                }
            }
        }
    }

	.header {
        .links {
            > li {
                a {
                    display: block;
                }
            }
        }

        &__menu {
            li.first {
                a.menu-link {
                    padding-bottom: 25px;
                }
            }
        }
    }
}

@media(max-width: 440px){
	.cms-index-index {
        .category-description {
            &__title {
                &.home-banner-section {
                    padding-bottom: 0px !important;
                }
            }
        }
    }

	.icon-add-to-cart:before {
        top: 11px;
    }
}

.oticon-address {
    .col2-left-layout {
        padding: 0 15px;

        .sidebar {
            width: 30%;
            float: left;
            margin-right: 1%;
            margin-bottom: 3%;
        }

        .col-main {
            width: 69%;
            float: left;
            margin-bottom: 59px;
        }
    }

    .welcome-msg {
        margin-top: 20px;
        margin-bottom: 50px;

        p {
            font-size: 18px !important;
            width: 50% !important;
            // font-size: 16px;
            // width: 100%;
            font-family: $SohoGothicPro-Light;
            font-weight: 300;
        }

        h2 {
            font-size: 42px;
            line-height: 42px;
            font-family: $SohoGothicPro-Bold;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }

    .header {
        &__menu {
            li.first {
                margin-bottom: 0; display: block !important; position: relative; flex-wrap: wrap; position: relative;
            }
        }
    }

    .account-wr,
    .block-account {
        margin-top: 45px;
    }

    .block-account {
        &__content {
            li {
                padding-left: 4%;

                &.current {
                    border-left: 1px solid $default-activeColor;
                }
            }

            a {
                line-height: 30px;
                color: $color-borderButton;
                font-family: $SohoGothicPro-Medium;
                font-weight: 500;

                strong {
                    font-weight: 500;
                    color: $default-color;
                }
            }
        }
    }

    .cms-content,
    div.my-account {
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 1px;
            height: 100%;
            background: #e5e5e5;
            position: absolute;
            top: 5px;
            left: 0;
        }
    }

    .account-wr {
        padding: 0 20px 20px;

        h2 {
            font-size: 30px;
            line-height: 30px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        h3 {
            margin-bottom: 10px;
        }

        .messages {
            max-width: 392px;

            .error-msg {
                padding: 8px;
            }
        }

        .box-account {
            &__box {
                padding-top: 0;
            }
        }
    }

    input {
        padding: 1%;
    }

    .form-list {
        label {
            margin: 10px 0;
            font-size: 12px;
        }

        input,
        select {
            width: 100%;
            max-width: 392px;
            height: 50px;
            padding: 10px 10px 15px 10px;
            color: $default-color;
        }

        .custom-select {
            width: 100%;
            max-width: 392px;
        }
    }

    .address-book {
        .form-list {
            input {
                height: 50px;
                padding: 10px 10px 15px 10px;
            }
        }

        h2.legend {
            margin-top: 20px;
        }

        .selectboxit-container {
            .selectboxit {
                width: 392px!important;
            }
        }
    }

    .selectboxit-container {
        position: relative;
        display: inline-block;
        vertical-align: top;

        .selectboxit {
            width: 200px;
            cursor: pointer;
            margin: 0;
            padding: 0;
            overflow: hidden;
            display: block;
            position: relative;
        }

        .selectboxit-options a {
            height: 50px;
            line-height: 50px;
        }

        span {
            height: 50px;
            line-height: 50px;
        }
    }

    .selectboxit {
        .selectboxit-option-icon-container {
            margin-left: 5px;
        }
    }

    .selectboxit-option-icon-container {
        float: left;
    }
}

.oticon-address .account-login:after,
.oticon-address .account-wr .scaffold-form,
.oticon-address .my-order-block .pager:after {
    clear: both;
}

.oticon-address .wrap-select,
input {
    border: 1px solid $color-borderInput;
    color: $default-color;
}

.oticon-address .header__menu li a.menu-link:hover {
    color: $black;
}

.form-validation-errors {
    color: $orange;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.logout-page {
    .wr-success {
        padding: 3%
    }
}

.flex-wrapper {
    display: flex;
    justify-content: center;

    @media (max-width: 991px) {
        flex-direction: column;
    }

    &__box-50 {
        width: 50%;

        @media (max-width: 991px) {
            width: 100%;
        }

        &:first-of-type {
            margin-right: 24px;

            @media (max-width: 991px) {
                margin-right: 0;
                margin-bottom: 24px;
            }
        }
    }
}

.input-container,
.select-container {
    display: flex;
    flex-direction: column-reverse;

    &--rev {
        flex-direction: column;
    }
}

.radio-input-container {
    &:first-of-type {
        margin-bottom: 16px;
    }

    input[type=radio] {
        &:checked {
            + label {
                border: 1px solid $black;
            }
        }
        & + label {
            padding: 16px;
            padding-left: 42px !important;
            background-color: $white;
            border: 1px solid #dcdcdc;
            font-size: px-to-rem(14);

            span {
                left: 16px;
                top: 18px;
            }
        }
    }
}

.termsAndConditionsCheckbox {
    margin-top: 20px;
    a {
        text-decoration: underline;
    }
    .validation-advice, .footer-validation-advice {
        padding-left: 30px;
    }
}

.form-list {
    li {
        display: flex;
        flex-direction: column-reverse;
    }
}

.swiper-slide {
    height: auto !important;
    align-self: stretch;

    .product-list-slider__item{
        height: 100%;
        &-wrapper {
            height: 100%;

            .product-incart{
                position: absolute;
                z-index: 1;
                background: rgba(255, 255, 255, 0.95);
            }
        }
    }
}

.popup-content{
    width: auto !important;
    max-width: 50%;
    border: 1px solid $color-border;
    padding: 15px !important;

    .content {
        width: 100%;
        padding: 10px 10px;
    }

    .actions {
        width: 100%;
        padding: 10px 5px;
        margin: auto;
        text-align: center;

        button {
            max-width: 45%;
            margin: 5px;
            display: inline-block;
        }
    }
    .close {
        position: absolute;
        display: none;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: $color-white;
        border-radius: 50%;
        outline: none;
        border: 1px solid $color-border;
      }
}

.pagination {

    li {
        &.disabled {
            opacity: .6;
        }

        a {
            float: left;
            padding: 8px 16px;
            text-decoration: none;
            border-radius: 5px;
            border: 1px solid $lightgrey; 
        
            &.active {
                background-color: $default-color;
                color: white;
            }

            &:not(.active):hover {
                background-color: #ddd;
            }
        
            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                margin-right: 10px;
              }
              
             &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                margin-left: 10px;
              }
          }
    }

}

.newsletter-page{
    max-width: 600px;
    text-align: center;
    padding: 40px 0;
    justify-content: center;
    margin: auto;

    .custom-checkbox{
        &__checkmark {
            border: 1px solid $color-border !important;
        }
    }

    p {
        margin: 30px 0;
    }
}