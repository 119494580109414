.payment-methods {
    margin-top: 24px;

    @media (max-width: 1244px) {
        margin-top: 0;
        margin-bottom: 24px;
    }

    &__label {
        margin-bottom: 12px;
        color: $black;

        @media (max-width: 1244px) {
            margin-bottom: 15px;
        }

        &--bold {
            font-family: $SohoGothicPro-Bold;
        }
    }

    &__icon {
        border-radius: 4px;
        display: inline-block;
        margin-bottom: 12px;
        overflow: hidden;

        &:not(:last-of-type) {
            margin-right: 12px;
        }
    }
}