.header {
    display: none;
    position: sticky;
    top: 0;
    z-index: 1000;

    .oticon-main &,
    .phonicear-main &,
    .horselbutik-main &,
    .bernafon-main & {
        display: block;
    }

    &-dark {
        background: $color-background;
        background: $default-gradient;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f3f3', endColorstr='#dddddd', GradientType=1);
    }

    &-light {
        background: $color-white 0 0;
        padding-bottom: 0;
    }
    &:after,
    &:before {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    &__top {
        margin-bottom: 30px;
    }

    .header-bottom-links {
        display: flex;
        align-items: center;
        justify-content: center;

        &__list-item {
            position: relative;
            padding-bottom: 12px;

            &:not(:last-of-type) {
                margin-right: 32px;
            }

            > ul {
                display: none;
            }

            &:hover {
                > ul {
                    display: block;
                }
            }
        }

        &__list-link {
            font-weight: normal;
            font-size: px-to-rem(16);
            color: $black;
            text-decoration: none;
        }

        &__lvl2 {
            display: flex;
            position: absolute;
            z-index: 1;
            top: 32px;
            background-color: $white;
            box-sizing: content-box;
            left: -20px;

            a {
                font-size: px-to-rem(16);
                font-weight: normal;
                color: $black;
                text-decoration: none;
            }
        }

        &__lvl2-item {
            padding: 10px 20px 10px 18px;
            border-left: 2px solid transparent;
            @include default-transition(all);
            white-space: nowrap;

            &:last-of-type {
                padding-bottom: 10px;
            }

            a {
                padding: 0;
            }

            &:hover {
                background-color: #F4F3FA;
                border-left: 2px solid $default-color;
            }
        }
    }

    .links {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $color-borderMenu;

        & > li {
            display: inline-block;
            position: relative;
            padding-top: 0;

            a {
                font-size: 12px;
                padding-left: 30px;
                position: relative;
                font-family: $SohoGothicPro-Medium;
                font-weight: 500;
            }

            > a {
                &:first-of-type {
                    &:before {
                        content: '\e721';
                        position: absolute;
                        top: -2px;
                        left: 0;
                        font-size: 20px;
                        font-family: oticon-icons;
                        color: $lightgrey;

                        @media (max-width: 767px) {
                            font-size: 24px;
                        }
                    }
                }
            }

            &.customer-service {
                > a {
                    &:first-of-type {
                        &:before {
                            content: '\E024';
                            font-family: iconfont;
                        }
                    }
                }
            }

            ul li {
                display: block;
            }

            .header-link-container {
                > ul {
                    display: none;
                }
            }
        }

        .first {
            a {
                font-weight: 400;
            }
        }
    }

    &__menu {
        width: 100%;
        padding: 2% 2% 100px;
        position: relative;
        background: $default-color;
        opacity: .99;
    
        li {
            position: relative;
            cursor: pointer;
    
            a {
                color: $color-footer;
                font-family: $SohoGothicPro-Medium;
                font-weight: 500;
                position: relative;
                display: inline-block;
            }
    
            .sub-menu {
                display: none;
                margin-left: 20px;
                position: relative;
    
                &::after,
                &::before {
                    bottom: 100%;
                    left: 12%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }
    
                &.mobile-show {
                    display: block;
                    background: $black;
                    margin: 10px -2% 0;
                    padding: 0 2% 2%;
    
                    li {
                        padding-top: 10px;
    
                        a {
                            font-family: $SohoGothicPro-Light;
                            font-weight: 300;
                            font-size: 12px;
                        }
                    }
                }
    
                li {
                    padding: 20px 0 0;
    
                    a {
                        font-size: 14px;
                        padding-left: 7px !important;
                    }
    
                    &.active {
                        a {
                            color: $white;
    
                            &::before {
                                background: 0 0;
                            }
                        }
                    }
                }
            }
        }
    
        .toggle-sub-menu {
            display: inline-block;
            color: $color-footer;
            background: 0 0;
            border: none;
            width: 14px;
            height: 14px;
            padding: 0;
            margin-left: 5px;
            outline: 0;
    
            .icon-minus,
            .icon-plus {
                display: block;
                width: 14px;
                height: 14px;
                position: relative;
    
                &::before {
                    content: '\e601';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 14px;
                    height: 14px;
                    font-size: 14px;
                    font-family: oticon-icons;
                    margin-top: 2px;
                }
            }
    
            .icon-minus {
                color: $default-activeColor;
            }
        }
    }
}

.deskstop-login {
    margin-top: 12px;
}

@media (max-width:767px) {
    .header {
        .links {
            .first {
                a {
                    font-weight: 500;
                    padding-left: 40px;
                    font-size: 13px;
                }
            }
        }

        > .hide-paymentpage.mobile-wr {
            display: none;
        }
    }
}

.headercart {
    position: relative;
    float: right;
    padding-top: 30px;
    margin-left: 20px;

    &__top {
        cursor: pointer;
        position: relative;

        .headercart-info {
            &.is-loading {
                opacity: .4;
            }
        }

        .headercart-disabled{
            pointer-events: none;
        }

        .headercart-info, .mini-products-list {
            cursor: initial;
        }

        a {
            display: block;
            text-align: center;
            position: relative;
            width: 100%;
            font-size: 11px;
            color: $default-activeColor;
            font-family: $SohoGothicPro-Regular;
            vertical-align: top;

            .icon-top-cart {
                display: inline-block;
                width: 23px;
                height: 25px;

                &::before {
                    content: '\e684';
                    display: block;
                    position: absolute;
                    top: 1px;
                    left: 0;
                    font-size: 20px;
                    font-family: oticon-icons;
                }
            }

            .btn-remove{
                cursor: pointer;
            }
            
            span {
                line-height: 34px;
            }
        }
    }
}

@media (min-width:1245px) {
    .headercart {
        margin-left: 45px;
    }

}

.headercart-inner {
    margin-top: 25px;
    background: $white;
    position: relative;
    margin-bottom: 34px;
    box-shadow: 7px 7px 16px -7px rgba(0, 0, 0, .8);

    &::after,
    &::before {
        bottom: 100%;
        right: 12%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(64, 64, 64, 0);
        border-bottom-color: $default-color;
        border-width: 10px;
        margin-left: -10px;
    }

    .mini-products-list {
        padding: 15px;
        max-height: 50vh;

        li {
            .item {
                border-bottom: 1px solid #e1e1e1;
                overflow: hidden;
                padding-bottom: 15px;
                margin-bottom: 15px;

                &:first-child {
                    padding-top: 15px;
                    border-top: none;
                }

                &.last {
                    border: none;
                }
            }

            &.item {
                position: relative;
                
                &--shipping-price {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 10px;
                    padding-bottom: 20px;
                    font-size: px-to-rem(12);
                }
            }
        }

        .button {
            font-size: 14px;
        }
    }

    .item-options {
        margin: 4px 0;
        font-family: $SohoGothicPro-Light;
        font-weight: 300;
    }

    .product-details {
        width: 190px;
        font-size: 11px;
        padding-left: 15px;
        display: inline-block;
    }

    .product-details-lower {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .product-name {
        font-family: $SohoGothicPro-Bold;
        font-weight: bold;
        margin: 0 0 2px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        span {
            display: block;
            font-family: $SohoGothicPro-Regular;
            margin-top: 12px;
        }
    }

    .btn-remove {
        font-size: 11px;

        &::hover {
            color: $default-activeColor;
        }
    }
}

.headercart-button {
    text-align: center;
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -30px;
        left: 0;
        width: calc(100% - 10px);
        height: 30px;
        background: linear-gradient(0deg, $white 32%, transparent 70%)
    }

    .button {
        display: inline-block;
        width: calc(100% - 30px);
        margin: 15px;
    }
}

.mobile-header {
    width: 100%;
    height: 60px;
    background: $default-color;
    padding: 1% 2%;
    position: relative;

    #recently-added-container {
        display: none !important;
    }

    .headercart {
        height: 100%;
        background: $default-activeColor;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        padding: 10px;
        margin: 0;

        &__top {
            a {
                color: $white;

                &::before {
                    font-size: 23px;
                    left: 10px;
                }

                span {
                    display: none;

                    &.headercart-items,
                    &.icon-top-cart {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

@media (max-width:440px) {
    .mobile-header {
        padding: 2% 3%;
    }

}

.logo-min {
    width: 80px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -40px;
    z-index: 1;
}

.phone-menu {
    float: left;
    position: relative;
    z-index: 10;
    margin-top: 8px;

    a {
        display: block;
        width: 32px;
        height: 19px;
        font-size: 12px;
        color: $white;

        .sr-only {
            display: block;
            margin-top: 4px;
            font-family: $SohoGothicPro-Regular;
            text-indent: -9999px;
            visibility: hidden;
            margin: 0;
            font-size: 0;
        }

        &.active {
            background: url(../../../images/close-icon.png) 50% no-repeat;
            margin-top: 5px;

            span {
                display: none;
            }
        }
    }
}

.hamburger {
    .bread {
        display: block;
        width: inherit;
        height: 2px;
        border-radius: 1px;
        background-color: $white;
        margin-top: 6px;
    }
}

.menu-standard-navigation {
    padding-bottom: 30px;
    margin-bottom: 15px;
    border-bottom: 1px solid $color-borderMenu;

    > li {
        > a {
            padding: 20px 0 0;
        }
    }
}

@media (min-width:768px) {
    .menu-standard-navigation {
        border: none;
        float: left;
        min-width: 400px;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        clear: both;

        > li {
            > a {
                &.over {
                    color: $black;
                }
            }
        }
    }
}

@media (min-width:768px) {
    .header {
        &__menu {
            opacity: 1;
            display: block !important;
            padding: 0;
            margin-top: 5px;

            li {
                float: left;
                padding: 15px 12px;
                border: none;

                &.first {
                    padding-left: 0;
                    margin-bottom: 40px;
                }

                &.active {
                    > a {
                        color: $default-color;

                        &:before {
                            content: '';
                            display: block;
                            background-color: $default-activeColor;
                            width: 15%;
                            height: 2px;
                            position: absolute;
                            bottom: -12px;
                            left: 50%;
                            margin-left: -7%;
                        }
                    }
                }

                a {
                    color: $color-borderButton;
                    padding: 0 !important;
                }

                .sub-menu {
                    width: 200px;
                    position: absolute;
                    top: 51px;
                    left: 0;
                    margin-left: 0;
                    z-index: 1000;
                    padding: 20px 18px;
                    background: $default-color;
                    color: $white;

                    &::after,
                    &::before {
                        border-color: rgba(64, 64, 64, 0);
                        border-bottom-color: $default-color;
                        border-width: 10px;
                        margin-left: -10px;
                    }

                    li {
                        padding-left: 0;

                        &:first-child {
                            padding-top: 0;
                        }

                        &.over {
                            .sub-menu {
                                display: block;
                            }
                        }
                    }
                }
            }

            .toggle-sub-menu {
                display: none;
            }
        }

        .links {
            border-bottom: none;
            float: right;
            padding-top: 25px;
            margin-left: 20px;
            padding-bottom: 0;
            margin-bottom: 0;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}

@media (max-width:990px) {
    .header {
        .links {
            margin-left: 8px;

            > li {
                a {
                    padding-left: 24px;
                }
            }
        }
    }
}


@media (min-width:1245px) {
    .menu-standard-navigation {
        min-width: 600px;
    }

    .header {
        .links {
            margin-left: 45px;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}