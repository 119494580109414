.intro-banner {
  background: $white;
  color: $default-color;
  text-align: center;

  input {
    width: 100%;
    max-width: 280px;
    background-color: transparent;
    border-bottom: 2px solid #fff !important;
    color: #fff !important;
  }

  .footer-validation-advice {
    color: $error-color !important;
  }

  @media (min-width: $breakpoint-medium) {
    background: $default-gradient;
    text-align: left;
  }

  &__heading {
    font-size: px-to-rem(32);
    font-family: $SohoGothicPro-Bold;
    border-bottom: 2px solid $white;
    padding-bottom: 15px;

    @media (min-width: $breakpoint-medium) {
      font-size: px-to-rem(48);
    }
    &.nested {
      font-size: px-to-rem(30) !important;
      border-bottom: none;

      @media (min-width: $breakpoint-medium) {
        font-size: px-to-rem(38) !important;
      }
    }
  }

  &__text {
    font-size: px-to-rem(16);
    margin: 25px 0;

    &:last-of-type {
      margin-bottom: 25px;
    }

    @media (min-width: $breakpoint-medium) {
      font-size: px-to-rem(20);
      margin: 20px 0;
      line-height: 30px;
    }

    &.nested {
      font-size: px-to-rem(14);
      line-height: 20px;
    }
  }

  &__left {
    max-width: calc(100% - 40px);
    order: 2;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 40px;
    box-shadow: 0px 0px 8px #e9ecef;
    background-color: $white;
    margin-top: -140px;
    margin-bottom: 60px;
    position: relative;

    @media (min-width: $breakpoint-medium) {
      order: 1;
      max-width: 500px;
      padding: 65px 0 70px;
      padding-right: 60px;
      box-shadow: none;
      background-color: transparent;
      margin-top: 0;
      margin-bottom: 0;
      height: px-to-rem(547);

      &.nested {
        padding: 45px 60px 45px 0px;
      }
    }
  }

  &__disclaimer {
    bottom: 5px;
    font-size: 12px;
    font-style: italic;
    @media (min-width: $breakpoint-medium) {
      position: absolute;
    }
  }

  &__right {
    width: 100% !important;
    order: 1 !important;
    height: 375px !important;

    @media (min-width: $breakpoint-medium) {
      width: calc(100% - 500px) !important;
      order: 2 !important;
      height: auto !important;
    }
  }

  &__flex-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-medium) {
      flex-wrap: wrap;
      flex-direction: row;
    }

    &.nested-component {
      &__heading {
        font-size: px-to-rem(25) !important;
      }
    }
  }

  .button {
    width: auto;
    padding: 10px 30px;
    line-height: normal;
    height: auto;

    max-width: 80%;
    margin: 0 auto;

    /* one item */
    &:only-of-type {
      min-width: 170px;
    }

    /* two items */
    &:first-of-type:nth-last-of-type(2),
    &:first-of-type:nth-last-of-type(2) ~ button {
      min-width: 170px;
    }

    /* three items */
    &:first-of-type:nth-last-of-type(3),
    &:first-of-type:nth-last-of-type(3) ~ button {
      min-width: 130px;
    }

    &:not(:last-of-type) {
      margin-bottom: 25px;
    }

    @media (min-width: $breakpoint-medium) {
      max-width: none;
      margin: 0;

      &:not(:last-of-type) {
        margin-right: 25px;
        margin-bottom: 0;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: $default-color;
  }

  .swiper-pagination-bullet-active {
    color: $default-color;
  }

  .custom-checkbox {
    &__checkmark {
      @media (max-width: $breakpoint-medium) {
        border: 1px solid #24405b !important;
      }
    }
  }
}
