.horselbutik-main {
    $default-color: #645AA0 !global;
    $default-activeColor: #647A84  !global;
    $default-buyButtonColor: #E9556E !global;
    $default-buttonColor: #645AA0 !global;
    $default-inputColor: #C8D2D7 !global;
    $default-labelColor: #96A5AF !global;
    $default-backgroundColor: #E6EBEB !global;
    $error-color: #EB5757 !global;
    $checkbox-stroke: #24405B !global;
    $light-grey: #E9ECEF !global;
    $header-border: #EAEDEF !global;
    $input-background: #F4F3FA !global;

    $product-boxshadow: 0px 0px 8px $light-grey !global;
    $product-boxshadow-hover: 0px 0px 40px rgba(145, 159, 173, 0.2) !global;

    //fonts
    $SourceSansPro-ExtraLight: 'SourceSansPro-ExtraLight', 'Arial', sans-serif !global;
    $SourceSansPro-Light: 'SourceSansPro-Light', 'Arial', sans-serif !global;
    $SourceSansPro-Regular: 'SourceSansPro-Regular', 'Arial', sans-serif !global;
    $SourceSansPro-SemiBold: 'SourceSansPro-SemiBold', 'Arial', sans-serif !global;
    $SourceSansPro-Bold: 'SourceSansPro-Bold', 'Arial', sans-serif !global;
    $SourceSansPro-Black: 'SourceSansPro-Black', 'Arial', sans-serif !global;
}