.horselbutik-main {
    .react-tabs {
        padding-bottom: 70px;
        &__tab-list {
            margin-bottom: 0 !important;
            border-bottom: none !important;
        }

        &__tab {
            border: 1px solid $default-inputColor !important;
            border-radius: 0px !important;
            padding: 10px 30px !important;
            font-size: .875rem;
            font-family: $SourceSansPro-SemiBold;

            &:not(:last-of-type) {
                border-right: none !important;
            }

            &:not(.react-tabs__tab--selected) {
                background-color: $default-backgroundColor !important;
                @include default-transition(background-color);
                color: rgba($black, .7);

                &:hover {
                    background-color: $default-labelColor !important;
                }

            }

            &:focus {
                box-shadow: none !important;

                &::after {
                    display: none !important;
                }
            }

            &--selected {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    height: 6px;
                    width: 100%;
                    background-color: inherit;
                    bottom: -3px;
                    left: 0;
                }
            }
        }

        &__tab-panel {
            border: 1px solid $default-inputColor;
            padding: 30px !important;

            li {
                list-style-type: disc;
                margin-left: 36px;
                margin-bottom: 8px;
            }
        }

        p {
            padding-bottom: 10px;
        }

        h2 {
            padding-top: 10px;
            font-size: 20px;
        }
    }
}