.horselbutik-main {
    .spinner {
        width: 36px;
        height: 36px;
        text-align: center;

        &__element {
            width: 100%;
            height: 100%;
            background-color: $white;
            border-radius: 100%;
            display: inline-block;
            opacity: 1;
            position: static;
            animation: none;
            background-image: url('../../../images/loader-horselbutik.gif');
            background-position: center center;
            background-size: contain;

            &--2,
            &--3,
            &--4,
            &--5,
            &--6,
            &--7,
            &--8 {
                display: none;
            }
        }
    }
}