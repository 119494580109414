.bernafon-main {
    $default-color: #404040 !global;
    $default-activeColor: #fa344c !global;
    $border-input: #7F7F7F !global;
    $header-background: #FADFD3 !global;
    $border-background: #f1f1f1 !global;

    //fonts
    $Roboto-Light: 'Roboto-Light', 'Arial', sans-serif !global;
    $Roboto-Regular: 'Roboto-Regular', 'Arial', sans-serif !global;
    $Roboto-Medium: 'Roboto-Medium', 'Arial', sans-serif !global;
    $BernafonIcons: 'bernafon-iconfont' !global;
}