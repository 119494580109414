.welcome-banner {

    &__img {
        position: relative;
        color: $white;
        background: url('../../../images/banner-mobile.jpg');
        background-position: center center;
        height: 300px;
        text-align: center;

        @media screen and (min-width: $breakpoint-medium) {
            background: url('../../../images/banner-desktop.jpg');
            background-position: center center;
            height: 230px;
        }
    }

    &__wrapper {
        position: absolute;
        top: 100px;
    }

    &__heading {
        font-family: $SohoGothicPro-Medium;
        font-size: px-to-rem(34);
        // top: 110px;
        text-align: center;
        width: 100vw;
        color: white;

        @media screen and (min-width: $breakpoint-medium) {
            font-size: px-to-rem(42);
            top: 100px;
        }
    }
}