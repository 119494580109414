.checkout-success,
.checkout-failed {
    margin: 20px;

    h1 {
        font-weight: 700;
        font-size: px-to-rem(30);
        padding: 20px;
    }
}

.checkout-failed {
    text-align: center;

    button {
        width: auto;
        padding: 0 45px;
        margin: 0 auto;
    }

    .order-failed {
        margin-bottom: 20px;
        span {
            color: black !important
        }
    }
}

.order-confirmation-wrapper {
    padding: 26px;
    font-size: px-to-rem(14);
    margin-bottom: 30px;
    line-height: 30px;

    a,span {
        color: $default-activeColor;
        font-weight: 700;
        @include default-transition(color);
    }

    a{
        &:hover {
            color: $default-color;
        }
    }
}

.order-success {
    font-size: px-to-rem(18);
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .checkout-success,
    .checkout-failed {
        margin: 30px;

        h1 {
            font-size: px-to-rem(50);
        }
    }

    .order-confirmation-wrapper {
        font-size: px-to-rem(16);
    }
}