.phonicear-main {
    .cms-side {
        a {
            color: $footer-color;
            border-left: 2px solid transparent;
            font-size: px-to-rem(16);

            &.active {
                color: $error-color;
                border-left: 2px solid $error-color;

                &:hover {
                    color: $error-color !important;
                }
            }

            &:hover {
                color: $color-border;
            }
        }
    }
}