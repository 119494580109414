.bernafon-main {
    .summary-table {
        &__column {
            font-family: $Roboto-Regular;

            &:last-of-type {
                font-family: $Roboto-Regular;
            }

            &--total {
                background-color: transparent;
                border-top: 2px solid $header-background;
                font-family: $Roboto-Medium !important;
            }
        }
    }
}