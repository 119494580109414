.horselbutik-main {
    .intro-banner {
        color: $black;
        
        @media (min-width: $breakpoint-medium) {
            background: $default-backgroundColor;
        }

        &__left {
            margin-top: -240px;

            @media (min-width: $breakpoint-medium) {
                margin-top: 0;
            }
        }

        &__heading {
            font-family: $SourceSansPro-Bold;
        }

        &__text {
            font-size: px-to-rem(18);
        }

        .button {
            margin: 0 auto;

            &:not(:last-of-type) {
                margin-bottom: 25px;
            }

            @media (min-width: $breakpoint-medium) {
                margin: 0;
                width: auto;

                &:not(:last-of-type) {
                    margin-right: 25px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .swiper-button-next, .swiper-button-prev {
        color: $default-buttonColor;
    }

    .swiper-pagination-bullet-active {
        color: $default-buttonColor;
        background-color: $default-buttonColor;

    }
}