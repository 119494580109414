.bernafon-main {
    .info-blocks-wrapper {
        background-color: $header-background;
    }

    .bottom-info-block {
        margin-left: -20px;
        margin-right: -20px;

        h6 {
            font-family: $Roboto-Medium;
            font-size: px-to-rem(20);
        }

        p {
            font-family: $Roboto-Regular;
        }

        div {
            background: $white;
            padding: 20px;
            margin: 20px;
            border: none;
            border-radius: 8px;
            box-shadow: 0px 2px 4px rgba($default-color, 0.3);
            border-radius: 3px;
        }

        .link {
            border-radius: 40px;
            border: 1px solid $default-activeColor;
            color: $default-activeColor;
            background-color: $white;
            transition: .2s ease color, .2s ease background-color;
            font-family: $Roboto-Regular;
            font-size: px-to-rem(20);
            padding-bottom: 0;
            background-color: $default-activeColor;
            color: $white;
            line-height: 45px;
            font-weight: 500;
            padding: 0px 10px 4px;
            text-decoration: none;
            height: 49px;
            width: auto;

            &:before {
                content: none !important;
            }
    
    
            &:hover {
                color: $default-activeColor;
                background-color: $white;
            }
        }

        .link-text {
            font-family: $Roboto-Medium;
            font-size: px-to-rem(20);
            display: inline;
            order: 2;
            padding: .75rem 1.375rem
        }
    }
}