.text-image-component {
    width: 100%;
    display: flex;
    padding: 0;
    text-align: center;
    background: $white;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: $breakpoint-large) {
        width: 50%;
        display: inline-flex;
        flex-direction: row;
        text-align: left;
        height: 350px;

        &.w100 {
            width: 100%;
        }
    }

    &__wrapper {
        padding: 40px 30px;
        z-index: 2;
        background-color: $white;

        @media (min-width: $breakpoint-large) {
            padding: 65px 60px;
        }
    }

    &__image-wrapper {
        @media (min-width: $breakpoint-large) {
            width: 38%;
            max-width: 280px;

            .w100 & {
                width: 50%;
                max-width: none;
                position: relative;
            }
        }
    }

    &__image {
        max-width: none;
        width: 100%;

        @media (min-width: $breakpoint-xsmall) {
            max-width: none;
            height: 100%;
            width: auto;
        }

        @media (min-width: $breakpoint-large) {
            .w100 & {
                position: absolute;
                height: auto;
                width: 100%;
            }
        }
    }

    .button {
        margin-left: auto;

        @media (min-width: $breakpoint-large) {
            margin-left: 0;
        }
    }
}

.components-group-wrapper {
    width: 100% !important;
    max-width: none;
}

@media (min-width: 1650px) {
    .components-group-wrapper {
        max-width: 1318px;
        margin: 0 auto;
    }
}