.horselbutik-main {
    .cms-side {
        a {
            font-family: $SourceSansPro-Regular;

            &:hover,
            &.active {
                color: $default-color;
                border-left: 0;
            }

            &.active {
                font-family: $SourceSansPro-Bold;
            }
        }
    }

    .messages {
        border: none;
        background-color: $input-background;
        margin-bottom: 30px;
        margin-left: 0;
        max-width: none !important;

        .success-msg,
        .error-msg {
            padding: 8px;
            font-size: px-to-rem(14);
            margin: 5px;
            color: $black;

            span {
                font-family: $SourceSansPro-SemiBold;
            }
        }

        .error-msg {
            color: $error-color;
        }
    }

    .contact-form {
        margin-top: 30px;
    }
}