.bernafon-main {
    .custom-select {
        margin-top: 0;

        &__btn {
            border: none;
            border-bottom: 1px solid $default-color;
            font-family: $Roboto-Regular;

            &::before {
                content: '';
                font-family: "bernafon-iconfont";
                content: "\e922";
                right: 7px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__dropdown-menu {
            border: none;
            box-shadow: 0 1px 3px 0px #7F7F7F;
            border-radius: 0 0 3px 3px;
        }

        &__menu-item {
            background-color: $white;

            &:hover {
                background-color: $default-activeColor;
                color: $white;
            }

            &--active {
                background-color: $white;
            }
        }

        &__label {
            margin-left: 9px;
            opacity: .6;
        }
    }
}