@keyframes cta-right-hover {
    0%,
    to {
        transform: translateX(0);
        opacity: 1
    }
    49% {
        opacity: 1
    }
    50% {
        transform: translateX(120%);
        opacity: 0
    }
    50.1% {
        transform: translateX(-120%);
        opacity: 0
    }
    50.2% {
        transform: translateX(-120%);
        opacity: 1
    }
}

@keyframes cta-right {
    0%,
    to {
        transform: translateX(0);
        opacity: 1
    }
    49% {
        opacity: 1
    }
    50% {
        transform: translateX(-120%);
        opacity: 0
    }
    50.1% {
        transform: translateX(120%);
        opacity: 0
    }
    50.2% {
        transform: translateX(120%);
        opacity: 1
    }
}

@keyframes bounce_loader {
    0% {
        transform:scale(1);
        opacity: 0;
    } 1% {
        opacity: 1;
    } 100% {
        transform:scale(.3);
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    } 100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    } 99% {
        z-index: -1;
    } 100% {
        opacity: 0;
        z-index: -1;
    }
}

@keyframes pulse {
    0% {
        transform: scaleX(1);
    } 50% {
        transform: scale3d(1.05,1.05,1.05);
    } to {
        transform: scaleX(1);
    }
}

@keyframes sk-bouncedelay {
    0%,80%,to {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}