@font-face {
    font-family: 'SourceSansPro-ExtraLight';
    src: url('../fonts/SourceSansPro-ExtraLight.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro-Light';
    src: url('../fonts/SourceSansPro-Light.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro-Regular';
    src: url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro-SemiBold';
    src: url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro-Bold';
    src: url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'SourceSansPro-Black';
    src: url('../fonts/SourceSansPro-Black.ttf') format('truetype');
    font-style: normal;
}