.horselbutik-main {
    .products-grid {
        &__item {
            background-color: $white;
            box-shadow: $product-boxshadow;
            @include default-transition(box-shadow);

            &:hover {
                box-shadow: $product-boxshadow-hover;
            }

            .product-detailing-top {
                height: 100%;
                z-index: -1;
                @include default-transition(all);

                &.is-active {
                    z-index: 1;
                    background: rgba($white, 0.95);
                }
            }

            .product-image {
                color: $black;
                display: flex;
                flex-direction: column;
                // justify-content: flex-end;

                &:hover {
                    text-decoration: none;
                }

                .image-div {
                    min-height: 220px;

                    @media (min-width: $breakpoint-large) {
                        min-height: 352px;
                    }
                }
            }

            .product-name {
                font-family: $SourceSansPro-Bold;
                font-size: px-to-rem(20);
            }

            .attr-tax,
            .long-description,
            .short-description {
                font-size: px-to-rem(14);
            }
        }
    }

    .add-to-cart {
        .button-addToCart {
            background: $default-color;
            color: $white;

            &:hover {
                background: $white;
                color: $default-color;
                box-shadow: 0px 0px 8px rgba(96, 97, 165, 0.3);
            }

            &:focus,
            &:active {
                background: $input-background;
                color: $default-color;
                box-shadow: 0px 0px 8px rgba(96, 97, 165, 0.3);
            }

            &.disabled {
                &:hover {
                    background: $default-color;
                    color: $white;
                }
            }
        }
    }

    .product-block {
        background: $white;
        align-items: flex-start;

        &__shop {
            background-color: $white;
            font-family: $SourceSansPro-Regular;

            .short-description {
                font-family: $SourceSansPro-Regular;
            }

            .info-block {
                .inStock {
                    font-family: $SourceSansPro-Bold;
                    padding-left: 34px;
                    padding-right: 20px;

                    &::before {
                        content: url('../../../images/horselbutik-checkmark.svg');
                        top: -2px;
                        width: 5px;
                    }
                }

                .outOfStock {
                    font-family: $SourceSansPro-Bold;
                    padding-left: 34px;
                    background-size: 5px;

                    padding-right: 20px;

                    &::before {
                        content: url('../../../images/horselbutik-close.svg');
                        background-size: 5px;
                        top: 1px;
                        width: 5px;
                    }
                }
            }

            .delivery-status {
                font-family: $SourceSansPro-Regular;
            }
        }
    }

    .price-box {
        .regular-price {
            font-family: $SourceSansPro-Bold;

            @media (min-width: $breakpoint-medium) {
                font-size: px-to-rem(22);
            }

            .promo {
                color: $default-buyButtonColor;
            }

            .strikethrough {
                font-family: $SohoGothicPro-Light;
                font-size: 16px;
            }
        }
    }

    .attributes {
        &__label {
            font-family: $SourceSansPro-SemiBold;
            display: inline-block;
            margin-top: 12px;
            margin-bottom: 5px;
        }

        .custom-select {
            margin-top: 0;
        }
    }

    .add-to-cart {
        &__wrapper {
            display: inline-block;
            margin-right: 60px;
            white-space: nowrap;
        }

        &__button {
            display: inline-block;
            background: none;
            border: none;
            outline: none;
            width: 20px;
            height: 20px;
            padding: 0;
            vertical-align: middle;
            background-position: center center;
            background-repeat: no-repeat;

            &--dec {
                background-image: url('../../../images/horselbutik-minus.svg');
            }

            &--inc {
                background-image: url('../../../images/horselbutik-plus.svg');
            }
        }

        .qty {
            float: none;
            min-width: 68px;
            margin: 0 10px;
            height: 44px;
            vertical-align: middle;
        }

        .button-addToCart {
            float: none;
            display: inline-block;
        }
    }
}